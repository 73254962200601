import styled from 'styled-components';
import { DefaultText } from '../../../styles/global/css/GlobalText';
import { BlueSidely, BorderColor, GreySidely, LightGreySidely2, SidelyBlack } from '../../../styles/global/css/Utils';

const Container = styled.div<{ overflow?: string, padding?: string }>`
	padding: ${p => p.padding ? p.padding : '0 1em'};
	height: 100%;
	overflow-y: ${p => p.overflow ? p.overflow : 'auto'};
`;

const Header = styled.div<{ paddingBottom?: string }>`
	display: flex;
	flex-direction: column;
	${({ paddingBottom }) => paddingBottom ? `padding-bottom: ${paddingBottom};` : ''}
	position: sticky;
	top: 0;
	background-color: white;
`;

const Body = styled.div``;

const Category = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	height: 35px;
	cursor: pointer;
	top: 37px;
	position: sticky;
	background-color: white;
	padding: 20px 0 10px 0;
`;

const CategoryName = styled.p<{ parentCategoryTitle?: boolean }>`
	${DefaultText}
	font-weight: 500;
	font-size: 14px;
	color: ${SidelyBlack};
	${({ parentCategoryTitle }) => parentCategoryTitle ? `
		cursor: pointer;
		margin-left: -10px;
		padding: 7px;
		padding-left: 10px;
		border-radius: 5px;
		&:hover {
			background-color: ${BlueSidely}20;
		}
	` : ''}
`;

interface CategoryBodyProps {
	isOpen: boolean
}

const CategoryBody = styled.div<CategoryBodyProps>`
	transition: all 0.4s ease;
	height: fit-content;
	display: ${p => p.isOpen ? 'initial' : 'none'};
	overflow-y: hidden;
`;

const CategoryFilterRow = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	align-items: center;
`;

interface CategoryFilterTextProps {
	margin?: string,
	isFilterDisabled?: boolean,
	warningMessage?: string
}

const CategoryFilterText = styled.div<CategoryFilterTextProps>`
	${DefaultText}
	font-weight: 400;
	font-size: 13px;
	color: ${p => p.isFilterDisabled ? GreySidely : 'rgba(37, 65, 83, 0.7)'};
	cursor: ${p => p.isFilterDisabled ? 'initial' : 'pointer'};
	padding: 4px 0;
	margin: ${p => p.margin ? p.margin : 'initial'};
	white-space: nowrap;
	overflow-x: hidden;
	text-overflow: ellipsis;
	border-radius: 5px;
	margin-left: -10px;
	padding-inline: 10px;
	&:hover {
		background-color: ${p => p.isFilterDisabled ? 'initial' : `${BlueSidely}20`};
		&::after {
			color: ${SidelyBlack};
			text-wrap: wrap;
			display: ${props => props.isFilterDisabled ? 'block' : 'none'};
			content: ${props => props.warningMessage ? `'${props.warningMessage}'` : ''};
			position: absolute;
			z-index: 10;
			width: 21vw;
			top: 185px;
			padding: 5px;
			background-color: ${BorderColor};
			border-radius: 5px;
			font-size: 12px;
			transition-property: display;
			transition-delay: 1s;
		}
	}
`;

interface FilterTextProps {
	fontSize?: string
	fontWeight?: string
	color?: string
	margin?: string
	padding?: string
	cursor?: string
}

const FilterText = styled.p<FilterTextProps>`
	${DefaultText}
	${p => p.fontSize ? `font-size: ${p.fontSize};` : ''}
	${p => p.fontWeight ? `font-weight: ${p.fontWeight};` : ''}
	${p => p.color ? `color: ${p.color};` : ''}
	${p => p.margin ? `margin: ${p.margin};` : ''}
	${p => p.padding ? `padding: ${p.padding};` : ''}
	${p => p.cursor ? `cursor: ${p.cursor};` : ''}
	width: fit-content;
	display: flex;
	flex-flow: row;
	align-items: center;
	gap: 5px;
	flex-wrap: wrap;
`;

export const FilterTextSummary = styled(FilterText)`
	width: 100%;
	cursor: pointer;
`;

const InputContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;

const RadioContainer = styled.div`
	display: flex;
	margin: 15px 0px;
	cursor: pointer;
	width: fit-content;
	align-items: center;
`;

const DropdownContainer = styled.div`
	width: 100%;
	height: 50px;
	border: 1px solid ${BorderColor};
	border-radius: 5px;
	display: flex;
	flex-direction: row;
	cursor: pointer;
	position: relative;
`;

const DropdownContainerLeft = styled.div`
	width: 90%;
	height: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	overflow-x: auto;
`;

const DropdownContainerRight = styled.div`
	width: 10%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
`;

const DropdownOptionContainer = styled.div`
	width: 105%;
	position: absolute;
	background-color: white;
	max-height: 350px;
	top: 40px;
	z-index: 10;
	overflow-y: auto;
	right: 0;
	box-shadow: 0px 0px 11px rgb(0 0 0 / 15%);
	border-radius: 5px;
`;

const DropdownOptionHeader = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 7px;
	position: sticky;
	top: 0;
	background: white;
`;

const DropdownOptionBody = styled.div`
	margin: 0 20px;
`;

const ItemContainer = styled.div`
	width: fit-content;
	display: flex;
	padding: 2px 5px;
	border: 1px solid ${SidelyBlack};
	border-radius: 5px;
	margin: 0 4px;
	white-space: nowrap;
`;

const ItemText = styled.p`
	${DefaultText}
	color: ${SidelyBlack};
`;

const ItemCross = styled.p`
	${DefaultText}
	color: ${SidelyBlack};
	padding-left: 10px;
	user-select: none;
`;

const SummaryRowBlock = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const SummaryDelete = styled.button`
	margin-left: 7px;
`;

const SummaryBlock = styled.div`
	display: flex;
	max-width: calc(100% - 70px);
`;

export const HoverableDiv = styled.div<{ weight: number }>`
	display: flex;
	gap: 5px;
	cursor: pointer;
	flex-wrap: wrap;
	&:hover {
		background-color: rgba(0, 178, 255, ${p => (p.weight + 1) * 0.03});
		outline: 3px solid rgba(0, 178, 255, ${p => (p.weight + 1) * 0.03});
		border-radius: 1px;
	}
`;

interface SpanProps {
	isValue?: boolean
	clickable?: boolean
}

const Span = styled.span<SpanProps>`
	${DefaultText}
	color: ${p => p.isValue ? SidelyBlack : BlueSidely};
	font-weight: 500;
	${p => p.clickable ? 'cursor: pointer;' : ''}
	overflow: hidden;
	text-overflow: ellipsis;
`;

interface GreyRectangleProps {
	weight: number
}

const GreyRectangle = styled.div<GreyRectangleProps>`
	${p =>
		p.weight == 0 ? 
			`
		display: flex;
		flex-flow: column;
		align-content: space-between;
		gap: 3px;
				`
			: 
			`
		background-color: rgba(0, 178, 255, ${p.weight * 0.03});
		border-width: 1px;
		padding: 0.5em;
		border-radius: 5px;
		border: 1px solid lightgray;
		display: flex;
		flex-flow: column;
		align-content: space-between;
		margin-top: 4px;
		gap: 3px;
		`
}

`;

const RectangleTitle = styled.h3`
	color: ${SidelyBlack};
	font-size: 16px;
	font-weight: 500;
	margin: 0;
`;

const Spacer = styled.div`
	display: flex;
	flex-flow: column;
	gap: 5px;
`;

// const RectangleCombinator = styled.p`
//   font-weight: 500;
//   font-size: 13px;
//   margin: 0;
//   color: ${SidelyBlack};
//   cursor: pointer;
//   text-decoration: underline;
// `;

const FilterEditor = styled.button`
	font-size: 12px;
	font-weight: 500;
	background-color: ${BlueSidely};
	color: white;
	border: none;
	padding: 0.1em 0.7em;
	border-radius: 4px;
	vertical-align: center;
	text-align: center;
	cursor: pointer;
	display: inline-block;
	margin: 0;
	height: fit-content;
`;

const FilterIcon = styled.img`
	vertical-align: center;
	text-align: center;
	cursor: pointer;
	display: inline-block;
	margin: 0;
	width: 20px;
`;

const FilterRow = styled.div`
	display: flex;
	flex-flow: row;
	align-content: space-between;
	width: 100%;
	vertical-align: center;
	align-items: center;
	justify-content: space-between;
`;

const HoverableContainer = styled.div`
	display: flex;

	${FilterEditor},${FilterIcon} {
		transition: opacity 0.4s ease;
		opacity: 0;
	}

	${FilterRow}:hover ${FilterEditor} {
		opacity: 1;
	}

	${FilterRow}:hover ${FilterIcon} {
		opacity: 1;
	}
`;

const FilterEditorContainer = styled.div`
	display: flex;
		flex-flow: row;
		align-content: flex-start;
		gap: 0.2em;
		margin-top: 0.5em;
`;

const RectangleCombinatorSwitch = styled.div`
		display: flex;
		flex-flow: row;
		align-content: flex-start;
		border-radius: 5px;
		border-style: solid;
		border-color: ${LightGreySidely2};
		border-width: 0.5px;
		margin-right: auto;
		cursor: pointer;
`;

interface RectangleCombinatorElementProps {
	enabled: boolean;
}
const RectangleCombinatorElement = styled.div<RectangleCombinatorElementProps>`
	border-style: solid;
	border-color: ${LightGreySidely2};
	border-width: 0.5px;
	color: ${p => p.enabled ? SidelyBlack : GreySidely };
	padding: 0 0.3em;
	font-size: 10px;
	${p => p.enabled ? '' : `
		&:hover {
			color: ${SidelyBlack};
			opacity: 0.75;
		}
	`}
`;

export {
	Container,
	Header,
	Body,
	Category,
	CategoryName,
	CategoryBody,
	CategoryFilterText,
	FilterText,
	RadioContainer,
	DropdownContainer,
	DropdownContainerLeft,
	DropdownContainerRight,
	ItemContainer,
	ItemText,
	ItemCross,
	DropdownOptionContainer,
	DropdownOptionHeader,
	DropdownOptionBody,
	CategoryFilterRow,
	SummaryBlock,
	Span,
	InputContainer,
	SummaryRowBlock,
	SummaryDelete,
	GreyRectangle,
	RectangleTitle,
	Spacer,
	// RectangleCombinator,
	FilterEditor,
	FilterRow,
	HoverableContainer,
	FilterEditorContainer,
	FilterIcon,
	RectangleCombinatorSwitch,
	RectangleCombinatorElement,
};
