import * as React from 'react';
import * as moment from 'moment';
import SidelyCalendar, { EventsTypes, toTemporaryEvents } from '../../calendar/Calendar';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useWindowDimensions } from '../../../components_v2/utils';
import { getTranslate, Translate } from 'react-localize-redux';
import { toast } from 'react-toastify';
import { bulkCreateEvents, sequenceEvents } from './actions';
import storeLang from '../../../helpers/storeLang';
import { modifyEvent } from '../../calendar/EventsApi';
import Popup from '../../../components_v2/popup/Popup';
import InputAddress from '../../../components_v2/input/InputAddress';
import { InputAddressResult } from '../../../components_v2/input/model/Model';
import { getLocalStorage } from '../../../helpers/localStorage';
import { Loader } from '../../../styles/global/css/GlobalLoader';
import LoaderContext from '../../../styles/global/loader/LoaderContext';
import { LoadingStateEnum } from '../../import/model';
import { ButtonContainer, ModalNoClose, OptimizePopupBody, OptimizePopupFooter, TopContainer } from './style';
import { Interconnection, LoadingState, OptimizeCompany } from './model';
import Dropdown from '../../../components_v2/dropdown/Dropdown';
import { DropdownData } from '../../../components_v2/dropdown/model/Model';
import styled from 'styled-components';
import { BlueSidely, DarkGreySidely2 } from '../../../styles/global/css/Utils';
import { PopupMode } from '../../../components_v2/popup/model/Model';
import useAlert from '../../alert/UseAlert';
import { AlertRes } from '../../alert/AlertProvider';
import { CalendarMapView } from '../calendarMapView';
import Splitter from '../../../components_v2/splitter/Splitter';
import { simpleDurationPicker, simpleTimePicker } from './createEventFromLasso';
import { useRecoilValue } from 'recoil';
import { AProfile } from '../../../atoms/global/users';
import { UserWithAdditionalFields } from '../../settings/userSettings/myProfile';
import { useSelector } from 'react-redux';
import { WebSocketState } from '../../../store/reducers/webSocket.reducer';
import OptimizeIMG from 'images/icons/ai_line.svg';

const InputAddressResultToString = (value?: InputAddressResult): string | undefined => {
	if (value == null) return;
	return `${value.street_number} ${value.route}, ${value.city}, ${value.country}`;
};

const InputContainer = styled.div`
	display: flex;
	flex-flow: column;
	width: 250px;
	height: fit-content;
	font-size: 12px;
	color: ${DarkGreySidely2};
`;

export type OptimizationInformations = {
	start: Date,
	end: Date,
	pause?: {
		time: Date,
		duration_sec: number
	}
}

export const initWaypoint = (value: string, profile: UserWithAdditionalFields): InputAddressResult | undefined => {
	let storage: InputAddressResult = getLocalStorage(value);
	if (((value === 'origin' && profile.use_address_as_default_optimization_start) || (value === 'destination' && profile.use_address_as_default_optimization_end)) && profile.latitude !== undefined && profile.longitude !== undefined) {
		//@ts-expect-error it works
		if (storage == null) storage = {};
		storage.city = profile.city ?? '';
		storage.country = profile.country ?? '';
		storage.street_number = profile.address ?? '';
		storage.route = '';
		storage.postcode = profile.postal_code ?? '';
		storage.longitude = profile.longitude;
		storage.latitude = profile.latitude;
	}
	return storage;
};

export function OptimizePopup(props: {
  companies?: OptimizeCompany[],
  errorCompanies: any[],
  onOptimize: (ids: string[], interconnections: Interconnection[], optimizationInformations: OptimizationInformations | undefined) => void
  isOpen: boolean
  setIsOpen: (b: boolean) => void,
  allInformations?: boolean
}) {
	const profile = useRecoilValue(AProfile);
	const companies = props.companies ?? [];
	const { onOptimize, isOpen, setIsOpen } = props;
	const [origin, setOrigin] = React.useState<InputAddressResult | undefined>(initWaypoint('origin', profile));
	const [destination, setDestination] = React.useState<InputAddressResult | undefined>(initWaypoint('destination', profile));
	const [transportMode, setTransportMode] = React.useState<string>(getLocalStorage('transportation-mode') ?? 'bicycle');
	const [hourStartDay, setHourStartDay] = React.useState(new Date(2000, 1, 1, 8, 0));
	const [hourEndDay, setHourEndDay] = React.useState(new Date(2000, 1, 1, 17, 0));
	const [pauseHour, setPauseHour] = React.useState<Date | null>(null);
	const [pauseDuration, setPauseDuration] = React.useState({ nb: '', op: 'min' });
	const { loaderNotify } = React.useContext(LoaderContext);
	const translate = getTranslate(storeLang.getState().localize);
	const alert = useAlert();

	const setAdressLocalStorage = (value: InputAddressResult, key: string) => {
		localStorage.setItem(key, JSON.stringify(value));
		switch (key) {
			case 'origin':
				setOrigin(value);
				break;
			case 'destination':
				setDestination(value);
				break;
		}
	};

	const transportationModes: DropdownData[] = [
		{ label: translate('car') as string, value: 'car' },
		{ label: translate('pedestrian') as string, value: 'pedestrian' },
		{ label: translate('truck') as string, value: 'truck' },
		{ label: translate('bicycle') as string, value: 'bicycle' }
	];

	const optimise = () => {
		loaderNotify('loader-optimization', LoadingStateEnum.LOADING);
		sequenceEvents({
			waypoints: [
				...companies,
				{ id: -1, lat: destination?.latitude, lng: destination?.longitude },
				{ id: -2, lat: origin?.latitude, lng: origin?.longitude }
			],
			mode: transportMode
		})
			.then(res => {
				onOptimize([...res.data.waypoints.filter(id => id > 0), ...props.errorCompanies.map(c => c.id.toString())], res.data.interconnections, props.allInformations ? {
					start: hourStartDay,
					end: hourEndDay,
					pause: pauseHour && !isNaN(parseInt(pauseDuration.nb)) ? {
						time: pauseHour,
						duration_sec: parseInt(pauseDuration.nb) * 60 * (pauseDuration.op === 'min' ? 1 : 60)
					} : undefined
				} : undefined);
				loaderNotify('loader-optimization', LoadingStateEnum.LOADED);
			})
			.catch(e => {
				console.error(e);
				loaderNotify('loader-optimization', LoadingStateEnum.ERROR);
			});
		setIsOpen(!isOpen);
	};

	return <Popup isOpen={isOpen} hasBackground onClickOut={() => setIsOpen(false)} popupStyle={{ zIndex: 1051, fitContent: true }} popupMode={PopupMode.Centered}>
		<div style={{ width: '100%', height: 'fit-content' }}>
			<div style={{ maxHeight: 'calc(100vh - 250px)', overflow: 'visible' }}>
				<OptimizePopupBody>
					<InputAddress
						name='optimization_origin'
						type='text'
						onChange={value => setAdressLocalStorage(value, 'origin')}
						value={InputAddressResultToString(origin)}
						label={translate('departure') as string}
						placeholder={translate('departure') as string}
						optionWidth='250px'
						optionTop='55px'
						inputStyle={{ width: '250px', labelUp: true, padding: '.56rem 0.75rem', height: 'fit-content' }}
					/>
					<InputAddress
						name='optimization_destination'
						type='text'
						onChange={value => setAdressLocalStorage(value, 'destination')}
						value={InputAddressResultToString(destination)}
						label={translate('destination') as string}
						placeholder={translate('destination') as string}
						optionWidth='250px'
						optionTop='55px'
						inputStyle={{ width: '250px', labelUp: true, padding: '.56rem 0.75rem', height: 'fit-content' }}
					/>
					<Dropdown
						datalist={transportationModes}
						selectedValue={transportationModes.find(t => t.value == transportMode)}
						onChange={(value: DropdownData) => {
							localStorage.setItem('transportation-mode', JSON.stringify(value.value));
							setTransportMode(value.value);
						}}
						name='optimization-transportation-mode'
						dropdownStyle={{ width: '250px', labelUp: true, optionWidth: '250px', height: 'fit-content' }}
						label={translate('transportation_mode') as string}
					/>
					{props.allInformations && <>
						<InputContainer>
							<Translate id='map.modal_right.hour_start_day' />
							{simpleTimePicker(false, hourStartDay, (e) => setHourStartDay(e), null)}
						</InputContainer>
						<InputContainer>
							<Translate id='map.modal_right.hour_end_day' />
							{simpleTimePicker(false, hourEndDay, (e) => setHourEndDay(e), null)}
						</InputContainer>

						<InputContainer>
							<Translate id='map.modal_right.pause_time' />
							{simpleTimePicker(false, pauseHour, (e) => setPauseHour(e), null)}
						</InputContainer>
						<InputContainer>
							<Translate id='map.modal_right.duration_pause' />
							{simpleDurationPicker(pauseDuration.nb, pauseDuration.op, (e) => setPauseDuration({ ...pauseDuration, ...e }), 'duration')}
						</InputContainer>
					</>}
				</OptimizePopupBody>
			</div>
			<OptimizePopupFooter>
				<Button
					style={{
						height: '42px',
						width: '130px',
						background: '#FFFFFF',
						border: `2px solid ${BlueSidely}`,
						color: BlueSidely
					}}
					onClick={() => setIsOpen(!isOpen)}
				>
					<Translate id='map.modal_right.cancel' />
				</Button>
				<Button
					style={{
						height: '42px',
						width: '130px',
						background: BlueSidely,
						color: '#FFFFFF'
					}}
					disabled={!origin || !destination}
					onClick={() => {
						if (props.errorCompanies.length > 0) {
							alert({
								zIndex: 1052,
								title: 'Attention !',
								content: <Translate id='optimisation_ignored' />,
							}).then(res => {
								if (res == AlertRes.Ok) optimise();
							});
						} else
							optimise();
					}}
				>
					<Translate id='map.modal_right.optimize' />
				</Button>
			</OptimizePopupFooter>
		</div>
	</Popup>;
}

export default function ModalCalendar(props: {
  isOpen: boolean
  toggleModal: () => void
  events: any[]
  companies: any[]
	name: string
  form_id?: number
  onClose: () => void
  id: number
  isVisit: boolean
  onOptimize: (ids: string[], interconnections: Interconnection[]) => void
  v2?: boolean,
  onEventCreate?: () => void
}) {
	const translate = getTranslate(storeLang.getState().localize);
	const [loadingState, setLoadingState] = React.useState<LoadingState>(null);
	const [events, setEvents] = React.useState<any[]>(toTemporaryEvents(props.events));
	const [isOpen, setIsOpen] = React.useState<boolean>(false);
	const [optimizeOpen, setOptimizeOpen] = React.useState<boolean>(false);
	const [hovered, setHovered] = React.useState<string | undefined>(undefined);
	const [hoveredEvent, setHoveredEvent] = React.useState<string | number | undefined>(undefined);
	const { height, width } = useWindowDimensions();

	React.useEffect(() => {
		if (props.events.length > 0 && props.isOpen) {
			setEvents([
				...toTemporaryEvents(props.events),
				...events.filter(event => event.type !== EventsTypes.TemporaryEvent)
			]);
			setIsOpen(props.isOpen);
		}
	}, [props.events, props.isOpen]);

	return (
		<div>
			<Modal
				size="lg"
				style={{ maxWidth: width - 200, width: width - 200 }}
				isOpen={isOpen}
				toggle={() => {
					props.toggleModal();
					setIsOpen(!isOpen);
				}}
				centered
			>
				<ModalNoClose>
					<React.Fragment>
						<ModalHeader
							toggle={() => {
								props.toggleModal();
								setIsOpen(!isOpen);
							}}
						>
							<TopContainer width={width - 205}>
								<Translate id="map.modal_right.preview_your_events" />
								<ButtonContainer>
									<Loader width='20px' id='loader-optimization' />
									{props.isVisit && <Button
										style={{
											height: '42px',
											width: '130px',
											background: BlueSidely,
											color: '#FFFFFF',
											marginLeft: 'auto',
											marginRight: '0px'
										}}
										disabled={props.companies?.filter(c => c.lat && c.lon).length > 30 || props.companies?.filter(c => c.lat && c.lon).length <= 1}
										onClick={() => setOptimizeOpen(true)}
									>
										<img src={OptimizeIMG} style={{ position: 'relative', right: '2px', bottom: '1px' }}/>
										<Translate id='map.modal_right.optimize' />
									</Button>
									}
									<Button
										style={{
											height: '42px',
											width: '130px',
											background: '#FFFFFF',
											border: `2px solid ${BlueSidely}`,
											color: BlueSidely,
											marginLeft: 'auto',
											marginRight: '0px'
										}}
										onClick={() => {
											props.toggleModal();
											setIsOpen(!isOpen);
										}}
									>
										<Translate id='map.modal_right.cancel' />
									</Button>
									<Button
										style={{
											height: '42px',
											width: '130px',
											background: BlueSidely,
											color: '#FFFFFF',
											marginLeft: 'auto',
											marginRight: '25px'
										}}
										onClick={() => {
											if (!props.v2) {
												const filteredEvents = events.filter(e => e.type == EventsTypes.TemporaryEvent);
												const body = {
													events: filteredEvents.map(e => ({
														title: e.title,
														start: ~~(+e?.start / 1000),
														end: ~~(+e?.end / 1000),
														company_id: parseInt(e.clientCompanyId),
														description: e.description
													})),
													linked_form: props.form_id,
													type_id: filteredEvents[0].eventTypeId,
													owner_id: parseInt(filteredEvents[0].incharge)
												};
												toast.promise(
													bulkCreateEvents(body).catch(console.error),
													{
														pending: `${translate('map.modal_right.creation_of')} ${filteredEvents.length} ${props.name}${props.name.toLowerCase().slice(-1) === 's' ? '' : 's'}`,
														success: `${translate('map.modal_right.events_created')}`,
														error: `${translate('map.modal_right.error_creating_events')}`
													}
												).then(props.onEventCreate);
											} else {
												const filteredEvents = events.filter(e => e.type == EventsTypes.TemporaryEvent);
												const body = {
													events: filteredEvents.map(e => ({
														title: e.title,
														start: ~~(+e?.start / 1000),
														end: ~~(+e?.end / 1000),
														company_id: parseInt(e.clientCompanyId),
														description: e.description
													})),
													linked_form: props.form_id,
													type_id: filteredEvents[0].eventTypeId,
													owner_id: parseInt(filteredEvents[0].incharge)
												};
												bulkCreateEvents(body).catch(console.error).then(props.onEventCreate);
											}
											events.filter(e => e.temporaryModified).forEach(event => {
												modifyEvent(event);
											});
											props.onClose();
											props.toggleModal();
											setIsOpen(!isOpen);
										}}
									>
										<Translate id='map.modal_right.generate' />
									</Button>
								</ButtonContainer>
							</TopContainer>
						</ModalHeader>
						<ModalBody>
							{/* @ts-expect-error I assure, this is working */}
							<Splitter
								style={{ height: `calc(${height}px - 200px)` }}
								direction="vertical"
								sizes={props.isVisit && isOpen ? [66, 33] : [100]}
								ishidden={(!(props.isVisit && isOpen)).toString()}
								minSize={0}
							>
								<div style={{ overflow: 'auto', height: props.isVisit && isOpen ? undefined : '100%' }}>
									<SidelyCalendar
										setEvents={setEvents}
										events={events}
										setLoadingState={setLoadingState}
										loadingState={loadingState}
										style={{ width: 'auto', margin: 'auto' }}
										restricted
										id={props.id}
										defaultDate={events[0]?.start}
										onEventHover={event => setHovered(event?.id?.toString())}
										hoveredEventId={hoveredEvent}
									/>
									<ComponentLoader
										loaderId='loader-optimization'
										height='100%'
										top='15px'
									/>
								</div>
								<div style={{ height: props.isVisit && isOpen ? undefined : 0, overflow: 'hidden' }}>
									{props.isVisit && isOpen &&
										<CalendarMapView mapEvents={[{
											events: events
												.filter(event => event.type == EventsTypes.TemporaryEvent)
												.map(event => {
													const company = props.companies.find(c => (c.id ?? c.company_id) == event.clientCompanyId) ?? {};
													return {
														companyId: company.id ?? company.company_id,
														eventId: event.id,
														eventName: event.name,
														lat: company.lat,
														lng: company.lon,
														time: moment(event.start ?? event.startDate),
														companyName: company.name
													};
												})
												.filter(e => e.lat && e.lng)
										}]}
										hovered={hovered}
										setHoveredEvent={setHoveredEvent}
										height='100%'
										width={width - 232}
										/>
									}
								</div>
							</Splitter>
							<OptimizePopup
								companies={props.companies.filter(c => c.lat && c.lon).map(c => ({ id: c.id, lat: c.lat, lng: c.lon }))}
								errorCompanies={props.companies.filter(c => !c.lat || !c.lon)}
								onOptimize={props.onOptimize}
								isOpen={optimizeOpen}
								setIsOpen={setOptimizeOpen}
							/>
						</ModalBody>
					</React.Fragment>
				</ModalNoClose>
			</Modal>
		</div>
	);
}

const ComponentLoaderBackground = styled.div<{ allScreen?: boolean, zIndex?: string, height?: string, width?: string, top?: string, left?: string, isLoading?: boolean, noBackground?: boolean }>`
	width: ${p => p.width ?? '100%'};
	height: ${p => p.height ?? '100%'};
	${p => p.noBackground ? '' : 'background-color: black; filter: opacity(0.4);'}
	position: absolute;
	top: ${p => p.top ?? '0'};
	left: ${p => p.left ?? '0'};
	display: ${p => p.isLoading ? 'flex' : 'none'};
	align-items: center;
	justify-content: center;
	${p => p.zIndex ? `z-index: ${p.zIndex};` : ''}
	${p => p.allScreen
		? `
		width: 100vw;
		height: 100vh;
		z-index: 99999;
		margin-left: -57px;
	`
		: ''}
`;

export const RoundProgressBar = styled.div<{ value: number, size: number, background?: string, borderSize?: number }>`
	flex-shrink: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	width: ${p => p.size}px;
	height: ${p => p.size}px;
	border-radius: 50%;
	background: 
	  radial-gradient(closest-side, ${p => p.background ?? 'white'} calc(100% - ${p => p.borderSize ?? 2}px), transparent calc(100% - ${p => p.borderSize ?? 2}px) 100%),
	  conic-gradient(${BlueSidely} ${p => p.value * 100}%, ${p => p.background ?? 'white'} ${p => p.value * 100}%);
`;

export function ComponentLoader(props: {
  loaderId?: string
  height?: string
  top?: string
  left?: string
  loadingState?: LoadingStateEnum
  zIndex?: string
  allScreen?: boolean
  noBackground?: boolean
}) {
	// @ts-expect-error state
	const selector: WebSocketState = useSelector(state => state.webSocket);
	const { loaderId, height, top, left, allScreen, noBackground } = props;
	const { getLoaderStatus } = React.useContext(LoaderContext);

	const isLoading = (props.loadingState || getLoaderStatus(loaderId ?? '')) == LoadingStateEnum.LOADING;

	return <ComponentLoaderBackground height={height} top={top} left={left} isLoading={isLoading} zIndex={props.zIndex} allScreen={allScreen} noBackground={noBackground}>
		{selector.percentage > 0 && selector.percentage < 1
			? <RoundProgressBar value={selector.percentage} size={80} background='black' borderSize={5} />
			: <Loader />
		}
	</ComponentLoaderBackground>;
}