import axios from 'axios';
import { Cookies } from 'react-cookie';
import { URL_API, URL_FOUNDATION } from '../../config/keys';
import * as moment from 'moment';
import { EventParams } from './model/Model';
import { CalendarEvent, CheckInEvent, EventsTypes, FormEvent, OrderEvent, PromotionEvent } from './Calendar';
import { FilterQueryResult, FilterTree } from '../../components_v2/filter/model/Model';
import { isAllDayDates } from './utils';
import { CheckInCalendar } from 'bindings/check_ins/CheckInCalendar';
import { UpdatedField } from '../../../../web-types/api';

const cookies = new Cookies();
const PATH = `${URL_API}/api`;
const token = cookies.get('id_token') || null;

axios.defaults.headers.common.Authorization = `${token}`;

// export async function deleteEvent(id: number, refreshData: Function) {
//   axios.defaults.headers.common.Authorization = `${token}`
//   return axios({
//     method: 'delete',
//     url: `${PATH}/events/${id}`
//   })
//     .then(
//       () => refreshData()
//     )
// }

export async function getCalendarPromotions(dates: { start: Date, end: Date }): Promise<PromotionEvent[]> {
	return axios.get(`${URL_FOUNDATION}/api/calendar/promotions?start=${dates.start.toISOString()}&end=${dates.end.toISOString()}`)
		.then(res => res.data.map(promotion => ({
			...promotion,
			type: EventsTypes.Promotion,
			start: new Date(promotion.start),
			end: new Date(promotion.end)
		})));
}

export async function getCalendarCheckIns(dates: {start: Date, end: Date}, toolBarState: any): Promise<CheckInEvent[]> {
	const query = `start=${dates.start.toISOString()}&end=${dates.end.toISOString()}${toolBarState.userId ? `&incharge=${toolBarState.userId}` : ''}`;
	return axios.get<CheckInCalendar[]>(`${URL_FOUNDATION}/api/v2/check-ins/list-calendar?${query}`).then(res => res.data.map(checkIn => {
		const start = new Date(Date.parse(checkIn.created_at + 'Z'));
		const end = new Date();
		end.setTime(start.getTime() + 1 * 60 * 60 * 1000);
		return {
			type: EventsTypes.CheckIn,
			id: checkIn.id,
			checkIn,
			start,
			end
		};
	}));
}

export async function getCalendarOrders(dates: { start: Date, end: Date }, toolBarStatus: any): Promise<OrderEvent[]> {
	const tags = toolBarStatus?.tags?.length > 0
		? toolBarStatus?.tags?.reduce((acc, tag) => {
			let symbol: string;
			switch (tag) {
				case 'and':
					symbol = '&';
					break;
				case 'or':
					symbol = '|';
					break;
				default:
					symbol = tag;
			}
			return acc + tag;
		}, '')
		: undefined;
	const body = {
		incharge: toolBarStatus?.userId,
		start: moment(dates.start).toISOString(),
		end: moment(dates.end).toISOString(),
		tags: tags
	};

	const res: any[] = await axios.post(`${URL_FOUNDATION}/api/calendar/orders`, body)
		.then(res => res.data.map(order => {
			const start = new Date(Date.parse(order.order_date + 'Z'));
			const end = new Date();
			end.setTime(start.getTime() + 1 * 60 * 60 * 1000);
			return {
				...order,
				start,
				end,
				companieName: order.client_company_name,
				type: EventsTypes.Order,
				incharge: order.owner_id
			};
		}))
		.catch(e => {
			console.error(e);
			return [];
		});
	return res;
}

export async function getCalendarFreeForms(dates, toolBarStatus): Promise<unknown[]> {
	const start = moment(dates.start).format('YYYY-MM-DD');
	const end = moment(dates.end).format('YYYY-MM-DD');
	const params = {
		id: toolBarStatus?.userId,
		start,
		end
	};
	return await axios({
		method: 'get',
		url: `${URL_FOUNDATION}/api/calendar/free-form`,
		params
	}).then(res => res.data.map(c => ({ ...c, date: c.date + 'Z' })));
}

export async function getCalendarShelfAudits(dates, toolBarStatus): Promise<unknown[]> {
	const start = moment(dates.start).format('YYYY-MM-DD');
	const end = moment(dates.end).format('YYYY-MM-DD');
	const params = {
		id: toolBarStatus?.userId,
		start,
		end,
		tags: (toolBarStatus?.tags && toolBarStatus.tags.length ? toolBarStatus.tags.reduce((acc, x) => acc + x + '-', '').slice(0, -1) : undefined)
	};
	return await axios({
		method: 'get',
		url: `${URL_FOUNDATION}/api/calendar/shelf-audit`,
		params
	}).then(res => res.data);
}

export async function getEventFilters() {
	return await axios.get(`${URL_FOUNDATION}/api/calendar/filters`);
}

interface DbEventSnakeCase {
	id: number
	event_type_id: number
	event_status_id: number
	title: string
	start_date: string
	end_date: string
	client_company_id?: number
	contact_id?: number
	incharge: number
	description?: string
	client_company_name?: string
	count: number
	contact_first_name?: string
	contact_last_name?: string
	created_at: string
	created_by: number
	longitude: string
	latitude: string
	form?: number
	scheduled: boolean
}

export interface EventQuery {
	start: string
	end: string
	limit?: number
	offset?: number
	desc?: boolean
	order_by?: string
	new_filters?: FilterTree
	search?: string
}

export async function getEventsV2(data: EventQuery): Promise<{ total: number, events: CalendarEvent[] }> {
	return await axios.post<DbEventSnakeCase[]>(`${URL_FOUNDATION}/api/calendar/event`, data)
		.then(res => ({
			total: res.data?.[0]?.count ?? 0,
			events: res.data.map((event: DbEventSnakeCase): CalendarEvent => {
				const start = new Date(Date.parse(event.start_date + 'Z'));
				const end = new Date(Date.parse(event.end_date + 'Z'));
				return {
					type: EventsTypes.Event,
					id: event.id,
					eventStatusId: event.event_status_id,
					eventTypeId: event.event_type_id,
					title: event.title,
					start,
					end,
					allDay: isAllDayDates(start, end),
					companieName: event.client_company_name,
					incharge: event.incharge,
					description: event.description,
					clientCompanyId: event.client_company_id,
					contactId: event.contact_id,
					contactFirstName: event.contact_first_name,
					contactLastName: event.contact_last_name,
					created_at: new Date(Date.parse(event.created_at + 'Z')),
					created_by: event.created_by,
					latitude: event.latitude ? parseFloat(event.latitude) : undefined,
					longitude: event.longitude ? parseFloat(event.longitude) : undefined,
					form: event.form,
					scheduled: event.scheduled
				};
			})
		}));
}

export async function exportEvents(data: EventQuery): Promise<void> {
	return await axios.post<void>(`${URL_FOUNDATION}/api/calendar/export`, data).then(res => res.data);
}

export async function getEvents(dates: { start: string, end: string }, toolBarStatus?: { userId?: number, statusId?: number, typeId?: number, tags?: string[] }): Promise<CalendarEvent[]> {
	axios.defaults.headers.common.Authorization = `${token}`;
	let new_filters: FilterTree | undefined = { and: [] };

	if (toolBarStatus?.userId) {
		new_filters.and.push({
			val: {
				column: 'incharge',
				operator: 'eq',
				value: toolBarStatus.userId.toString()
			}
		});
	}

	if (toolBarStatus?.statusId) {
		new_filters.and.push({
			val: {
				column: 'event_status_id',
				operator: 'eq',
				value: toolBarStatus.statusId.toString()
			}
		});
	}

	if (toolBarStatus?.typeId) {
		new_filters.and.push({
			val: {
				column: 'event_type_id',
				operator: 'eq',
				value: toolBarStatus.typeId.toString()
			}
		});
	}

	if (toolBarStatus?.tags && toolBarStatus.tags.length > 0) {
		new_filters.and.push({
			val: {
				column: 'tag_id',
				operator: 'combinator',
				value: toolBarStatus.tags.reduce((acc, tagOrOpe) => {
					switch (tagOrOpe) {
						case 'or':
							return acc + '|';
						case 'and':
							return acc + '&';
						default:
							return acc + tagOrOpe;
					}
				})
			}
		});
	}

	if ('and' in new_filters && new_filters.and.length == 0) {
		new_filters = undefined;
	}

	return getEventsV2({
		start: moment(dates.start).toISOString(),
		end: moment(dates.end).toISOString(),
		new_filters
	}).then(res => res.events);
}

export async function postEvent(params: EventParams): Promise<number> {
	try {
		const result = await axios.post(`${URL_FOUNDATION}/api/crm/events`, params)
			.then(response => {
				return response.data;
			});
		return result;
	} catch (error) {
		console.error(error);
		return -1;
	}
}

export async function getEventById(id: number) {
	return await axios({
		method: 'get',
		url: `${PATH}/events/id/${id}`
	});
}

export async function modifyEvent(event) {
	const details = await getEventById(event.id);
	const data = {
		...details.data.data,
		endDate: moment.utc(event.end).format(),
		startDate: moment.utc(event.start).format()

	};
	return await axios({
		method: 'put',
		url: `${PATH}/events/id/${event.id}`,
		data
	});
}

export async function getEventTypes() {
	axios.defaults.headers.common.Authorization = `${token}`;
	return await axios({
		method: 'get',
		url: `${PATH}/eventTypes`
	});
}

export async function getEventStatuses() {
	axios.defaults.headers.common.Authorization = `${token}`;
	return await axios({
		method: 'get',
		url: `${PATH}/eventStatuses`
	});
}

export async function selectInCharge(userId, companyId, role) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	if (role === 'manager') {
		return await axios.get(`${URL_API}/api/users/manager/${userId}`);
	} else {
		return await axios.get(`${URL_API}/api/users/company/${companyId}`);
	}
}

export async function selectContact(clientCompanyId?: number) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	const url = clientCompanyId
		? `${URL_API}/api/contacts/clientCompany/id/${clientCompanyId}`
		: `${URL_API}/api/contacts/AllRefactor`;

	return await axios({
		method: 'get',
		url
	});
}

export async function selectCompany() {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	return await axios({
		method: 'get',
		url: `${URL_API}/api/clientCompanies/AllRefactor`
	});
}

export async function selectCompanyById(id: number) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	return await axios({
		method: 'get',
		url: `${URL_API}/api/clientCompanies/pin/id/${id}`
	});
}

export async function createEvent(data) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	return await axios({
		method: 'POST',
		url: `${PATH}/events`,
		headers: { 'Content-Type': 'multipart/form-data' },
		data
	});
}

export async function editEvent(data, id: number) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	return await axios({
		method: 'PUT',
		url: `${PATH}/events/id/${id}`,
		headers: { 'Content-Type': 'multipart/form-data' },
		data
	});
}

export async function getfilteredCompanies(pattern: string, limit: number | undefined, offset: number | undefined) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	let query = '';
	if (limit !== undefined) {
		query += `&limit=${limit}`;
	}
	if (offset !== undefined) {
		query += `&offset=${offset}`;
	}
	return await axios({
		method: 'GET',
		url: `${URL_FOUNDATION}/api/crm/client-companies/search?pattern=${pattern}${query}`,
		headers: { 'Content-Type': 'multipart/form-data' }
	});
}

export async function deleteEvent(eventId: number, then?: () => void): Promise<void> {
	return await axios.delete(`${URL_FOUNDATION}/api/v2/events/${eventId}`).then(_ => then?.());
}

export async function deleteEvents(events: number[], all: boolean, start: string, end: string, new_filters?: FilterTree): Promise<boolean> {
	const data = {
		events,
		all,
		new_filters,
		start,
		end
	};

	return await axios.post(`${URL_FOUNDATION}/api/v2/events/bulk-edit/delete`, data);
}

interface BulkEditOwnerBody {
  events?: number[]
  owner: number
  all: boolean
  filters?: FilterQueryResult[]
}

export async function bulkEditOwner(body: BulkEditOwnerBody) {
	return await axios.post(`${URL_FOUNDATION}/api/v2/events/bulk-edit/owner`, body);
}

interface bulkEditTypeBody {
  events?: number[]
  type_id: number
  all: boolean
  filters?: FilterQueryResult[]
}

export async function bulkEditType(body: bulkEditTypeBody) {
	return await axios.post(`${URL_FOUNDATION}/api/v2/events/bulk-edit/type`, body);
}

export async function bulkEditDates(body) {
	return await axios.post(`${URL_FOUNDATION}/api/v2/events/bulk-edit/dates`, body);
}

export async function bulkDuplicate(body) {
	return await axios.post(`${URL_FOUNDATION}/api/v2/events/bulk-edit/duplicate`, body);
}

interface BulkEditStatusesBody {
  events?: number[]
  status: number
  all: boolean
  filters?: FilterQueryResult[]
}

export async function bulkEditStatus(body: BulkEditStatusesBody) {
	return await axios.post(`${URL_FOUNDATION}/api/v2/events/bulk-edit/status`, body);
}

export const WEEK_DAY_LIST = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'] as const;
export type WeekDay = typeof WEEK_DAY_LIST[number]
type NaiveDateTime = string

export type VisitsOptimizationBody = {
	visits: Array<{ id: number, duration: number, travel_duration?: number }>,
	first_visit: NaiveDateTime,
	working_hours: { start: NaiveDateTime, end: NaiveDateTime },
	working_days: Array<WeekDay>,
	pause?: { time: NaiveDateTime, duration: number },
	max_event_day?: number,
}

type RetVisit = {
	id: number,
	start: NaiveDateTime,
	end: NaiveDateTime,
}

export async function optimizeVisits(body: VisitsOptimizationBody): Promise<RetVisit[]> {
	return await axios.post<RetVisit[]>(`${URL_FOUNDATION}/api/v2/optimization/visits`, body).then(res => res.data);
}

type QueryEventEditionParams = {
	title?: string,
	description?: UpdatedField<string>,
	company_id?: UpdatedField<number>,
	contact_id?: UpdatedField<number>,
	status_id?: number,
	type_id?: number,
	incharge?: number,
	start?: string,
	end?: string,
	scheduled?: boolean,
}

export type PutEventsBody = [number, QueryEventEditionParams]

export async function putEvents(body: PutEventsBody[]): Promise<null> {
	return await axios.put<null>(`${URL_FOUNDATION}/api/v2/events`, body).then(res => res.data);
}

interface DbEvent {
	clientCompanyId?: number
	contactId?: number
	createdAt: string
	deletedAt: string
	description?: string
	endDate: string
	eventStatusId: number
	eventTypeId: number
	id: number
	incharge: number
	opportunityId?: number
	startDate: string
	title: string
	updatedAt?: string
	scheduled?: boolean
}


export async function getEvent(id: number): Promise<DbEvent | undefined> {
	return axios.get<DbEvent | undefined>(`${URL_FOUNDATION}/api/v2/events/${id}`).then(res => res.data);
} 

export async function getCalendarForms(dates: { start: Date, end: Date }, toolBarStatus): Promise<FormEvent[]> {
	return axios.get(`${URL_FOUNDATION}/api/calendar/forms?start=${dates.start.toISOString()}&end=${dates.end.toISOString()}${toolBarStatus?.userId ? `&incharge=${toolBarStatus.userId}` : ''}`)
		.then(res => res.data.map((instance): FormEvent => ({
			...instance,
			type: EventsTypes.Form,
			start: moment.utc(instance.start).local().toDate(),
			end: moment.utc(instance.end).local().toDate(),
			id: instance.uuid,
			owner_id: instance.created_by
		})));
}