import * as React from 'react';
import Pagination from '../../components_v2/pagination/Pagination';
import { ToolbarState } from '../globals/mainPage/mainPage';
import AdvancedFilters from '../../components_v2/filter/AdvancedFilters';
import { FilterParameter } from '../../components_v2/filter/pages/FilterList';
import { getCompanyFilters } from '../client-companies/data/action';
import { FilterId, FilterResult, filterTreeLength } from '../../components_v2/filter/model/Model';
import ToolbarFilterButton from '../../components_v2/toolbarFilter/ToolbarButton';
import { GalleryModalLeft } from './GalleryModalLeft';
import styled from 'styled-components';
import { InstancePhotos, getPhotosFromForm } from './actions';
import { useRecoilValue } from 'recoil';
import { AUsers } from '../../atoms/global/users';
import { BlackContainer, Card, Close, ImageSwapper, PhotoContainer, PhotoText, UserPhoto } from '../gallery/style';
import noUserImage from 'images/icons/user.svg';
import { DateAccessor, filterIdToName } from '../client-companies/data/CompanyColumns';
import close from 'images/icons/orders/close.svg';
import * as moment from 'moment';
import new_tab_white from 'images/icon/new_tab_white.svg';
import { PaginationResult } from '../../components_v2/pagination/model/Model';
import { ContentWrapper } from '../forms/create-from-template/FormCreation';
import { Translate, translateToString } from '../../styles/global/translate';
import { FormTemplate } from '../forms/jsonValidator';
import { AFormTemplate } from '../../atoms/forms';
import { CardImageDisplayer } from '../forms/FieldDisplayer';
import { DEFAULT_FILTER_RESULT } from '../client-companies/CompaniesAndMapView';
import { LoadingStateEnum } from '../import/model';
import { ComponentLoader } from '../map/modalRight/ModalCalendar';
import { ModalState } from '../products/model';
import { set } from 'lodash';
import { getFormInstancesFilters } from '../forms/actions';
import { ExportButton } from '../../components_v2/popup/style/PopupExportStyle';
import { launchToastError, launchToastMessage } from '../forms/visual-form-editor/visualFormEditor';
import PizZip from 'pizzip';
import JSZipUtils from 'jszip-utils';
import { saveAs } from 'file-saver';
import _ from 'lodash';
import { ApiCount, getApiCounters } from '../settings/subSettings/actions';
import useAlert from '../alert/UseAlert';
import { AlertRes } from '../alert/AlertProvider';
import { toast } from 'react-toastify';
import { AAdditionalColumns, additionalColumnTypeToFilterTypeAndPath, getFirstValueNotPieChart } from '../../atoms/additionalColumns';
import PermissionContext from '../permissions/PermissionContext';
import { AAdditionalFieldColumns, fieldTypeToFilterType } from '../../atoms/additionalFieldColumns';
import { ACalculatedFields } from '../../atoms/calculatedFields';
import { ValueKey } from 'bindings/reports/generic/ValueKey';
import { FilterType } from 'bindings/filters/FilterType';
import { additionalColumnIdFormatter, additionalFieldColumnIdFormatter, calculatedFieldIdFormatter } from '../client-companies/Companies';
import { CompanyStatus, EventType } from '../client-companies/model/Model';
import { getEventTypes, getTags } from '../../components_v2/toolbarFilter/tags/action';
import { getShelfAuditTemplates, ShelfAuditTemplate } from '../../atoms/filter/shelfAuditFilterAtom';
import { getClientStatuses } from '../import/actions';
import { TagType } from '../../components_v2/toolbarFilter/tags/Model';
import { ITag } from 'proto/protobufs';

const DEFAULT_STEP = 10;

export const Wrapper = styled.div<{ isModalOpen: boolean }>`
  margin-left: ${p => p.isModalOpen ? '260px' : '0px'};
  transition: 0.25s;
`;

export const PhotoDetails = styled.div<{ fontWeight?: number, fontSize?: number }>`
    width: fit-content;
    left: 50%;
    transform: translateX(-50%);
    position: relative;
    font-size: ${p => p.fontSize ?? 16}px;
    font-weight: ${p => p.fontWeight ?? 400};
    color: white;
    margin-top: 5px;
`;

const BottomToolBar = styled.div`
	position: fixed;
	bottom: 0;
	width: 100%;
	width: calc(110vw - 450px);
}
`;

type PhotoExport = {
	FolderName: string,
	Photos: {name: string, file: ArrayBuffer}[]
}

async function prepareZip(res: InstancePhotos[], selectedForm: FormTemplate, setProgress?: (progress: number) => void
): Promise<PhotoExport[]> {
	const photo_export: PhotoExport[] = [];

	const res_clone = _.cloneDeep(res);
	const nb_photos = res_clone.reduce((acc, instance) => acc + instance.photos.length, 0);
	
	let photo_count = 0;

	for (const instance of res) {
		const one_folder: PhotoExport = {
			FolderName: selectedForm.name + ' - ' + instance.cc_name + ' - ' + instance.made_at,
			Photos: []
		};
		let count = 1;
		for (const photo of instance.photos) {
			photo_count++;
			setProgress && setProgress((photo_count - 1) / nb_photos);
			const response = await fetch(photo.url);
			const buffer = await response.arrayBuffer();
			const actual_count = ('0' + count).slice(-2).toString();
			one_folder.Photos.push({ name: actual_count + '_' + photo.name + '.webp', file: buffer });
			count++;
		}
		photo_export.push(one_folder);
	}
	setProgress && setProgress(1);
	return photo_export;
}



function zipPhotos(prepared_export: PhotoExport[]) {
	const zip = new PizZip();
	const main_folder = zip.folder('export_gallerie_' + new Date().toISOString().replace(/:/g, '-'));
	prepared_export.forEach((folder) => {
		const folderZip = main_folder.folder(folder.FolderName);
		folder.Photos.forEach((photo) => {
			folderZip.file(photo.name, photo.file);
		});
	});
	const content = zip.generate({ type: 'blob' });
	saveAs(content, 'export_gallerie_' + new Date().toISOString().replace(/:/g, '-') + '.zip');
}

function ExportClick(props: {selectedForm: FormTemplate | undefined, filterResult: FilterResult}): JSX.Element {
	const [apiCounters, setApiCounters] = React.useState<ApiCount>();
	const [nbInstances, setNbInstances] = React.useState<number>(0);
	const [toastId, setTaostId] = React.useState<any>();
	const { selectedForm } = props;
	const { filterResult } = props;
	const [progress, setProgress] = React.useState<number>(0.0);

	React.useEffect(() => {
		toast.update(toastId, { progress: progress });
		
		if (progress === 1) {
			setTimeout(() => {
				toast.dismiss(toastId);
			}, 3000);
			toastId && setTaostId(undefined);
		}
	}, [progress]);


	React.useEffect(() => {
		getApiCounters().then(setApiCounters);
		if (selectedForm) {
			getPhotosFromForm(selectedForm.id, { filters: filterResult.formatted, offset: 0, limit: 100 }).then((res: InstancePhotos[]) => {
				setNbInstances(res.length);
			});
		}
	}, [selectedForm, filterResult]);


	const alert = useAlert();

	return <>
		<ExportButton onClick={
			() => {
				if ((apiCounters?.photo_exports ?? 0) + nbInstances > 100 + (apiCounters?.photo_exports_extra ?? 0)) {
					launchToastError(translateToString('out_of_quota_export_photo'));
					return;
				}
				alert({
					title: <Translate id='export_of_photos' />,
					content: translateToString('warning_quota_will_change') + (apiCounters?.photo_exports ?? 0).toString() + '/' + (100 + (apiCounters?.photo_exports_extra ?? 0)) + ' ' + translateToString('to') + ' ' + ((apiCounters?.photo_exports ?? 0) + nbInstances).toString() + '/' + (100 + (apiCounters?.photo_exports_extra ?? 0)) + '.',
				}).then((res) => {
					if (res === AlertRes.Ok) {
					// check if we already displayed a toast
					if (toastId === undefined) {
						setTaostId(toast.info(translateToString('preparing_download'), {
							position: 'top-right',
							autoClose: false,
							hideProgressBar: false,
							closeOnClick: false,
							pauseOnHover: false,
							draggable: true,
							progress: progress,
						}));
					}
					if (res === AlertRes.Ok) {
						if (selectedForm) {
							getPhotosFromForm(selectedForm.id, { filters: filterResult.formatted, offset: 0, limit: 100, export: true })
								.then((res: InstancePhotos[]) => {
									prepareZip(res, selectedForm, setProgress).then((prepared_export) => {
										zipPhotos(prepared_export);
									});
								});
						} else {
							launchToastError('no form selected');
						}
					} else {
						return;
					}
				}
				});
			}
		}><Translate id={'exports.export_button'} /></ExportButton>
	</>;
}

export default function GalleryV2(props: {
    setToolBarState: (value: ToolbarState) => void
}): JSX.Element {

	const [isFilterOpen, setFilterOpen] = React.useState<boolean>(false);
	const [companyFilterParams, setCompanyFilterParams] = React.useState<FilterParameter[]>([]);
	const [filterParams, setFilterParams] = React.useState<FilterParameter[]>();
	const [filterResult, setFilterResult] = React.useState<FilterResult>(DEFAULT_FILTER_RESULT);

	const additionalColumns = useRecoilValue(AAdditionalColumns);
	const additionalFieldColumns = useRecoilValue(AAdditionalFieldColumns);
	const calculatedFields = useRecoilValue(ACalculatedFields);
	const [allColumns, setAllColumns] = React.useState<{ id: FilterId, name: string }[]>([]);
	const [statuses, setStatuses] = React.useState<CompanyStatus[]>([]);
	const [eventTypes, setEventTypes] = React.useState<EventType[]>([]);
	const [shelfAuditTemplates, setShelfAuditTemplates] = React.useState<ShelfAuditTemplate[]>([]);
	const [tags, setTags] = React.useState<ITag[]>([]);

	
	const [isModalOpen, setModalOpen] = React.useState<boolean>(true);
	const { isAllowedTo } = React.useContext(PermissionContext);
	const formTemplates = useRecoilValue(AFormTemplate);
	const [selectedForm, setSelectedForm] = React.useState<FormTemplate>();
    
	React.useEffect(() => {
		getCompanyFilters().then(async response => {
			const allFirstValues = await getFirstValueNotPieChart();
			const calculatedFirstValues = allFirstValues.filter(fv => fv.column_type === 'calculated_field');
			const newFilterParams = [...response.filters];
			let newAllColumns = [...response.columns.map(id => ({ id, name: filterIdToName(id) }))];
			if (isAllowedTo({ objectAction: 'ReadForm' })) {
				newFilterParams.push(
					additionalColumns.reduce((acc: FilterParameter, x) => {
						if (x.type === 'ReportColumn') return acc;
						const { type, path } = additionalColumnTypeToFilterTypeAndPath(x.type);
						acc.filters.push({
							id: { additional_columns: [{ id: x.id, path: path ?? null, name: false }] },
							name: x.name,
							type
						});
						return acc;
					}, { category: translateToString('additional_columns'), filters: [] })
				);
				newFilterParams.push(
					additionalFieldColumns.reduce((acc: FilterParameter, x) => {
						acc.filters.push({
							id: { additional_field_columns: [x.field_id] },
							name: x.field_name,
							type:  fieldTypeToFilterType(x.field_type)
						});
						return acc;
					}, { category: translateToString('additional_field_columns'), filters: [] }));

				newFilterParams.push(calculatedFields.reduce((acc: FilterParameter, x) => {
					let type: FilterType;
					let path: ValueKey | undefined = undefined;
					const firstValue = calculatedFirstValues.find(fv => fv.id === x.id);
					if (!firstValue || !firstValue.value) {
						type = 'nullable';
					} else if ('int' in firstValue.value || 'float' in firstValue.value) {
						type = 'numeric';
					} else if ('percentage' in firstValue.value || 'evolution' in firstValue.value) {
						type = 'percentage';
					} else if ('key' in firstValue.value || 'text' in firstValue.value) {
						type = 'string';
					} else {
						type = 'nullable';
					}
					if (firstValue && firstValue.value) {
						path = Object.keys(firstValue.value)[0] as ValueKey;
					}
					acc.filters.push({
						id: { calculated_field_columns: [{ id: x.id, path: path ?? null, name: false }] },
						name: x.field_name,
						type
					});
					return acc;
				}, { category: translateToString('calculated_fields'), filters: [] }));
				
				newAllColumns = [
					...newAllColumns,
					...additionalColumns.map(ac => ({ id: { additional_columns: [{ id: ac.id, name: false, path: null }] }, name: additionalColumnIdFormatter(ac.id) })),
					...additionalFieldColumns.map(ac => ({ id: { additional_field_columns: [ac.field_id] }, name: additionalFieldColumnIdFormatter(ac.field_id) })),
					...calculatedFields.map<{id: FilterId, name: string }>(cf => ({ id: { calculated_field_columns: [{ id: cf.id, name: false, path: null }] }, name: calculatedFieldIdFormatter(cf.id) }))
				];
			}
			setFilterParams(newFilterParams);
			setAllColumns(newAllColumns);
		});
		// getClientStatuses().then(response => {
		// 	setStatuses(response.data);
		// });

		// getTags(TagType.COMPANY).then(setTags);
		// getEventTypes().then(setEventTypes);
		// getShelfAuditTemplates().then(setShelfAuditTemplates);
	}, []);

	React.useEffect(() => {
		setSelectedForm(formTemplates[0]);
	}, [formTemplates]);

	React.useEffect(() => {
		props.setToolBarState({
			title: translateToString('gallery.title'),
			bottomLeftToolbarComponent: <>
				<ToolbarFilterButton
					activeFilters={filterTreeLength(filterResult.formatted)}
					onClick={() => {
						setFilterOpen(true);
					}}
					onDeleteFilter={() => {
						setFilterResult({ values: { combinator: 'and', array: [] }, formatted: undefined });
					}}
				/></>,
			bottomRightToolbarComponent: <ExportClick selectedForm={selectedForm} filterResult={filterResult} />
		});
	}, [selectedForm, filterResult]);

	return <>
		<AdvancedFilters
			isOpen={isFilterOpen}
			setOpen={setFilterOpen}
			filterList={filterParams}
			isOpenSummary={true}
			onChange={setFilterResult}
			filterValues={filterResult.values} />
		<GalleryModalLeft
			templateList={formTemplates}
			selected={selectedForm ? selectedForm : formTemplates[0]}
			setSelected={setSelectedForm}
			isModalOpen={isModalOpen}
			setIsModalOpen={setModalOpen}
		/>
		<Wrapper isModalOpen={isModalOpen}>
			{selectedForm &&
                <InstanceGallery form={selectedForm} filters={filterResult} />
			}
		</Wrapper> 
	</>;
}

function InstanceGallery(props: {
    form: FormTemplate
    filters: FilterResult
}) {
	const [allInstancesWithPhotos, setAllInstancesWithPhotos] = React.useState<InstancePhotos[]>([]);
	const [modalInfo, setModalInfo] = React.useState<ModalState<{instances: InstancePhotos[], index: number}>>({ isOpen: false });
	const [pagination, setPagination] = React.useState<PaginationResult>({ currentPage: 1, step: DEFAULT_STEP, offset: 0 });
	const Users = useRecoilValue(AUsers);
	const [loadingState, setLoadingState] = React.useState<LoadingStateEnum>(LoadingStateEnum.LOADED);

	const closeModal = () => {
		setModalInfo({ isOpen: false });
	};

	React.useEffect(() => {
		setLoadingState(LoadingStateEnum.LOADING);
		getPhotosFromForm(props.form?.id, { filters: props.filters.formatted, offset: pagination.offset, limit: pagination.step })
			.then((res) => {
				setAllInstancesWithPhotos(res);
				setLoadingState(LoadingStateEnum.LOADED);
			})
			.catch(() => setLoadingState(LoadingStateEnum.ERROR))
		;
	}, [props.filters, props.form.id, pagination]);

	return <>
		<ComponentLoader loadingState={loadingState} allScreen />
		{modalInfo.isOpen && modalInfo.data && <BlackContainer>
			<PhotosList closeModal={closeModal} form={props.form} instances={modalInfo.data.instances} index={modalInfo.data.index} />
			<Close src={close} onClick={closeModal} />
		</BlackContainer>}
		<ContentWrapper style={{ overflow: 'auto' }}>
			{allInstancesWithPhotos && allInstancesWithPhotos.map((instance, i) => {
				const index = i + pagination.offset;
				return <>
					<Card
						key={`PhotoCard[${index}]`}
						onClick={() => setModalInfo({ isOpen: true, data: { instances: allInstancesWithPhotos, index: i } })}
					>
						<PhotoContainer>
							<CardImageDisplayer images={instance.photos.map(p => p.url_thumb)} onClick={() => undefined} />
						</PhotoContainer>
						<PhotoText size={13} weight={600}>
							{instance.cc_name}
						</PhotoText>
						<PhotoText maxLines={1.5}>
							<UserPhoto src={Users.find(u => u.id == instance.owner)?.photoUrl ?? noUserImage} />
							{Users.find(u => u.id == instance.owner)?.name}
						</PhotoText>
						<PhotoText size={9}>
							<DateAccessor date={instance.made_at} />
						</PhotoText>
					</Card>
				</>;
			})}
			<BottomToolBar>
				<Pagination
					label={translateToString('company.detail.linked_elements.form_instances')}
					steps={[DEFAULT_STEP, 20, 25, 30, 50, 75, 100]}
					onChange={setPagination}
					defaultStep={DEFAULT_STEP}
					isAtTheEnd={allInstancesWithPhotos.length < pagination.step}
				/>
			</BottomToolBar>
		</ContentWrapper>
	</>;
}



export function PhotosList(props: {
	form: FormTemplate
	instances: InstancePhotos[]
	index: number
	closeModal: () => void
}) {
	const [currentInstance, setCurrentInstance] = React.useState<number>(props.index);
	const [index, setIndex] = React.useState<number>(0);
	const url = props.instances[currentInstance].photos[index].url;
	const name = props.instances[currentInstance].photos[index].name;
	const Users = useRecoilValue(AUsers);
	const date = moment(props.instances[currentInstance].made_at);
	const [loadingState, setLoadingState] = React.useState<LoadingStateEnum>(LoadingStateEnum.LOADING);

	React.useEffect(() => {
		setLoadingState(LoadingStateEnum.LOADING);
	}, [currentInstance, index]);

	function changeIndex(newIndex: number) {
		if (newIndex < 0) {
			if (currentInstance > 0) {
				setCurrentInstance(currentInstance - 1);
				setIndex(props.instances[currentInstance - 1].photos.length - 1);
			} else {
				setIndex(props.instances[currentInstance].photos.length - 1);
			}
		} else if (newIndex > props.instances[currentInstance].photos.length - 1) {
			setIndex(0);
			setCurrentInstance((currentInstance + 1) % props.instances.length);
		} else {
			setIndex(newIndex);
		}
	}

	function keyHandler(event) {
		switch (event.key) {
			case 'Escape':
				props.closeModal();
				return;
			case 'ArrowLeft':
				changeIndex(index - 1);
				return;
			case 'ArrowRight':
				changeIndex(index + 1);
		}
	}

	React.useEffect(() => {
		document.addEventListener('keydown', keyHandler, false);
		return () => {
			document.removeEventListener('keydown', keyHandler, false);
		};
	}, [index]);

	return <> 
		<div style={{
			marginTop: 15,
			justifyContent: 'center',
			alignItems: 'center',
			textAlign: 'center'
		}}>
			<PhotoDetails fontSize={14} fontWeight={600}>
				{props.instances[currentInstance].cc_name}
				<div style={{ display: 'inline-block', cursor: 'pointer' }} onClick={() => window.open(`/companies?id=${props.instances[currentInstance].cc_id}`, '_blank')}>
					<img src={new_tab_white} style={{ height: 15, marginLeft: 5 }} />
				</div>
				<div>{ index + 1 } / { props.instances[currentInstance].photos.length }</div>
			</PhotoDetails>
			<br />
			<ImageSwapper onClick={() => changeIndex(index - 1)} />
			<img style={{ height: '69vh', display: loadingState === LoadingStateEnum.LOADING ? 'none' : 'inline' }} onError={() => {
				setLoadingState(LoadingStateEnum.ERROR);
			}} onLoad={() => {
				setLoadingState(LoadingStateEnum.LOADED);
			}} src={url} />
			{loadingState === LoadingStateEnum.LOADING && <div style={{ display:  'inline-block', height: '50px', width: '200px', marginTop: '60px' }}>
				<ComponentLoader loadingState={loadingState} />
			</div>}
			<ImageSwapper right onClick={() => changeIndex(index + 1)} />
			<br />
			<PhotoDetails fontSize={14}>
				<div>{name}</div>
			</PhotoDetails>
			<PhotoDetails fontSize={14}>
				<div>
					{props.form.name}
					<div style={{ display: 'inline-block', cursor: 'pointer' }} onClick={() => window.open(`/form-instance-list?id=${props.form.id}&instance=${props.instances[currentInstance].form_instance_id}`, '_blank')}>
						<img src={new_tab_white} style={{ height: 15, marginLeft: 5 }} />
					</div> &nbsp;-&nbsp;
					{Users.find(u => u.id == props.instances[currentInstance].owner)?.name} -&nbsp;
					{date.format('DD/MM/YYYY - HH:mm')}
				</div>
			</PhotoDetails>
		</div>
	</>;
}