import magnifying_glass_v2 from 'images/icons/magnifying_glass_v2.svg';
import * as React from 'react';
import { DarkGreySidely2, GreySidely2, LightGreySidely } from '../../styles/global/css/Utils';
import Input from './Input';
import { InputProps } from './model/Model';
import { SearchImage } from './style/Style';

function InputSearch(props: InputProps & { noBorderRadius?: boolean }): JSX.Element {
	return (
		(<Input inputStyle={{
			removePlaceHolderOnFocus: true,
			width: '100%',
			height: '37px',
			backgroundColor: LightGreySidely,
			borderRadius: props.noBorderRadius ? undefined : '15px',
			color: DarkGreySidely2,
			placeholderColor: GreySidely2,
			hasInputBorder: true,
			border: 'none',
			textOverflow:'ellipsis',
			containerWidth: '100%',
			margin: '0px'
		}} JSXContent={(
			<SearchImage src={magnifying_glass_v2} noBorderRadius={props.noBorderRadius} />
		)} {...props}/>)
	);
}

export default InputSearch;
