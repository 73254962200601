import React from 'react';
import { ComponentProps } from '../globals/mainPage/mainPage';
import { deleteTeam, getTeams, postTeam, putTeam, Team } from './actions';
import { Table } from '../../components_v2/table/Table';
import { TableRow, TableRowTitle } from '../orders/templateOrders/style/Style';
import InputSearch from '../../components_v2/input/InputSearch';
import { ModalState } from '../products/model';
import Popup from '../../components_v2/popup/Popup';
import { PopupMode } from '../../components_v2/popup/model/Model';
import styled from 'styled-components';
import PopupCreation from '../../components_v2/popup/PopupCreation';
import { useRecoilValue } from 'recoil';
import { AUsers } from '../../atoms/global/users';
import trash_black from 'images/ui_icon/trash_black.svg';
import Input from '../../components_v2/input/Input';
import { creationPopupInputStyle } from '../client-companies/style/Style';
import Add from '../../components_v2/add/Add';
import DropdownOwners, { UserBlock } from '../../components_v2/dropdown/DropdownOwners';
import { LoadingStateEnum } from '../import/model';
import { ComponentLoader } from '../map/modalRight/ModalCalendar';
import { useDeletionAlert } from '../alert/UseAlert';
import { AlertRes } from '../alert/AlertProvider';
import { Translate } from '../../styles/global/translate';
import { ToolbarBox } from '../globals/defaultToolbar/style/Style';

const UserListWrapper = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	overflow: auto;
	flex-shrink: 1;
`;

const UserDiv = styled.div`
	padding: 10px;
	font-size: 14px;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const TrashButton = styled.button`
	background: none;
	border: none;
	height: 23px;
`;

const TrashImage = styled.img`
	height: 100%;
`;

const PopupContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
`;

export default function Teams(props: ComponentProps) {
	const [teams, setTeams] = React.useState<Team[]>([]);
	const [search, setSearch] = React.useState<string>('');
	const [modalState, setModalState] = React.useState<ModalState<Team>>({ isOpen: false });
	const [loadingState, setLoadingState] = React.useState<LoadingStateEnum>(LoadingStateEnum.LOADED);

	const getTeamsAndSetLoadingStates = React.useCallback(async() => {
		setLoadingState(LoadingStateEnum.LOADING);
		try {
			await getTeams().then(setTeams);
			setLoadingState(LoadingStateEnum.LOADED);
		}
		catch (e) {
			console.error(e);
			setLoadingState(LoadingStateEnum.ERROR);
		}
	}, []);


	React.useEffect(() => {
		props.setToolBarState({
			bottomLeftToolbarComponent: <></>,
			bottomRightToolbarComponent: <ToolbarBox><InputSearch name={''} type={'text'} onChange={(t) => setSearch(t.toLowerCase())}/><Add onClick={() => setModalState({ isOpen: true })}/></ToolbarBox>,
		});
	}, []);


	React.useEffect(() => {
		getTeamsAndSetLoadingStates();
	}, []);


	return <>
		<ComponentLoader loadingState={loadingState} allScreen/>
		<Table<Team>
			height="calc(100vh - 174px)"
			columns={React.useMemo(() => [
				{ id: 'name', Header: <Translate id='name' />, accessor: (row) => <TableRowTitle onClick={() => setModalState({ isOpen: true, data: row })}>{row.name}</TableRowTitle>, width: undefined, maxWidth: undefined },
				{ id: 'users', Header: <Translate id='users' />, accessor: (row) => <TableRow>{row.users.length}</TableRow>, width: undefined , maxWidth: undefined },
			], [])}
			data={teams.filter(team => team.name.toLowerCase().includes(search))}
			noBorder
		/>
		<Popup isOpen={modalState.isOpen} popupMode={PopupMode.Details} onClickOut={() => setModalState({ isOpen: false })}>
			{modalState.isOpen && <PopupContent team={modalState.data} onClose={refresh => {
				if (refresh) {
					getTeamsAndSetLoadingStates();
				}
				return setModalState({ isOpen: false });
			}}/>}
		</Popup>
	</>;
}

function PopupContent({ team, onClose }: { team?: Team, onClose: (refresh: boolean) => void }) {
	const [newName, setNewName] = React.useState<string>(team?.name ?? '');
	const [newUserIds, setNewUserIds] = React.useState<number[]>(team?.users ?? []);
	const [loadingState, setLoadingState] = React.useState<LoadingStateEnum>(LoadingStateEnum.LOADED);
	const users = useRecoilValue(AUsers);
	const missingUsers = users.filter(u => !newUserIds.includes(u.id));
	const hasDiff = !team || newName !== team.name || newUserIds.length !== team.users.length || newUserIds.some(id => !team.users.includes(id));
	const alert = useDeletionAlert();

	const onSubmit = async() => {
		setLoadingState(LoadingStateEnum.LOADING);
		try {
			if (team) {
				await putTeam(team.id, { name: newName, users: newUserIds });
			} else {
				await postTeam({ name: newName, users: newUserIds });
			}
			setLoadingState(LoadingStateEnum.LOADED);
			onClose(true);
		} catch (e) {
			console.error(e);
			setLoadingState(LoadingStateEnum.ERROR);
		}
	};


	return <PopupCreation
		loading={loadingState}
		title={team?.name ?? <Translate id='new_team' />}
		onClose={() => onClose(false)}
		onDelete={team ? () => {
			alert({
				name: team.name
			}).then(async(res) => {
				if (res == AlertRes.Ok) {
					setLoadingState(LoadingStateEnum.LOADING);
					try {
						await deleteTeam(team.id);
						setLoadingState(LoadingStateEnum.LOADED);
						onClose(true);
					} catch (e) {
						console.error(e);
						setLoadingState(LoadingStateEnum.ERROR);
					}
				}
			});
		} : undefined}
		messageButton={team ? <Translate id='global.edit' /> : undefined}
		hideValidation={!hasDiff}
		onSubmit={onSubmit}
		canValidate={newName.length > 0}
	>
		<PopupContentWrapper>
			<Input inputStyle={creationPopupInputStyle} name='name' type='text' label='Name' value={newName} onChange={setNewName}/>
			<div/>
			<UserListWrapper>
				{newUserIds.map(userId => <UserDiv key={userId}>
					<UserBlock userId={userId}/>
					<TrashButton onClick={() => setNewUserIds(users => users.filter(u => u !== userId))}><TrashImage src={trash_black}/></TrashButton>
				</UserDiv>)}
			</UserListWrapper>
			{missingUsers.length > 0 && <DropdownOwners onChange={(u) => setNewUserIds(users => [...users, u.value!.id])} users={missingUsers.map(u => ({ value: u, label: u.name }))} JSXButton={() => <Add />}/>}
		</PopupContentWrapper>
	</PopupCreation>;
}