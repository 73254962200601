import * as React from 'react';
import { ComponentProps } from '../globals/mainPage/mainPage';
import { Translate, translateToNode, translateToString } from '../../styles/global/translate';
import { FlexDiv } from '../products/style';
import styled from 'styled-components';
import { BlueSidely, LightGreySidely2, RedSidely, DarkGreySidely2, LightGreySidely, GreySidely2, BorderColor } from '../../styles/global/css/Utils';
import { deleteField, postForm } from './actions';
import { DefaultButton } from '../../styles/global/css/GlobalButton';
import { LoadingStateEnum } from '../import/model';
import { useHistory } from 'react-router';
import { ComponentLoader } from '../map/modalRight/ModalCalendar';
import { Open } from '../../styles/global/css/Open';
import Add from '../../components_v2/add/Add';
import { ToolbarBox } from '../globals/defaultToolbar/style/Style';
import { ButtonStyle } from '../../components_v2/popup/PopupCreation';
import { ModalState } from '../products/model';
import PopupField, { ALL_FIELD_TYPES } from './PopupField';
import { FieldType } from 'bindings/forms/FieldType';
import AdvancedFormEditor from './advancedFormEditor'; 
import { useFunctionState, useKeyPress } from '../../utils/customHooks';
import { getLocalFormById, LocalStorageForm, removeLocalForm, setLocalForm, updateLocalForm } from './localForm';
import { getUrlParameterByName } from '../../components_v2/utils';
import bugSvg from 'images/bug.svg';
import dropdownSvg from 'images/dropdown.svg';
import useAlert from '../alert/UseAlert';
import WarningImage from 'images/icons/import/warning.svg';
import ErrorImage from 'images/icons/import/error.svg';
import { AlertRes } from '../alert/AlertProvider';
import { Field, getFields, importFieldsBySlug } from '../../atoms/forms';
import SettingIcon from 'images/icon/setting.png';
import DeleteIcon from 'images/icon/delete.png';
import InputSearch from '../../components_v2/input/InputSearch';
import PageSwitch, { IconsGroupBackground } from '../map/PageSwitch';
import CompanyBlackImage from 'images/menu_icon/company_black.svg';
import CompanyMapBlackImage from 'images/menu_icon/company_map_black.svg';
import Input from '../../components_v2/input/Input';
import VisualFormEditor, { launchToastError, launchToastMessage } from './visual-form-editor/visualFormEditor';
import { Screen } from './jsonValidator';
import { ButtonImage } from '../notes/style/PopupNoteStyle';
import importIcon from 'images/setting_icons/import_icon.svg';
import exportIcon from 'images/setting_icons/export_icon.svg';
import copyIcon from 'images/icon/copy.png';
import InputFile from '../../components_v2/input/InputFile';
import { FileResult } from '../../components_v2/input/model/Model';



const MAX_WARNING_SEVERITY = 4; 

type ImportType = {
	name: string,
	lib?: string,
	screens: Screen[],
	fields: { [key: string]: Omit<Field, 'id' | 'slug'> }
};

export type MonacoError = {
	code?: string,
	message: string,
	endColumn: number,
	endLineNumber: number,
	owner: string
	relatedInformation?: string
	resource?: object,
	severity: number,
	source: string,
	startColumn: number
	startLineNumber: number
	tags?: string
}

const EditorContainer = styled.div<{ white?: boolean }>`
	height: 100%;
	width: 100%;
	flex-shrink: 1;
	background: ${p => p.white ? 'white' : LightGreySidely2};
`;

const LeftModalSideBar = styled.div`
	width: 50px;
	height: 100%;
	border-right: 1px solid white;
	flex-shrink: 0;
`;

const CopyToClipBoard = styled.div<{ hoverText: string }>`
position: relative;
${p => p.hoverText ? `&:hover {
	::before {
		content: '${p.hoverText}';
		position: absolute;
		transform: translate(-108%,-18%);
		background-color: white;
		font-weight: 400;
		font-size: 12px;
		padding: 5px;
		border: 1px solid ${BorderColor};
		border-radius: 5px;
		white-space: nowrap;
		z-index: 100;
	}
}` : ''}
`;

export const LeftModalContent = styled.div`
	display: flex;
	flex-flow: column;
	padding: 5px 0;
	overflow: auto;
	width: 100%;
`;

const LeftModalSideBarImg = styled.div<{ src?: string, selected?: boolean, size?: string, flex?: boolean }>`
	${({ flex }) => flex ? 'display: flex; justify-content: center; align-items: center;' : ''}
	height: 50px;
	width: 50px;
	${({ src }) => src ? `background-image: url(${src});` : ''}
    background-position: center;
	background-size: ${({ size }) => size ?? 'calc(100% - 5px)'};
    background-repeat: no-repeat;
	cursor: pointer;
	&:before {
		content: '';
		position: absolute;
		width: 3px;
		height: 50px;
		${p => p.selected ? `
			background-color: ${BlueSidely};
		` : ''}
		
	}
`;


const LeftModalContainer = styled.div<{ fullWidth?: boolean, open?: boolean }>`
	background: ${LightGreySidely2};
	width: ${({ fullWidth, open }) => fullWidth ? '100%' : (open === false ? '50px' : '25%')};
	height: 100%;
	flex-shrink: 0;
	display: flex;
	transition: width 0.2s;
`;


const P = styled.p`
	font-size: 13px;
	margin: 0;
`;

const ErrorCount = styled.div<{ display: boolean, severity: number }>`
	padding: 5px;
	background-color: ${p => p.severity > MAX_WARNING_SEVERITY ? RedSidely : '#FFB946'};
    position: relative;
    top: 100%;
    left: 100%;
    color: white;
    font-size: 7px;
    border-radius: 50%;
    width: 15px;
    height: 15px;
	display: ${({ display }) => !display ? 'none' : 'flex'};
    justify-content: center;
    align-items: center;
    translate: -100% -100%;
`;

const FieldSvg = styled.div<{ src: string }>`
	width: 17px;
	height: 17px;
	background: url("${p => p.src}");
	background-size: cover;
	flex-shrink: 0;

`;

export const FieldName = styled(P)`
	font-size: 14px;
	overflow: hidden;
	flex-shrink: 1;
	text-overflow: ellipsis;
	white-space: nowrap;
	font-weight: 400;
	color: ${DarkGreySidely2};
	flex-grow: 1;
`;

export const FieldContainer = styled.div<{ padding?: string, justify?: string}>`
	display: flex;
	align-items: center;
	gap: 0.5em;
	cursor: pointer;
	padding: ${({ padding }) => padding ?? '0px 15px'};
	justify-content: ${({ justify }) => justify ?? 'flex-start'};
	height: 30px;
	flex-shrink: 0;
	font-size: 14px;
	&:hover {
		background: lightgrey;
	}
`;

export const OpenContainer = styled(FieldContainer)`
	font-size: 14px;
	font-weight: 500;
`;

const FieldId = styled(P)`
	color: ${BlueSidely};
	display: flex;
    justify-content: center;
    align-items: center;
    // border: 1px solid ${BlueSidely};
    width: 15px;
    height: 15px;
    border-radius: 5px;
	font-size: 10px;
`;

const Button = styled(DefaultButton)`
	margin: 0;
`;

const Invisible = styled.div<{hide?: boolean}>`
	display: ${({ hide }) => hide ? 'none' : 'block'};
`;

export type ModalData = {
	type?: FieldType,
	field_id?: number,
	name?: string,
	slug?: string,
	is_company?: boolean,
	is_form?: boolean,
	is_additional?: boolean,
	is_product?: boolean,
	constraint?: object
}


const RemoveIconDiv = styled.div`
	cursor: pointer;
`;

const SettingIconDiv = styled.div`
	cursor: pointer;
`;

const InputFildChild = styled.img`
	width: 23px;
	height: 23px;
`;


export function TypeFieldsContainer(props: { search?: string, fields: Field[], type: FieldType, onFieldClicked?: (field: Field) => void, setModalState: React.Dispatch<React.SetStateAction<ModalState<ModalData>>>, creationNotAllowed?: boolean, hideId?: boolean, allowEdit?: boolean }) {
	const [isOpen, setIsOpen] = React.useState(true);
	const { type } = props;
	const alert = useAlert();

	const [fields, setFields] = React.useState<Field[]>(props.fields);

	React.useEffect(() => {
		setFields(props.fields);
	}, [JSON.stringify(props.fields)]);

	const filteredFields = fields.filter(f => f.name.toLocaleLowerCase().includes(props.search?.toLocaleLowerCase() ?? ''));
	if (filteredFields.length === 0) return <></>;

	return <>
		<FlexDiv flow='column' align='stretch' margin='10px 0px 0px 0px'>
			<OpenContainer onClick={() => setIsOpen(!isOpen)} justify='space-between'>
				{props.hideId ? translateToString('report_editor.form_field_types.' + type.toLowerCase()) : type}
				{fields.length != 0 && <Open isOpen={isOpen} size={15} />}
			</OpenContainer>
			{isOpen &&
				<FlexDiv flow='column' align='stretch'>
					{filteredFields.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1).map(field =>
						<FieldContainer key={`Field[${field.id}]`} onClick={() => props.onFieldClicked?.(field)}>
							<Invisible hide={props.hideId}><FieldId>{field.id}</FieldId></Invisible>
							<FieldName>{field.name}</FieldName>
							<FlexDiv style={{ float: 'right' }}>
								{props.allowEdit && <SettingIconDiv>
									<img src={SettingIcon} style={{ width: '15px', height: '15px' }}
										onClick={e => 
										{
											e.stopPropagation();
											props.setModalState({ isOpen: true, data: { 
												type: type,
												field_id: field.id,
												slug: field.slug,
												is_company: field.is_company,
												is_form: field.is_form,
												is_additional: field.is_additional,
												is_product: field.is_product,
												constraint: field.constraint
											} });
										}}
									/>
								</SettingIconDiv>}
								{props.allowEdit && <RemoveIconDiv>
									<img src={DeleteIcon} style={{ width: '15px', height: '15px' }}
										onClick={e => 
										{
											e.stopPropagation();
											alert({
												title: translateToNode('import.are_you_sure'),
												content: <>{translateToString('form_editor.delete_field')}{field.name} ?</>,
												buttons: [
													{
														title: translateToNode('cancel'),
														res: AlertRes.Break,
														style: ButtonStyle.White
				
													},
													{
														title: translateToNode('yes'),
														res: AlertRes.Ok
													}
												]
											}).then(res => {
												if (res === AlertRes.Break) return;
												deleteField(field.id).then(() => {
													setFields(fields.filter(f => f.id !== field.id));
												});
											});
										}}
									/>
								</RemoveIconDiv>}
								<CopyToClipBoard hoverText='Copier le slug'>
									<img src={copyIcon} width='15px' height='15px' style={{ cursor: 'pointer' }} onClick={
										e => {
											e.preventDefault();
											e.stopPropagation();
											// copy to clipboard
											navigator.clipboard.writeText(field.slug);
											launchToastMessage('Le slug (' + field.slug + ') à été copié dans le presse-papier');
										}
									} />
								</CopyToClipBoard>
							</FlexDiv>
						</FieldContainer>
						
					)}
					{!props.creationNotAllowed &&
						<FieldContainer onClick={() => props.setModalState({ isOpen: true, data: { type: type } })}><Add size='17px' /></FieldContainer>}
				</FlexDiv>
			}
		</FlexDiv>
	</>;
}

export function LeftModal(props: {
	onFieldClicked?: (field: Field) => void,
	errors: MonacoError[][],
	onErrorClicked?: (error: MonacoError) => void,
	creationNotAllowed?: boolean,
	externalUse?: boolean,
	allowEdit?: boolean,
	fields?: { [type in FieldType]: Field[]},
	refreshFields?: () => void
}) {
	const [fields, setFields] = React.useState<{ [type in FieldType]: Field[]} | undefined>(props.fields);
	const [modalState, setModalState] = React.useState<ModalState<ModalData>>({ isOpen: false, data: undefined });
	const [selectedPanel, setSelectedPanel] = React.useState<'fields' | 'errors'>('fields');
	const [open, setOpen] = React.useState(props.externalUse ? true : false);
	const refresh = React.useCallback(() => {
		getFields().then(fields => setFields(fields.reduce((acc: { [key in FieldType]: Field[] }, f) => {
			if (!acc[f.type]) acc[f.type] = [];
			acc[f.type].push(f);
			return acc;
		}, {} as { [key in FieldType]: Field[] })));
	}, []);
	const [search, setSearch] = React.useState<string>('');

	React.useEffect(() => {
		if (props.externalUse) return;
		setFields(props.fields);
	}, [props.fields]);

	React.useEffect(() => {
		if (!props.externalUse) return;
		refresh();
	}, []);
	
	return <>
		<LeftModalContainer fullWidth={props.externalUse} open={open}>
			{!props.externalUse && <LeftModalSideBar>
				<LeftModalSideBarImg onClick={() => setOpen(!open)} flex>
					<Open isOpen={open} size={15} horizontal />
				</LeftModalSideBarImg>
				<LeftModalSideBarImg src={dropdownSvg} selected={selectedPanel === 'fields'} onClick={() => setSelectedPanel('fields')} />
				<LeftModalSideBarImg src={bugSvg} size='60%' selected={selectedPanel === 'errors'} onClick={() => setSelectedPanel('errors')}>
					<ErrorCount display={props.errors && props.errors[0] && props.errors[0].length !== 0} severity={props.errors && props.errors[0] && props.errors[0].reduce((acc, c) => Math.max(acc, c.severity), 0)}>{props.errors[0]?.length}</ErrorCount>
				</LeftModalSideBarImg>
			</LeftModalSideBar>}
			{open && <>
				<LeftModalContent>
					{selectedPanel === 'fields' && <>
						<FlexDiv padding='0 10px' gap='10px'>
							<InputSearch name={''} value={search} type={'text'} onChange={setSearch}/>
							{!props.creationNotAllowed && <Add onClick={() => setModalState({ isOpen: true, data: { name: search } })} />}
						</FlexDiv>
					{...ALL_FIELD_TYPES.map((type) => <TypeFieldsContainer
						search={search}
						key={`Key[${type}]`}
						fields={fields?.[type] ?? []}
						type={type}
						onFieldClicked={props.onFieldClicked}
						setModalState={setModalState}
						creationNotAllowed={props.creationNotAllowed}
						allowEdit={props.allowEdit} />)}
					</>}
					{selectedPanel === 'errors' && <>
						<OpenContainer>{props.errors[0]?.length ?? 0} {translateToString('error(s)')}</OpenContainer>
						{...props.errors?.[0]?.sort((a, b) => b.severity - a.severity).map((error, i) => {
							return <FieldContainer key={`error${i}`} onClick={() => props.onErrorClicked?.(error)}>
								<FieldSvg src={error.severity > MAX_WARNING_SEVERITY ? ErrorImage : WarningImage} />
								<FieldName>{error['message']}</FieldName>
							</FieldContainer>;
						}) ?? []}
					</>}
				</LeftModalContent>
			</>}
		</LeftModalContainer>
		{modalState.data && <PopupField
			onCreate={() => {
				refresh();
				props.refreshFields?.();
				setModalState({ isOpen: false });
			}}
			isOpen={modalState.isOpen}
			setIsOpen={isOpen => setModalState({ isOpen })}
			field={modalState.data}
		/>}
	</>;
}

type Path = 'visual' | 'advanced';

const DEFAULT_JSON: Screen[] = [{
	components: [],
	fields: [],
	layout: {
		children: [],
		type: 'column'
	},
	name: 'Nouvelle page',
	next_screens: [
		{
			'condition': 'true'
		}
	]
}];

export function FormTemplateImportButton(props: {
	onImportFinished: (importType: LocalStorageForm) => void,
	onImportStarted?: () => void,
	formId?: number,
	formName?: string,
	formScreens?: Screen[],
	formLib?: string,
}) {
	const onImport = async(file: FileResult) => {
		props.onImportStarted?.();
		const obj: ImportType = JSON.parse(file.content);
		const newForm: LocalStorageForm = { name: props.formName ?? obj.name, screens: props.formScreens ?? obj.screens, lib: props.formLib ?? obj.lib };
		updateLocalForm(newForm, props.formId);
		await importFieldsBySlug(Object.entries(obj.fields).reduce<Omit<Field, 'id'>[]>((acc, [slug, field]) => {
			acc.push({ ...field, slug });
			return acc;
		}, []));
		props.onImportFinished(newForm);
	};

	return <InputFile readAsText child={<InputFildChild src={importIcon}/>} accept='application/json' onChange={onImport}/>;
}

export default function FormTemplateCreator(props: ComponentProps) {
	const history = useHistory();
	const formId = parseInt(getUrlParameterByName('id') ?? 'NaN');
	const form = getLocalFormById(isNaN(formId) ? undefined : formId);
	if (!form) {
		history.replace({ pathname: 'settings', hash: 'form_templates' });
		return <></>;
	}
	const [screens, setScreens] = useFunctionState<Screen[]>(form?.screens ?? DEFAULT_JSON, ({ newValue }) => {
		updateLocalForm({ screens: newValue }, formId);
		return newValue;
	});
	const [lib, setLib] = useFunctionState<string>(form?.lib ?? '', ({ newValue }) => {
		updateLocalForm({ lib: newValue }, formId);
		return newValue;
	});
	const [errors, setErrors] = React.useState<MonacoError[][]>([]);
	const [loadingState, setLoadingState] = React.useState(LoadingStateEnum.LOADED);
	// eslint-disable-next-line @typescript-eslint/type-annotation-spacing
	const ref = React.useRef<{ onFieldClicked? :(field: Field) => void, onErrorClicked?: (error: MonacoError) => void, onNewScreen?: () => void }>();
	const [selectedPath, setSelectedPath, setSelectedPathNoFn] = useFunctionState<Path | undefined>(undefined, ({ newValue }) => {
		const formId = parseInt(getUrlParameterByName('id') ?? 'NaN');
		history.replace({ pathname: 'form-template', search: isNaN(formId) ? undefined : `?id=${formId}`, hash: newValue });
		return newValue;
	});
	const alert = useAlert();
	const [fields, setFields] = React.useState<{ [type in FieldType]: Field[]}>();
	const refreshFields = React.useCallback(() => {
		getFields().then(fields => setFields(fields.reduce((acc: { [key in FieldType]: Field[] }, f) => {
			if (!acc[f.type]) acc[f.type] = [];
			acc[f.type].push(f);
			return acc;
		}, {} as { [key in FieldType]: Field[] })));
	}, []);

	React.useEffect(() => {
		refreshFields();
	}, []);

	function checkUrlParams() {
		if (window.location.hash) {
			const newPath = window.location.hash.slice(1) as Path;
			if (selectedPath === newPath) return;
			setSelectedPathNoFn(newPath);
		}
	}

	React.useEffect(() => {
		checkUrlParams();
		history.listen(checkUrlParams);
	}, [history]);


	const saveForm = React.useCallback((quit: boolean) => async() => {
		if (errors.some(e => e.some(e => e.severity > MAX_WARNING_SEVERITY))) {
			return alert({
				svg: 'error',
				title: translateToString('error'),
				content: translateToString('cant_save_errors', [['NUMBER', errors.reduce((acc, e) => acc + e.filter(e => e.severity > MAX_WARNING_SEVERITY).length, 0).toString()]]),
				noButtons: true
			});
		}
		if (errors.some(e => e.length)) {
			const res = await alert({
				svg: 'warning',
				title: translateToString('Attention'),
				content: translateToString('are_you_sure_save_warning', [['NUMBER', errors.reduce((acc, e) => acc + e.length, 0).toString()]]),
				buttons: [{ res: AlertRes.Ok, title: translateToString('yes') }]
			});
			if (res == AlertRes.Break) return;
		}
		const form = getLocalFormById(formId);
		if (!form) return;
		setLoadingState(LoadingStateEnum.LOADING);
		postForm({ ...form, screens: form.screens ?? [] }).then(() => {
			if (quit) {
				removeLocalForm(formId);
				history.push({ pathname: 'settings', hash: 'form_templates' });
			} else {
				setLoadingState(LoadingStateEnum.LOADED);
			}
		});
	}, [errors]);
	useKeyPress([{ key: 's', metaKey: true }], () => {
		saveForm(false)();
	});

	const exportForm = React.useCallback(() => {
		setFields(fields => {
			const form = getLocalFormById(formId);
			if (!form) return;
			const fieldsArray = Object.values(fields ?? {}).flat();
			const screens = form.screens ?? [];
			const exportValue: ImportType = {
				name: form.name,
				lib: form.lib,
				screens,
				fields: screens.reduce<{ [key: string]: Omit<Field, 'id' | 'slug'> }>((acc, screen) => {
					const t = screen.fields.reduce<{ [key: string]: Omit<Field, 'id' | 'slug'> }>((acc, field) => {
						if (!field.slug && field.field_id) {
							const findedField = fieldsArray.find(f => f.id === field.field_id);
							if (!findedField) return acc;
							acc[findedField.slug] = { name: findedField?.name, type: findedField?.type, data: findedField?.data };
							return acc;
						} else if (!field.slug) return acc;
						const findedField = fieldsArray.find(f => f.slug === field.slug);
						if (!findedField) return acc;
						acc[field.slug] = { name: findedField.name, type: findedField.type, data: findedField.data };
						return acc;
					}, {});
					return { ...acc, ...t };
				}, {}),
			};
			const blob = new Blob([JSON.stringify(exportValue ?? '{}')], { type: 'application/json' });
			const url = URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.href = url;
			a.download = `${form.name}.json`;
			a.click();
			URL.revokeObjectURL(url);

			return fields;
		});
	}, []);

	React.useEffect(() => {
		props.setToolBarState({
			title: 'Éditeur de formulaire',
			bottomLeftToolbarComponent: <ToolbarBox>
				<Input inputStyle={{
					removePlaceHolderOnFocus: true,
					height: '37px',
					backgroundColor: LightGreySidely,
					borderRadius: '15px',
					color: DarkGreySidely2,
					placeholderColor: GreySidely2,
					hasInputBorder: true,
					border: 'none',
					textOverflow:'ellipsis',
					containerWidth: '100%',
					padding: '10px'
				}}
				name='form-name' spellcheck={false} type='text' value={form.name} onChange={(value) => {
					form.name = value;
					const newForm = getLocalFormById(formId);
					if (newForm) {
						newForm.name = value;
						setLocalForm(newForm, formId);
					}
				}} />
				<PageSwitch<Path>
					selectedValue={selectedPath}
					list={[
						{ value: 'visual', src: CompanyBlackImage, onClick: setSelectedPath },
						{ value: 'advanced', src: CompanyMapBlackImage, onClick: setSelectedPath }
					]} />
				<IconsGroupBackground>
					<FormTemplateImportButton onImportStarted={() => setLoadingState(LoadingStateEnum.LOADING)} onImportFinished={(obj) => {
						setScreens(obj.screens ?? []);
						setLib(obj.lib ?? '');
						refreshFields();
						setLoadingState(LoadingStateEnum.LOADED);
					}} formId={formId}/>
					<ButtonImage src={exportIcon} onClick={exportForm} canHover size='23px'/>

				</IconsGroupBackground>
			</ToolbarBox>,
			bottomRightToolbarComponent: <ToolbarBox>
				<Button
					onClick={() => {
						const newPageName = prompt('Nom de la nouvelle page');
						if (newPageName && form) {
							setScreens(screens => [...screens, { ...defaultPage, name: newPageName }]);
							ref.current?.onNewScreen?.();
						}
					}}
				>
					<Translate id='form_editor.new_page' />
				</Button>
				<Button onClick={() => history.push({ pathname: 'settings', hash: 'form_templates' })} buttonStyle={ButtonStyle.White}><Translate id='back' /></Button>
				{!!formId && <Button onClick={saveForm(false)}><Translate id='save' /></Button>}
				<Button onClick={saveForm(true)}><Translate id='save_and_quit' /></Button>
			</ToolbarBox>
		});
	}, [errors, selectedPath, JSON.stringify(form)]);

	return <>
		<FlexDiv width='100%' height="calc(100vh - 120px)">
			<LeftModal
				onFieldClicked={ref.current?.onFieldClicked}
				allowEdit={true}
				onErrorClicked={ref.current?.onErrorClicked}
				errors={errors}
				fields={fields}
				refreshFields={refreshFields}
			/>
			<EditorContainer white={selectedPath === 'visual'}>
				{selectedPath === 'advanced' && <AdvancedFormEditor
					ref={ref}
					setLoadingState={setLoadingState}
					setErrors={setErrors}
					screens={screens}
					setScreens={setScreens}
					lib={lib}
					setLib={setLib}
				/>}
				{selectedPath === 'visual' && <VisualFormEditor
					ref={ref}
					setLoadingState={setLoadingState}
					setErrors={setErrors}
					screens={JSON.stringify(screens)}
					setScreens={(screens) => setScreens(JSON.parse(screens))}
					lib={lib}
					setLib={setLib}
					exportForm={exportForm}
				/>}
			</EditorContainer>
			<ComponentLoader loadingState={loadingState} allScreen/>
		</FlexDiv>
	</>;
}

const defaultPage: Screen = {
	components: [],
	fields: [],
	layout: {
		children: [],
		type: 'column'
	},
	next_screens: [
		{
			'condition': 'true'
		}
	],
	name: 'default_page'
};