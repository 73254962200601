import axios from 'axios';
import { URL_FOUNDATION, URL_JANUS } from '../../../config/keys';
import { Cookies } from 'react-cookie';
import { CompanyStatus } from '../../client-companies/model/Model';
import { TagType } from '../../../components_v2/toolbarFilter/tags/Model';
import { CreationEditionOrDeletion, Id } from './statusesAndTagsSettings';
import { OauthConfigurationState } from './thirdPartyIntegration';
import { EmailAutomation } from './orderNotificationsSettings';
import { EmailTemplate } from '../../email-templates/model/models';
import { EmailTemplateSubmit } from '../../email-templates/model/models';

const cookies = new Cookies();
const PATH = `${URL_FOUNDATION}/api`;
const token = cookies.get('id_token') || null;
axios.defaults.headers.common.Authorization = `${token}`;

export async function getPkceLink(third_party: string): Promise<{ url: string, token: string, verifier: string }> {
	return axios.get(`${URL_JANUS}/api/v1/oauth/pkce-token?third_party=${third_party}`).then(res => res.data);
}

const getPathFromTagType = (tagType: TagType): string => {
	switch (tagType) {
		case TagType.COMPANY: return 'client-companies';
		case TagType.CONTACT: return 'contacts';
		case TagType.PRODUCT: return 'products';
		case TagType.OPPORTUNITY: return 'opportunities';
		case TagType.ORDER: return 'orders';
		default: throw 'tag type unknow';
	}
};

export async function getClientStatuses(): Promise<CompanyStatus[]> {
	return await axios({
		method: 'get',
		url: `${PATH}/crm/client-company-statuses`
	}).then(res => res.data);
}

export async function getColors(): Promise<(unknown & { colorCode: string, id: number })[]> {
	return await axios({
		method: 'get',
		url: `${PATH}/crm/colors`
	}).then(res => res.data);
}
export async function postStatus(tag_type: TagType, body: { name: string, color_status_id: number, probability?: number }): Promise<number> {
	const target = getPathFromTagType(tag_type);

	return await axios.post(`${PATH}/v2/${target}/statuses`, body).then(res => res.data);
}

export async function deleteStatus(statusId: number, tag_type: TagType): Promise<null> {
	const target = getPathFromTagType(tag_type);

	return await axios.delete(`${PATH}/v2/${target}/statuses/${statusId}`).then(res => res.data);
}

export async function putStatus(statusId: number, tag_type: TagType, body: { name?: string, color_id?: number, probability?: number, sequence?: number }): Promise<null> {
	const target = getPathFromTagType(tag_type);

	return await axios.put(`${PATH}/v2/${target}/statuses/${statusId}`, body).then(res => res.data);
}

export async function postTag(tag_type: TagType, body: { name: string, color_status_id: number }): Promise<number> {
	const target = getPathFromTagType(tag_type);

	return axios.post(`${URL_FOUNDATION}/api/v2/${target}/tags`, body).then(res => res.data);
}

export async function putTag(tagId: number, tag_type: TagType, body: { name?: string, color_status_id?: number }): Promise<null> {
	const target = getPathFromTagType(tag_type);

	return axios.put(`${URL_FOUNDATION}/api/v2/${target}/tags/${tagId}`, body).then(res => res.data);
}

export async function deleteTag(tagId: number, tag_type: TagType): Promise<null> {
	const target = getPathFromTagType(tag_type);

	return axios.delete(`${URL_FOUNDATION}/api/v2/${target}/tags/${tagId}`).then(res => res.data);
}

export async function deleteBrand(brandId: number): Promise<null> {
	return axios.delete(`${URL_FOUNDATION}/api/brands/${brandId}`).then(res => res.data);
}

export async function deleteMapping(mappingId: number): Promise<null> {
	return axios.delete(`${URL_FOUNDATION}/api/mappings/external/${mappingId}`).then(res => res.data);
}

export async function syncTagsAndStatuses(body: { field_type: TagType, statuses: CreationEditionOrDeletion[], tags: CreationEditionOrDeletion[], additional_columns: CreationEditionOrDeletion[], additional_field_columns: CreationEditionOrDeletion[], calculated_fields: CreationEditionOrDeletion[] }): Promise<void> {
	return axios.post(`${URL_FOUNDATION}/api/v2/settings/sync-tags-and-statuses`, body).then(res => res.data);
}

type DeleteCategoryRes = {
	Ko: {
		CategoryStillHaveProducts: [number, string][]
	}
} | { Ok: [] };

export async function deleteCategory(categoryId: number): Promise<DeleteCategoryRes> {
	return axios.delete(`${URL_FOUNDATION}/api/categories/${categoryId}`).then(res => res.data);
}

export type OauthConfigurationMappings = {
	status: string,
	options: [string, string][]
}

export type OauthConfiguration = {
	id: Id
	oauth_connection_id: boolean
	status: boolean
	create_sidely_to_service: boolean
	create_service_to_sidely: boolean
	update_sidely_to_service: boolean
	update_service_to_sidely: boolean
	delete_sidely_to_service: boolean
	delete_service_to_sidely: boolean
	priority: number
	created_at: Date
	updated_at?: Date
	deleted_at?: Date
	synced_at?: Date
	mappings?: OauthConfigurationMappings
}

export function getOauthConnectionConfigs(thirdParty: string): Promise<OauthConfiguration | { id: Id }> {
	return axios.get(`${URL_JANUS}/api/v1/oauth/config?third_party=${thirdParty}`).then(res => res.data ?? { id: { usync: 1 } });
}

export function getOauthConnectionConfigsPriorities(): Promise<{ id: number, name: string }[]> {
	return axios.get(`${URL_JANUS}/api/v1/oauth/config/priorities`).then(res => res.data);
}

export function syncOauthConnectionConfig(body: { third_party: string, config: CreationEditionOrDeletion<OauthConfigurationState, OauthConfigurationState> }): Promise<void> {
	return axios.post(`${URL_JANUS}/api/v1/oauth/config/sync`, body).then(res => res.data);
}

export function getEmailAutomations(): Promise<EmailAutomation[]> {
	return axios.get(`${URL_FOUNDATION}/api/v2/settings/email-automations`).then(res => res.data);
}

export function syncEmailsAutomations(body: CreationEditionOrDeletion[]): Promise<void> {
	return axios.post(`${URL_FOUNDATION}/api/v2/settings/email-automations/sync`, body).then(res => res.data);
}

export type ApiCount = {
	sent_mail_count: number,
	mail_extra: number,
	route_opimization: number,
	route_opimization_extra: number,
	photo_exports: number,
	photo_exports_extra: number,
}

export function getApiCounters(): Promise<ApiCount> {
	return axios.get(`${URL_FOUNDATION}/api/v2/api-counters`).then(res => res.data);
}

//FIXME: Devide action for Email template??
//Email Template
export async function getEmailTemplates(): Promise<EmailTemplate[]> {
	return axios.get<EmailTemplate[]>(
		`${URL_FOUNDATION}/api/v2/email-templates`
	).then(res => res.data);

}
export async function postEmailTemplate(body: EmailTemplateSubmit): Promise<number> {
	return await axios.post<number>(
		`${URL_FOUNDATION}/api/v2/email-templates`,
		body
	).then(res => res.data);
}
export async function putEmailTemplate(id: number, body: EmailTemplateSubmit): Promise<null> {
	return await axios.put<null>(
		`${URL_FOUNDATION}/api/v2/email-templates/${id}`,
		body
	).then(res => res.data);
}
export async function deleteEmailTemplate(id: number): Promise<null> {
	return await axios.delete<null>(
		`${URL_FOUNDATION}/api/v2/email-templates/${id}`,
	).then(res => res.data);
}