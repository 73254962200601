import { Chart } from 'bindings/reporting/Chart';
import { Curve } from 'bindings/reporting/Curve';
import { Link } from 'bindings/reporting/Link';
import { Point } from 'bindings/reporting/Point';
import { SecondaryAxis } from 'bindings/reporting/SecondaryAxis';
import { GenericReport } from 'bindings/reports/generic/GenericReport';
import optionGrey from 'images/icon/options_grey.png';
import arrow from 'images/icons/orders/arrow_left.svg';
import reports_black from 'images/menu_icon/reports_black.svg';
import VisibilityOffIcon from 'images/reports/visibility_off.svg';
import VisibilityOnIcon from 'images/reports/visibility_on.svg';
import VisibilityOnEmptyIcon from 'images/reports/visibility_on_empty.svg';
import setting from 'images/setting_icons/system_setting_icon.svg';
import listView_black from 'images/ui_icon/listView_black.svg';
import * as moment from 'moment';
import * as React from 'react';
import { Translate, getTranslate } from 'react-localize-redux';
import { PieChart } from 'react-minimal-pie-chart';
import { useRecoilState, useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { AAdditionalColumns, ReportColumnType } from '../../../atoms/additionalColumns';
import { ATagFilter } from '../../../atoms/filter/tagsFilterAtom';
import { AUsers, isOwner, useMe } from '../../../atoms/global/users';
import { AProducts, Product } from '../../../atoms/product';
import { PanelSelector } from '../../../components_v2/Selector/PanelSelector';
import { Image } from '../../../components_v2/avatar/style/Style';
import Dropdown from '../../../components_v2/dropdown/Dropdown';
import { DropdownData } from '../../../components_v2/dropdown/model/Model';
import { OptionBlock } from '../../../components_v2/dropdown/style/Style';
import AdvancedFilters from '../../../components_v2/filter/AdvancedFilters';
import advancedFiltersInterpretor from '../../../components_v2/filter/AdvancedFiltersInterpretor';
import { FilterResult, FilterTree, filterTreeLength } from '../../../components_v2/filter/model/Model';
import { FilterParameter, FilterParameterParent } from '../../../components_v2/filter/pages/FilterList';
import { tooltipPos } from '../../../components_v2/models';
import { InfiniteTable, InfiniteTableStyle } from '../../../components_v2/table/InfiniteTable';
import { Column } from '../../../components_v2/table/Table';
import ToolbarFilterButton from '../../../components_v2/toolbarFilter/ToolbarButton';
import { ColorAndT, createRandomColor, isSuperAdmin } from '../../../components_v2/utils';
import storeLang from '../../../helpers/storeLang';
import { Dot } from '../../../styles/global/css/Dot';
import { DefaultImage } from '../../../styles/global/css/GlobalImage';
import { DefaultText } from '../../../styles/global/css/GlobalText';
import { BlueSidely, BorderColor, DarkGreySidely2, FilterBlue, FilterGreen, FilterRed, GreenSidely, GreySidely, RedSidely, SidelyBlack } from '../../../styles/global/css/Utils';
import { translateToString } from '../../../styles/global/translate';
import { useFunctionState } from '../../../utils/customHooks';
import { AlertRes } from '../../alert/AlertProvider';
import useAlert from '../../alert/UseAlert';
import { LoadingStateEnum } from '../../import/model';
import { ComponentLoader } from '../../map/modalRight/ModalCalendar';
import { FlexDiv } from '../../products/style';
import { goalsProps, LocalFilters, ReportFiltersContext } from '../generic/generic';
import { getColorForPercentage, getStringWidth } from '../utils';
import { getColorFromList as stringToColour } from '../../../components_v2/avatar/Data/ColorList';
import ChartReportingV2 from './ChartInterpretor';
import {
	AxisRepresentation,
	Cell,
	ComplexCell,
	EvolutionTuple,
	Header,
	Panel,
	Reference,
	Report,
	Date as ReportingDate,
	Row,
	TooltipTemplate,
	Value,
} from './bareReportingDecoder';
import ReportInterpretorContext, { EvolutionState, ReportInterpretorProvider, ReportInterpretorProviderProps, ReportInterpretorContextType } from './hoverContext';
import Input from '../../../components_v2/input/Input';
import { ACompanies } from '../../../atoms/clientCompany';
import NoData from '../../noData/NoData';
import { ACampaigns, Campaign } from '../../../atoms/campaignAtom';
import { Owner } from '../../orders/model/Model';
import { BodyHeaderStatus } from '../../client-companies/style/PopupStyle';
import { AStatuses, Status } from '../../../atoms/clientStatusesAtom';
import { Goal, GoalPath, postGoals } from '../generic/action';
import _ from 'lodash';
import { ADatePicker } from '../../../atoms/filter/timeFilterAtom';
import DashboardImg from 'images/menu_icon/dashboard_black.svg';

const MAX_SELECTED = 10;

const EvolutionOperatorContainer = styled.div`
	${DefaultText};
	display: flex;
	align-items: center;
	border-bottom: 1px solid ${DarkGreySidely2};
	height: 100%;
	gap: 5px;
	margin: 0 5px;
	&:after {
		// content: '';
		margin-left: -1px;
		display: inline-block;
		height: 0;
		width: 0;
		border-right: 4px solid transparent;
		border-top: 4px solid ${DarkGreySidely2};
		border-left: 4px solid transparent;
	}
`;

const PercentageDiv = styled(FlexDiv) <{ color: string, width?: string, minHeight?: string, goal?: boolean, borderColor: string }>`
	background-color: ${p => p.color};
	border-radius: 25px;
	color: ${SidelyBlack};
	text-align: center;
	width: ${({ width }) => width ?? '80px'};
	justify-content: center;
	min-height: ${({ minHeight }) => minHeight ?? '21px'};
	font-weight: ${p => p.goal ? '600' : '400'};
	border: ${p => p.goal ? `2px solid ${p.borderColor}` : `2px solid ${p.color}`};
`;

const FlatEvolutionDiv = styled.p<{ value: number }>`
	color: ${({ value }) => value === 0 ? GreySidely : value > 0 ? GreenSidely : RedSidely};
	white-space: nowrap;
	margin: 0;
	font-size: 11px;
	&:before {
		${({ value }) => value === 0 ? 'content: \'=\';' : value > 0 ? 'content: \'+\'' : ''}

	}
`;

const EvolutionDiv = styled.p<{ value: number, kind?: 'small', background?: boolean, fontSize?: string }>`
	white-space: nowrap;
	color: ${({ value }) => value === 0 ? GreySidely : value > 0 ? GreenSidely : RedSidely};
	margin: 0;
	${({ kind, fontSize }) => {
		if (fontSize) return `font-size: ${fontSize};`;
		switch (kind) {
			case 'small': return 'font-size: 10px;';
			default: return 'font-size: 11px;';
		}
	}}
	${({ background, value }) => background ? `
		padding: 0.5em 1em;
		background-color: ${value === 0 ? GreySidely : value > 0 ? GreenSidely : RedSidely}30;
		border-radius: 5px;
	` : ''}
	&:before {
		${({ value }) => value === 0 ? 'content: \'=\';' : `
			content: '';
			background: url('${arrow}');
		`}
	${({ kind }) => {
		switch (kind) {
			case 'small': return `
					width: 10px;
					height: 7px;
					font-size: 10px;
				`;
			default: return `
					width: 13px;
					height: 8px;
					font-size: 13px;
				`;
		}
	}}
		margin-right: -1px;
		display: inline-block;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
	${({ value }) => {
		if (value === 0) return '';
		return `
				rotate: ${value > 0 ? '' : '-'}90deg;
				filter: ${value > 0 ? FilterGreen : FilterRed};
			`;
	}}
	}
`;

// --------------[VALUE]------------- //
function capitalizeFirstLetter(s: string) {
	return s.charAt(0).toUpperCase() + s.slice(1);
}

export function reportingDateToString(value: ReportingDate): string {
	moment.locale();
	const translate = getTranslate(storeLang.getState().localize);
	if ('day' in value.val) {
		return moment(value.val.day).format('L');
	}
	if ('week' in value.val) {
		const eow = moment(value.val.week).endOf('week');
		return `${translate('symbol_week')}${moment(value.val.week).format('w: Do')} - ${eow.format('Do MMM YY')}`;
	}
	if ('month' in value.val) {
		return capitalizeFirstLetter(moment(value.val.month).format('MMMM YYYY'));
	}
	if ('quarter' in value.val) {
		return `${translate('symbol_quarter')}${moment(value.val.quarter).format('Q YYYY')}`;
	}
	if ('fourMonths' in value.val) {
		const momentDate = moment(value.val.fourMonths);
		const endOf4Months = moment(momentDate);
		endOf4Months.set('month', 3 + momentDate.month());

		return `${momentDate.format('MMM')}-${endOf4Months.format('MMM YYYY')}`;
	}
	if ('year' in value.val) {
		return moment(value.val.year).format('YYYY');
	}
	return '';
}

function styleValue(value: Value): { justify?: 'center' | 'left', className?: string } {
	if (typeof value === 'string' || !value || !value.val) {
		return { justify: 'left' };
	}
	if ('percentage' in value.val) {
		return { justify: 'center' };
	}
	if ('text' in value.val || 'key' in value.val || 'formattedKey' in value.val) {
		return {
			justify: 'left',
			className: 'report-text'
		};
	}
	if ('float' in value.val) {
		return { justify: 'center' };
	}
	if ('int' in value.val || 'average' in value.val) {
		return { justify: 'center' };
	}
	if ('colorDot' in value.val) {
		return { justify: 'center' };
	}
	if ('pieChart' in value.val) {
		return { justify: 'center' };
	}
	if ('date' in value.val) {
		return { justify: 'center' };
	}
	if ('list' in value.val) {
		return { justify: 'center', className: 'tamer' };
	}
	if ('evolution' in value.val) {
		return { justify: 'center' };
	}
	if ('user' in value.val) {
		return {
			justify: 'left',
			className: 'report-text'
		};
	}
	return {};
}

export function Percentage(props: { percentage: number, width?: string, fontSize?: string, minHeight?: string, goal?: boolean }) {
	const { percentage: value } = props;
	return <PercentageDiv width={props.width} fontSize={props.fontSize} color={getColorForPercentage(value, props.goal ? 0.6 : undefined, undefined, true)} borderColor={getColorForPercentage(value, undefined, undefined, true)} minHeight={props.minHeight} goal={props.goal}>{(value * 100).toFixed(2)}%</PercentageDiv>;
}

function EvolutionValueDisplayer(props: { value: Extract<ReportColumnType, { 'evolution' }>, columnIndex?: number }) {
	const { evolutionState } = React.useContext(ReportInterpretorContext);
	const state = evolutionState[props.columnIndex ?? -1] ?? '%';
	// return <div><ValueToNode value={props.value.evolution.old}/> {'->'} <ValueToNode value={props.value.evolution.new}/> </div>
	const res = calcEvolution(props.value.evolution);
	if (!res) return <>-</>;
	if (state === '+') return <FlatEvolutionDiv value={res?.flat}>{res?.flat.toFixed(2)}</FlatEvolutionDiv>;
	if (isNaN(res.percentage)) {
		if (res.flat === 0) return <>-</>;
		return <Evolution percentage={Infinity * res.flat} />;
	}
	return <Evolution percentage={res.percentage} />;

}

export function Evolution(props: { percentage: number, kind?: 'small', background?: boolean, fixed?: number, fontSize?: string }) {
	const { percentage, fixed } = props;
	const tamer = isNaN(percentage) ? 0 : percentage;
	return <EvolutionDiv value={tamer} kind={props.kind} background={props.background} fontSize={props.fontSize}>{isFinite(tamer) ? `${(tamer * 100).toFixed(fixed ?? 2)}%` : '%'}</EvolutionDiv>;
}

export function calcEvolution(evolution: EvolutionTuple): { percentage: number, flat: number } | undefined {
	if (evolution.old.tag !== evolution.new.tag) return undefined;
	if (evolution.old.val && 'percentage' in evolution.old.val && evolution.new.val && 'percentage' in evolution.new.val) {
		return { percentage: fractionToDecimal(evolution.new.val.percentage) - fractionToDecimal(evolution.old.val.percentage), flat: fractionToDecimal(evolution.new.val.percentage) - fractionToDecimal(evolution.old.val.percentage) };
	}
	const oldNumber = valueToNumber(evolution.old) ?? 0;
	const newNumber = valueToNumber(evolution.new) ?? 0;
	return { percentage: (newNumber - oldNumber) / oldNumber, flat: newNumber - oldNumber };
}

export function ValueInnerToNode(props: { value: ReportColumnType, emptyTile?: boolean, columnIndex?: number, primary?: boolean, goal?: boolean }): JSX.Element {
	const { value } = props;
	const owners = useRecoilValue(AUsers);
	const products = useRecoilValue(AProducts);
	const companies = useRecoilValue(ACompanies);
	const campaigns = useRecoilValue(ACampaigns);
	const statuses = useRecoilValue(AStatuses);
	const ctx = React.useContext(ReportInterpretorContext);

	if (typeof value === 'string' || !value) {
		return props.emptyTile ? <></> : <>-</>;
	}
	if ('percentage' in value) {
		return <Percentage percentage={fractionToDecimal(value.percentage)} goal={props.goal}/>;
	}
	if ('text' in value) {
		return <>{value.text}</>;
	}
	if ('key' in value) {
		return <Translate id={value.key} />;
	}
	if ('formattedKey' in value) {
		const translate = getTranslate(storeLang.getState().localize);
		let res = translate(value.formattedKey.key).toString();
		value.formattedKey.formats.forEach((replacementKey, i) => res = res?.replaceAll(`{{${i + 1}}}`, replacementKey));
		return <>{res}</>;
	}
	if ('float' in value) {
		return <>{value.float.toFixed(2)}</>;
	}
	if ('int' in value) {
		return <>{value.int.toString()}</>;
	}
	if ('colorDot' in value) {
		return <Dot color={value.colorDot} size='12px' />;
	}
	/*
		Note to future devs:
		Select fields become piecharts and multi-select fields become lists.
		Piechart and list used to behave differently.
		Now they behave the same, but are serialized in opposite order (piechart is count then name, list is name then count).
		So they still have different code paths and are not merged in the same groups.
		Eventually, pieChart should be removed and everything should become a list.
	*/
	if ('pieChart' in value) {
		if (props.primary) {
			return <CompanyStyle>{value.pieChart.map(d => d.name).join(', ')}</CompanyStyle>;
		}
		const total = value.pieChart.reduce((acc, data) => acc + data.count, 0);
		return <PieChart
			className='circle'
			lineWidth={70}
			center={[50, 50]}
			viewBoxSize={[100, 100]}
			data={value.pieChart.map(data => ({ value: data.count / total, color: stringToColour(data.name) }))}
		/>;
	}
	if ('list' in value) {
		if (props.primary) {
			return <CompanyStyle>{value.list.map(d => d.value).join(', ')}</CompanyStyle>;
		}
		const total = value.list.reduce((acc, data) => acc + data.count, 0);
		return <PieChart
			className='circle'
			lineWidth={70}
			center={[50, 50]}
			viewBoxSize={[100, 100]}
			data={value.list.map(data => ({ value: data.count / total, color: stringToColour(data.value) }))}
		/>;
	}
	if ('date' in value) {
		return <>{reportingDateToString(value.date)}</>;
	}
	if ('datetime' in value) {
		return <>{moment.unix(value.datetime).format('L - LT')}</>;
	}
	if ('user' in value) {
		const id = value.user;
		const name = ctx?.mapValues?.users?.[id] ?? owners.find(o => o.id === id)?.name;
		if (name) return <>{name}</>;
		return <></>;
	}
	if ('evolution' in value) {
		return <EvolutionValueDisplayer value={value} columnIndex={props.columnIndex} />;
	}
	if ('product' in value) {
		const uuid = value.product;
		const name = ctx?.mapValues?.products?.[uuid] ?? products.find(p => p.uuid === uuid)?.name;
		if (name) return <CompanyStyle>{name}</CompanyStyle>;
	}
	if ('company' in value) {
		const name = ctx?.mapValues?.companies?.[value.company] ?? companies[value.company];
		return <CompanyStyle>{name}</CompanyStyle>;
	}
	if ('average' in value) {
		return <>{(value.average.numerator / value.average.denominator).toFixed(2)}</>;
	}
	if ('event' in value) {
		return <CompanyStyle>{value.event.title}</CompanyStyle>;
	}
	if ('contact' in value) {
		return <CompanyStyle>{value.contact}</CompanyStyle>;
	}
	if ('campaign' in value) {
		const name = ctx?.mapValues?.campaigns?.[value.campaign] ?? campaigns[value.campaign]?.name;
		return <CompanyStyle>{name}</CompanyStyle>;
	}
	if ('status' in value) {
		const status = statuses[value.status];
		return <>
			<FlexDiv gap="5px">
				<BodyHeaderStatus backgroundColor={status.status_color ?? ''}>
					{status.status_name ?? <NoValueDiv><Translate id='reports.no_value' /></NoValueDiv>}
				</BodyHeaderStatus>
			</FlexDiv>
		</>;
	}
	return <></>;

}

export function ValueToNode(props: { value: Omit<Value, 'tag'>, emptyTile?: boolean, columnIndex?: number, primary?: boolean, tag?: Pick<Value, 'tag'>['tag'], goal?: boolean }): JSX.Element {
	const { value } = props;
	if (props.tag === 16) {
		return <NoValueDiv>
			<Translate id='reports.no_value' />
		</NoValueDiv>;
	}
	if (typeof value === 'string' || !value || !value.val) {
		return props.emptyTile || props.primary ? <></> : <>-</>;
	}
	return <ValueInnerToNode value={value.val} emptyTile={props.emptyTile} columnIndex={props.columnIndex} primary={props.primary} goal={props.goal}/>;
}

const CompanyStyle = styled.div`
	overflow: hidden;
	text-overflow: ellipsis;
	text-wrap: nowrap;
	text-align: left;
	width: 100%;
`;

const NoValueDiv = styled.div`
	font-weight: 200;
	font-style: italic;
`;

// White pill background for text element white black outline
const MultiSelectDiv = styled.div`
	background-color: white;
	border-radius: 25px;
	border: 0.8px solid ${BorderColor};
	color: ${SidelyBlack};
	text-align: center;
	padding: 0.1em 0.5em;
	margin: 0.1em;
`;

function MultiSelectElement(props: { value: string }) {
	return <MultiSelectDiv>
		{props.value}
	</MultiSelectDiv>;
}

type ReactTableRow = {
	cells: readonly ComplexCell[],
	subRows: ReactTableRow[],
	primaryCell: readonly Cell[],
	id: number,
	path?: GoalPath,
	history?: readonly Cell[],
	parent?: ReactTableRow,
}

function filterEmptyRow(row: ReactTableRow, tree: FilterTree | undefined): ReactTableRow | undefined {
	if (!tree) return row;
	const newRow = { ...row };
	newRow.subRows = row.subRows.reduce((acc: ReactTableRow[], r) => {
		const res = filterEmptyRow(r, tree);
		if (res) acc.push(res);
		return acc;
	}, []);
	if (newRow.subRows.length > 0) return newRow;
	const filterApproved = advancedFiltersInterpretor(newRow.cells, tree, {
		empty(value, columnId) {
			if (typeof columnId == 'string') {
				const indexedValue = value[Number(columnId)];
				return indexedValue.value.tag === EmptyCell.tag || indexedValue.value.val === undefined;
			}
			return true;
		},
		not_empty(value, columnId) {
			if (typeof columnId == 'string') {
				const indexedValue = value[Number(columnId)];
				return indexedValue.value.tag !== EmptyCell.tag && indexedValue.value.val !== undefined;
			}
			return false;
		},
	});
	return filterApproved ? newRow : undefined;
}

function toReactTableData(row: Row, reportHeadersNoDate?: Header[], history?: Cell[], parent?: ReactTableRow): ReactTableRow {
	const currentRow: ReactTableRow = {
		id: row.id,
		cells: row.cells,
		primaryCell: row.primaryCell,
		path: reportHeadersNoDate ? (history ?? row.primaryCell ?? []).reduce((acc, current: Cell, i: number) => {
			if (!current)
				return acc;
			const path = current.value.val;
			const objectTitle = Object.values(reportHeadersNoDate[i].cell.value.val ?? {})[0];
			if (path) {
				acc[objectTitle] = {
					'value': path,
					'index': i 
				};
			}
			return acc;
		}, {}) : undefined,
		subRows: [],
		history,
		parent
	};
	currentRow.subRows = row.rows.map(subRow => toReactTableData(subRow, reportHeadersNoDate, history ? [...history, subRow.primaryCell[subRow.primaryCell.length - 1]] : [row.primaryCell[row.primaryCell.length - 1], subRow.primaryCell[subRow.primaryCell.length - 1]], currentRow));
	return currentRow;
}

export function valueInnerToString(value: ReportColumnType, users?: Owner[] | undefined, products?: Product[] | undefined, companies?: Record<number, string> | undefined, campaigns?: Record<number, Campaign> | undefined): string | undefined {
	if (typeof value === 'string' || !value) {
		return '';
	} else if ('text' in value) {
		return value.text;
	} else if ('key' in value) {
		return translateToString(value.key);
	} else if ('formattedKey' in value) {
		let res = translateToString(value.formattedKey.key);
		value.formattedKey.formats.forEach((replacementKey, i) => res = res?.replaceAll(`{{${i + 1}}}`, replacementKey));
		return res;
	} else if ('int' in value) {
		return value.int.toString();
	} else if ('float' in value) {
		return value.float.toFixed(2);
	} else if ('date' in value) {
		return reportingDateToString(value.date);
	} else if ('evolution' in value) {
		return value.evolution.toString();
	} else if ('company' in value) {
		return companies?.[value.company] ?? `Company #${value.company}`;
	} else if ('list' in value) {
		return value.list.map(e => e.value).join(', ');
	} else if ('user' in value) {
		return users?.find(u => u.id === value.user)?.name ?? `User #${value.user}`;
	} else if ('datetime' in value) {
		return moment.unix(value.datetime).format('L - LT');
	} else if ('product' in value) {
		return products?.find(p => p.uuid === value.product)?.name ?? `Product ${value.product}`;
	} else if ('colorDot' in value) {
		return value.colorDot;
	} else if ('average' in value) {
		return (value.average.numerator / value.average.denominator).toFixed(2);
	} else if ('event' in value) {
		return value.event.title;
	} else if ('contact' in value) {
		return `Contact #${value.contact}`;
	} else if ('campaign' in value) {
		return campaigns?.[value.campaign].name ?? `Campaign #${value.campaign}`;
	} else {
		console.log('unimplemented value :', value);
	}
}

export function valueToString(value: Value, users?: Owner[], products?: Product[], companies?: Record<number, string>, campaigns?: Record<number, Campaign>): string | undefined {
	if (typeof value === 'string' || !value || !value.val) {
		return '';
	}
	return valueInnerToString(value.val, users, products, companies, campaigns);
}

// --------------[CELLS]------------- //

export const EmptyCell: Value = { tag: 15, val: null };

const LinkDiv = styled.div`
	cursor: pointer;
	width: 100%;
`;

const ToolTipTd = styled.td<{ noBorder?: boolean }>`
	vertical-align: middle;
				text-align: center;
	${p => p.noBorder ? 'border: none !important;' : ''}
`;

export function onLinkClick(link: Link | null, links: ReportInterpretorProviderProps): void {
	if (!link) return;
	if ('clientCompany' in link) {
		const value = link.clientCompany;
		links.onCompanyClick(value);
	} else if ('contact' in link) {
		const value = link.contact;
		window.open(`contacts/detail/${value}`);
	} else if ('order' in link) {
		const value = link.order;
		window.open(`orders?id=${value}`);
	} else if ('product' in link) {
		const value = link.product;
		window.open(`products-v2?id=${value}`);
	} else if ('assortment' in link) {
		const value = link.assortment;
		window.open(`assortments-v2?id=${value}`);
	} else if ('shelfAudit' in link) {
		const value = link.shelfAudit;
		window.open(`enform/detaildata/${value}`);
	} else if ('user' in link) {
		console.log('TODO');
	} else if ('formInstance' in link) {
		links.onFormInstanceClick(link.formInstance);
	} else {
		console.log('unimplemented link :', link);
	}
}

function ReportLink(props: { link: Link | null, value: React.ReactNode }): JSX.Element {
	const { link, value } = props;
	const { onCompanyClick, onFormInstanceClick } = React.useContext(ReportInterpretorContext);

	return <LinkDiv onClick={() => onLinkClick(link, { onCompanyClick, onFormInstanceClick })}>{value}</LinkDiv>;
}

function referenceToNode(reference: Reference, primaryCell: Cell | undefined, cell: ComplexCell | Cell): React.ReactNode {
	if (reference.val && 'values' in cell && 'complexValue' in reference.val) {
		// ComplexValue
		return <ValueToNode value={cell.values[reference.val.complexValue]} />;
	} else if (reference.tag === 0 && primaryCell) {
		// PrimaryCell
		return <ValueToNode value={primaryCell.value} />;
	} else if (reference.tag === 1) {
		// PrimaryValue
		return <ValueToNode value={cell.value} />;
	} else if (reference.val && 'primaryValueTupple' in reference.val && cell.value.val && 'evolution' in cell.value.val) {
		switch (reference.val.primaryValueTupple) {
			case 0: return <ValueToNode value={cell.value.val.evolution.old} />;
			case 1: return <ValueToNode value={cell.value.val.evolution.new} />;
		}
	}
	return undefined;
}

function CellToNode(props: { cell: ComplexCell | Cell | undefined, isEmptyTile?: boolean, columnIndex?: number, color?: string, primary?: boolean, fontSize?: string, goal?: boolean }): JSX.Element {
	const { isEmptyTile, columnIndex } = props;
	const cell = props.cell ?? { value: EmptyCell, link: null, tooltip: null };
	const { justify, className } = styleValue(cell.value);
	const value: React.ReactNode = <div style={{ width: '100%', display: justify === 'center' ? 'flex' : 'block', justifyContent: justify }} className={className}>{<ValueToNode value={cell.value} emptyTile={isEmptyTile} columnIndex={columnIndex} primary={props.primary} tag={cell.value.tag} goal={props.goal} />}</div>;
	let link: Link | null | undefined = cell.link?.val;

	if (cell.value.val) {
		if ('user' in cell.value.val) {
			link = { user: cell.value.val.user };
		}
		else if ('company' in cell.value.val) {
			link = { clientCompany: cell.value.val.company };
		}
		else if ('contact' in cell.value.val) {
			link = { contact: cell.value.val.contact };
		}
	}

	if (!link) {
		return <FlexDiv height='100%' justify={justify} align='center' width='100%' fontSize={props.fontSize}
			color={props.color}>{value}</FlexDiv>;
	}

	return <FlexDiv height='100%' justify={justify} align='center' width='100%'>
		<ReportLink link={link} value={value} />
	</FlexDiv>;
}

// --------------[TABLE]------------- //

const sort = (primaryLength: number, index: number, evolutionStateRef: React.MutableRefObject<EvolutionState>, users: Owner[], products: Product[], companies: Record<number, string>, campaigns: Record<number, Campaign>, statuses: Record<number, Status>, ctx: ReportInterpretorContextType) => (rowA: { original: ReactTableRow }, rowB: { original: ReactTableRow }, _columnId, desc: boolean) => {
	const translate = getTranslate(storeLang.getState().localize);
	let ra: Value;
	let rb: Value;
	if (index >= primaryLength) {
		ra = rowA.original.cells[index - primaryLength]?.value ?? EmptyCell;
		rb = rowB.original.cells[index - primaryLength]?.value ?? EmptyCell;
	} else {
		ra = rowA.original.primaryCell[index]?.value ?? EmptyCell;
		rb = rowB.original.primaryCell[index]?.value ?? EmptyCell;
	}

	if ((typeof ra === 'string' && typeof rb === 'string') || ((!ra || !ra.val) && (!rb || !rb.val))) return 0;
	if (typeof ra === 'string' || !ra || !ra.val) return desc ? -1 : 1;
	if (typeof rb === 'string' || !rb || !rb.val) return desc ? 1 : -1;

	let fra: number | undefined = undefined;
	let frb: number | undefined = undefined;
	if ('percentage' in ra.val) {
		fra = fractionToPercentage(ra.val.percentage);
	} else if ('float' in ra.val) {
		fra = ra.val.float;
	} else if ('int' in ra.val) {
		fra = Number(ra.val.int);
	} else if ('evolution' in ra.val) {
		if (evolutionStateRef.current[index] === '+') fra = calcEvolution(ra.val.evolution)?.flat;
		else {
			const evo = calcEvolution(ra.val.evolution);
			if (evo === undefined) fra = undefined;
			else {
				if (isNaN(evo.percentage)) {
					if (evo.flat === 0) fra = undefined;
					else fra = Infinity * evo.flat;
				} else fra = evo.percentage;
			}
		}
	}

	if ('percentage' in rb.val) {
		frb = fractionToPercentage(rb.val.percentage);
	} else if ('float' in rb.val) {
		frb = rb.val.float;
	} else if ('int' in rb.val) {
		frb = Number(rb.val.int);
	} else if ('evolution' in rb.val) {
		if (evolutionStateRef.current[index] === '+') frb = calcEvolution(rb.val.evolution)?.flat;
		else {
			const evo = calcEvolution(rb.val.evolution);
			if (evo === undefined) frb = undefined;
			else {
				if (isNaN(evo.percentage)) {
					if (evo.flat === 0) frb = undefined;
					else frb = Infinity * evo.flat;
				} else frb = evo.percentage;
			}
		}
	}
	if (fra && (!isFinite(fra) || isNaN(fra))) return desc ? 1 : -1;
	if (frb && (!isFinite(frb) || isNaN(frb))) return desc ? -1 : 1;

	if (fra !== undefined && frb !== undefined) return fra - frb;
	if (fra === undefined && frb !== undefined) return desc ? -1 : 1;
	if (frb === undefined && fra !== undefined) return desc ? 1 : -1;

	if ('colorDot' in ra.val && 'colorDot' in rb.val) return ra.val.colorDot.localeCompare(rb.val.colorDot);
	let tra: string | undefined = undefined;
	let trb: string | undefined = undefined;
	if ('text' in ra.val) {
		tra = ra.val.text;
	} else if ('key' in ra.val) {
		tra = translate(ra.val.key).toString();
	} else if ('formattedKey' in ra.val) {
		trb = translate(ra.val.formattedKey.key).toString();
		ra.val.formattedKey.formats.forEach((replacementKey, i) => trb = trb?.replaceAll(`{{${i + 1}}}`, replacementKey));
	} else if ('user' in ra.val) {
		const id = ra.val.user;
		tra = ctx?.mapValues?.users?.[id] ?? users.find(o => o.id === id)?.name;
	} else if ('product' in ra.val) {
		const uuid = ra.val.product;
		tra = ctx?.mapValues?.products?.[uuid] ?? products.find(p => p.uuid === uuid)?.name;
	} else if ('company' in ra.val) {
		tra = ctx?.mapValues?.companies?.[ra.val.company] ?? companies[ra.val.company];
	} else if ('campaign' in ra.val) {
		tra = ctx?.mapValues?.campaigns?.[ra.val.campaign] ?? campaigns[ra.val.campaign]?.name;
	} else if ('status' in ra.val) {
		const status = statuses[ra.val.status];
		if (status.status_name) { tra = status.status_name; }
	}

	if ('text' in rb.val) {
		trb = rb.val.text;
	} else if ('key' in rb.val) {
		trb = translate(rb.val.key).toString();
	} else if ('formattedKey' in rb.val) {
		trb = translate(rb.val.formattedKey.key).toString();
		rb.val.formattedKey.formats.forEach((replacementKey, i) => trb = trb?.replaceAll(`{{${i + 1}}}`, replacementKey));
	} else if ('user' in rb.val) {
		const idb = rb.val.user;
		trb = ctx?.mapValues?.users?.[idb] ?? users.find(o => o.id === idb)?.name;
	} else if ('product' in rb.val) {
		const uuid = rb.val.product;
		trb = ctx?.mapValues?.products?.[uuid] ?? products.find(p => p.uuid === uuid)?.name;
	} else if ('company' in rb.val) {
		trb = ctx?.mapValues?.companies?.[rb.val.company] ?? companies[rb.val.company];
	} else if ('campaign' in rb.val) {
		trb = ctx?.mapValues?.campaigns?.[rb.val.campaign] ?? campaigns[rb.val.campaign]?.name;
	} else if ('status' in rb.val) {
		const status = statuses[rb.val.status];
		if (status.status_name) { trb = status.status_name; }
	}

	if (tra === undefined && trb !== undefined) return desc ? -1 : 1;
	if (trb === undefined && tra !== undefined) return desc ? 1 : -1;

	if (tra !== undefined && trb !== undefined) return tra.localeCompare(trb);
	
	return JSON.stringify(ra).localeCompare(JSON.stringify(rb));
};

const defaultFilters: FilterResult = {
	values: {
		array: [],
		combinator: 'and'
	},
	formatted: undefined
};

function RenderTableCell(props: {
	data: ReactTableRow | ComplexCell,
	header: Header,
	toolTipTemplate: TooltipTemplate | null,
	index: number,
	primaryLength: number,
	emptyTile?: boolean
}) {
	let element: React.ReactNode = <></>;
	if (!('primaryCell' in props.data)) {
		element = <CellToNode cell={props.data} isEmptyTile={props.emptyTile} columnIndex={props.index} />;
	} else if (props.index < props.primaryLength) {
		element = <CellToNode cell={props.data.primaryCell[props.index]} isEmptyTile={props.emptyTile} columnIndex={props.index} primary />;
	} else {
		element = props.data.cells?.[props.index - props.primaryLength] ? <CellToNode cell={props.data.cells[props.index - props.primaryLength]} isEmptyTile={props.emptyTile} columnIndex={props.index} /> : undefined;
	}
	return <div style={{ height: '100%', cursor: 'pointer' }}
	>
		{element}
	</div>;
}

export type ToolTipHover = {
	cell: Cell | ComplexCell,
	bounds: DOMRect,
	toolTipTemplate: TooltipTemplate | null,
	primaryCell?: Cell,
	pos: tooltipPos
};

export type ReportInterpretorProps = {
	setToolBarState?: (a) => void,
	report: Report,
	reportId: number,
	height: number,
	filterParams?: FilterParameter[] | FilterParameterParent[],
	visualizationFitlerParams?: FilterParameter[] | FilterParameterParent[],
	filterResult?: FilterResult,
	onFiltersChange?: (filters: FilterResult) => void,
	reprasentation?: GenericReport,
	onStoreColumn?: (columnIndex: number, panelIndex: number, columnName: string) => void,
	onUnstoreColumn?: (columnId: number) => void,
	onCreateCalcField?: (columnIndex: number, panelIndex: number, columnName: string) => void,
	externalActiveFilter?: number,
	context?: boolean,
	goalsProps?: goalsProps
};

export default function ReportInterpretor(props: ReportInterpretorProps & ReportInterpretorProviderProps) {
	return <ReportInterpretorProvider {...props}>
		<ReportInterpretorNoProvider {...props} />
	</ReportInterpretorProvider>;
}

const OnHoverInputContainer = styled.div<{isFocused: boolean, left?: string, top?: string, width?: string}>`
	position: relative;
	z-index: 1;
	top: ${p => p.top ?? '-20px'};
	left: ${p => p.left ?? '103px'};
	background-color: white;
	width: ${p => p.width ?? '180px'};
	display: ${p => p.isFocused ? 'flex' : 'none'};
	flex-direction: column;
	text-align: left;
	border-radius: 5px;
`;

const InlineButton = styled.div`
	min-height: 25px;
	border-bottom: 1px solid ${BorderColor};
	padding-left: 8px;
	cursor: pointer;
	&:hover {
		background-color: rgba(0, 178, 255, 0.2);
	}
`;

const StyledInput = styled.input<{ width?: string, height?: string }>`
	width: ${p => p.width ?? '80px'};
	height: ${p => p.height ?? ''};
	border: solid 1px ${BorderColor};
	border-radius: 5px;
	text-align: center;
	padding-left: 15px;
`;

const GoalInputCell = ({ onBlur, onSetAllColumn, onSetAllColumnCustom, value, isInputDisabled }: { onBlur: (value: string) => void, onSetAllColumn: (value: string) => void, onSetAllColumnCustom: (value: string) => void, value: string, isInputDisabled: boolean}) => {
	const [inputValue, setInputValue] = React.useState(value);
	const [isFocused, setIsFocused] = React.useState(false);
	return <div style={{ width: '100%', height: '100%' }} onFocus={() => setIsFocused(true)} onBlur={() => setIsFocused(false)}>
		<FlexDiv justify='center' width='100%' height='100%'>
			<StyledInput
				type="number"
				disabled={isInputDisabled}
				defaultValue={value}
				onChange={(e) => setInputValue(e.target.value)}
				onBlur={() => onBlur(inputValue)}
				onKeyDown={(e) => {
					if (e.key === 'Enter' || e.key === 'Tab' || e.key === 'Escape') {
						(e.target as HTMLInputElement).blur();
					}
				}}
			/>
		</FlexDiv>
		<OnHoverInputContainer isFocused={isFocused && !isInputDisabled}>
			<InlineButton onMouseDown={() => onSetAllColumn(inputValue)}>
				<Translate id='report.apply_to_column'/>
			</InlineButton>
			<InlineButton onMouseDown={() => onSetAllColumnCustom(inputValue)}>
				<Translate id='report.apply_last_period'/>
			</InlineButton>
		</OnHoverInputContainer>
	</div>;
};

const DashboardCheckbox = ({ isCheckboxDisabled, defaultChecked, onChange, onCheckAll }: { isCheckboxDisabled: boolean, defaultChecked: boolean, onChange: (e: React.ChangeEvent<HTMLInputElement>) => void, onCheckAll: () => void }) => {
	const [isHovered, setIsHovered] = React.useState(false);

	return <div onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
		<FlexDiv justify='center' >
			<input type='checkbox'
				style={{ backgroundColor: isCheckboxDisabled ? BorderColor : 'white', cursor: isCheckboxDisabled ? 'not-allowed' : 'pointer' }}
				defaultChecked={defaultChecked}
				title={isCheckboxDisabled ? 'Enter a goal to enable adding goals to the dashboard' : ''}
				disabled={isCheckboxDisabled}
				onChange={onChange}
			/>
		</FlexDiv>
		<OnHoverInputContainer isHovered={isHovered && !isCheckboxDisabled} left='30px' top='-22px' width='110px'>
			<InlineButton onMouseDown={() => onCheckAll()}>
				<Translate id='report.apply_to_column'/>
			</InlineButton>
		</OnHoverInputContainer>
	</div>;
};

function hash(obj: object): string {
	function sortObject(o: object): any {
		if (o === null || typeof o !== 'object') {
			return o;
		}
		if (Array.isArray(o)) {
			return o.map(sortObject);
		}
		const sortedKeys = Object.keys(o).sort();
		const result: object = {};
		sortedKeys.forEach(key => {
			result[key] = sortObject(o[key]);
		});
		return result;
	}
	return JSON.stringify(sortObject(obj));
}

export function ReportInterpretorNoProvider(props: ReportInterpretorProps) {
	const [selectedPanel, setSelectedPanel] = React.useState(0);
	const [selectedHeader, setSelectedHeader] = React.useState<number>();
	const [selectedView, setSelectedView] = React.useState<'table' | 'chart'>('table');
	const [selectedCheckbox, setSelectedCheckbox] = React.useState<boolean[]>([]);
	const [loadingState, setLoadingState] = React.useState<LoadingStateEnum>(LoadingStateEnum.LOADED);
	const { evolutionStateRef, onCompanyClick, onFormInstanceClick } = React.useContext(ReportInterpretorContext);
	const users = useRecoilValue(AUsers);
	const products = useRecoilValue(AProducts);
	const companies = useRecoilValue(ACompanies);
	const campaigns = useRecoilValue(ACampaigns);
	const statuses = useRecoilValue(AStatuses);
	const ctx = React.useContext(ReportInterpretorContext);
	const [hidden, setHidden] = React.useState<boolean>(false);
	const [, setTagsFilter] = useRecoilState(ATagFilter);
	const additionalColumns = useRecoilValue(AAdditionalColumns);
	const isAdmin = isSuperAdmin();
	let meOwner;
	try {
		const me = useMe();
		meOwner = isOwner(me);
	} catch (e) {
		meOwner = false;
	}
	// Filters
	const [isFilterOpen, setFilterOpen] = React.useState<boolean>(false);
	const [isVizualisationFilterOpen, setVizualisationFilterOpen] = React.useState<boolean>(false);
	const [filterResult, setFilterResult] = useFunctionState<FilterResult>(props.filterResult ?? defaultFilters, ({ newValue }) => {
		props.onFiltersChange?.(newValue);
		return newValue;
	});
	const [vizualisationFilterResult, setVizualisationFilterResult] = React.useState<FilterResult>(defaultFilters);
	const [isOpenSummary, setOpenSummary] = React.useState<boolean>(false);
	const [reportGranularity, setReportGranularity] = React.useState<string>('month');
	const { filters, setFilters } = React.useContext(ReportFiltersContext);

	const [nbFilters, setNbFilters] = React.useState<number>(filtersLenght(filters));
	const [goals, setGoals] = React.useState<Goal[]>(props.goalsProps?.goals ?? []);

	const selectedPanelName = props.report.panels.map((panel) => panel.value.val && 'text' in panel.value.val ? panel.value.val.text : '')[selectedPanel];
	const pickedDates = useRecoilState(ADatePicker)[0];

	React.useEffect(() => {
		setNbFilters(filtersLenght(filters));
	}, [filters]);

	React.useEffect(() => {
		setGoals(props.goalsProps?.goals ?? []);
	}, [props.goalsProps?.goals]);

	const goalPathsHashMap = React.useMemo(() => {
		const res = new Map<string, { value: string, index: number, dashboard: boolean }>();
		goals?.forEach((goal, goalIndex) => {
			if (goal.granularity !== reportGranularity || (goal.column_name && selectedPanelName !== goal.column_name)) return;
			goal.goals.forEach(g => {
				let date = moment(g.date);
				switch (goal.granularity) {
					case 'week':
						date = date.startOf('week');
						break;
					case 'month':
						date = date.startOf('month');
						break;
					case 'quarter':
						date = date.startOf('quarter');
						break;
					case 'fourMonths':
						date = moment(`${date.year()}-${date.month()}-01`);
						break;
					case 'year':
						date = date.startOf('year');
						break;
					default:
						break;
				}
				const path = { 'date': date.format('YYYY-MM-DD'), ...goal.path };
				res.set(hash(path), { value: g.value.toString(), index: goalIndex, dashboard: g.dashboard });
			});
		});
		return res;
	}, [reportGranularity, selectedPanel, goals]);

	function filtersLenght(filters: LocalFilters): number {
		let i = 0;
		if (filters) {
			if (filters.form_filter && (filters.form_filter as number[]).length > 0)
				i = i + 1;
			if (filters.created_by_filter && (filters.created_by_filter as number[]).length != users.length)
				i = i + 1;
			if (filters.my_filters) {
				if ((filters.my_filters).products && !(filters.my_filters).products?.all)
					i = i + 1;
				if ((filters.my_filters).brands && !(filters.my_filters).brands?.all)
					i = i + 1;
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				if ((filters.my_filters).tags && (filters.my_filters).tags!.length > 0)
					i = i + 1;
			}
		}
		return i;
	}

	const { report: r, height } = props;
	let report: Panel | undefined = undefined;
	if (r.panels[selectedPanel]) report = r.panels[selectedPanel];
	const alert = useAlert();
	const colors = React.useMemo(() => {
		const table: number[] = [];
		const flattenRowIds = (row: Row) => {
			table.push(row.id);
			row.rows.forEach(flattenRowIds);
		};
		report?.rows.forEach(flattenRowIds);
		return {
			rowColors: createRandomColor(table),
			panelColors: createRandomColor(r.panels)
		};
	}, [report]);

	React.useEffect(() => {
		if (props.filterResult) {
			setFilterResult(props.filterResult);
		}
	}, [props.filterResult]);

	function Visibility(propos: { defaultValue: boolean, onChange: (b: boolean) => void }) {
		const [hidden, setHidden] = React.useState<boolean>(propos.defaultValue);
		let icon = VisibilityOnEmptyIcon;
		if (selectedCheckbox.some(b => b === true)) {
			if (hidden) {
				icon = VisibilityOffIcon;
			}
			else {
				icon = VisibilityOnIcon;
			}
		}
		else {
			if (hidden) {
				setHidden(false);
				alert({
					title: 'Attention',
					content: 'Veuillez sélectionner au moins une entreprise',
					width: '475px',
					noButtons: true,
					svg: 'warning'
				});
				propos.onChange(false);
				icon = VisibilityOffIcon;
			}
		}
		return (<><Image onClick={() => {
			if (selectedCheckbox.some(b => b === true)) {
				setHidden(!hidden); propos.onChange(!hidden);
			}
			else {
				alert({
					title: 'Attention',
					content: 'Veuillez sélectionner au moins une entreprise',
					width: '475px',
					noButtons: true,
					svg: 'warning'
				});
			}
		}} url={icon} width='20px' /></>);
	}

	function isColAverage(row: ReactTableRow, columnIndex: number): boolean {
		let isAverage = false;
		let valueFound = false;
		function getAllCellValuesRecursively(row: ReactTableRow): void {
			if (valueFound || !row.subRows || row.subRows.length === 0) {
				if (columnIndex < row.cells.length) {
					const cell = row.cells[columnIndex].value;
					if (cell && cell.val) {
						if ('percentage' in cell.val || 'average' in cell.val) {
							isAverage = true;
							valueFound = true;
						}
						else if ('float' in cell.val || 'int' in cell.val) {
							valueFound = true;
						}
					}
				}
			}
			else
				row.subRows.forEach(child => getAllCellValuesRecursively(child));
		}
		getAllCellValuesRecursively(row);
		return valueFound ? isAverage : true;
	}

	function getAverageValueForDate(date: string, leafLen: number): {num: number, denom: number} | undefined {
		let numOfGoals = 0;
		const sum = goals.reduce((acc, goal: Goal) => {
			if (goal.goals && goal.granularity === reportGranularity && Object.keys(goal.path).length - 1 === leafLen && (!goal.column_name || !goal.column_name.length || selectedPanelName === goal.column_name)) {
				const goalForDate = goal.goals.find((goal) => goal.date === date);
				if (goalForDate) {
					const goalForDateVal = goalForDate.value;
					if (goalForDateVal && !isNaN(goalForDateVal)) {
						numOfGoals++;
						return goalForDateVal + acc;
					}
				}
			}
			return acc;
		}, 0);
		return sum && numOfGoals ? { num: sum, denom: numOfGoals } : undefined;
	}

	function getPathFromRowWithDate(row: ReactTableRow, formattedDate: string): GoalPath {
		return ({ ...row.path, date: formattedDate } as GoalPath);
	}

	function getFinalChildrenGoalsRecursively(row: ReactTableRow, value: string, hashedPaths: string[], siblingGoalValues: number[], reportHeadersNoDate: Header[], formattedDate: string): void {
		if (!row.subRows || row.subRows.length === 0) {
			let goalValue: { value: string, index: number } | undefined;
			const currentPathHash = hash(getPathFromRowWithDate(row, formattedDate));
			if (hashedPaths.includes(currentPathHash))
				goalValue = { value, index: -1 };
			else
				goalValue = goalPathsHashMap.get(currentPathHash);
			if (goalValue && goalValue.value && goalValue.value.length > 0)
				siblingGoalValues.push(parseFloat(goalValue.value));
		}
		else
			row.subRows.forEach(sibling => getFinalChildrenGoalsRecursively(sibling, value, hashedPaths, siblingGoalValues, reportHeadersNoDate, formattedDate));
	}

	function getCellValue(cell: Value, emptyGoalInterpretation: string): number | null {
		let cellValue: number | null;
		switch (cell.tag) {
			case 0:
				cellValue = cell.val.percentage.numer / cell.val.percentage.denom * 100;
				break;
			case 4:
				cellValue = cell.val.float;
				break;
			case 5:
				cellValue = cell.val.int;
				break;
			case 17:
				cellValue = cell.val.average.numerator / cell.val.average.denominator;
				break;
			default:
				cellValue = emptyGoalInterpretation === 'null' ? null : 0;
				break;
		}
		return cellValue;
	}

	function getAverageCellValueOfChildrenWithZeros(row: ReactTableRow, reportHeadersNoDate: Header[], formattedDate: string, cellIndex: number): number | null {
		const allValues: number[] = [];
		function getAllCellValuesRecursively(row: ReactTableRow): void {
			if (!row.subRows || row.subRows.length === 0) {
				const currentPathHash = hash(getPathFromRowWithDate(row, formattedDate));
				const goalValue = goalPathsHashMap.get(currentPathHash);
				if (goalValue && goalValue.value && goalValue.value.length > 0 && cellIndex < row.cells.length) {
					const cell = row.cells[cellIndex].value;
					const cellValue = getCellValue(cell, '0');
					if (cellValue !== null)
						allValues.push(cellValue);
				}
			}
			else
				row.subRows.forEach(child => getAllCellValuesRecursively(child));
		}
		getAllCellValuesRecursively(row);
		return allValues.length > 0 ? allValues.reduce((acc, val) => acc + val, 0) / allValues.length : null;
	}

	function updateOneGoal(formattedDate: string, trimmedInput: string, path: GoalPath, goalValue: {value: string; index: number; dashboard: boolean} | undefined, newGoals: Goal[]) {
		const pathWithoutDate = Object.keys(path).reduce((acc, key) => {
			if (key !== 'date') {
				acc[key] = path[key];
			}
			return acc;
		}, {});
		const inputToFloat = parseFloat(trimmedInput);
		if (_.isEmpty(pathWithoutDate)) return;
		if (goalValue?.index !== undefined && goalValue.index >= 0 && newGoals[goalValue.index]) {
			if (!trimmedInput.length) {
				newGoals[goalValue.index].goals = newGoals[goalValue.index].goals.filter(g => g.date !== formattedDate);
			}
			else
				newGoals[goalValue.index].goals = newGoals[goalValue.index].goals.map(g => {
					if (g.date === formattedDate) {
						return { 
							...g,
							value: inputToFloat,
							date: formattedDate,
						};
					}
					return g;
				});
		}
		else {
			if (trimmedInput.length > 0) {
				const pathStringified = hash(pathWithoutDate);
				const newGoalPath = newGoals.find(g => hash(g.path) === pathStringified && g.granularity === reportGranularity && (!g.column_name || g.column_name === selectedPanelName));
				if (newGoalPath) {
					newGoalPath.goals.push({ value: inputToFloat, date: formattedDate, dashboard: goalValue?.dashboard ?? false });
				}
				else {
					newGoals.push({
						report_id: props.reportId,
						path: pathWithoutDate,
						column_name: selectedPanelName,
						granularity: reportGranularity,
						goals: [{ value: inputToFloat, date: formattedDate, dashboard: goalValue?.dashboard ?? false }]
					});
				}
			}
		}
	}

	function setAllColumnGoals(values: string[], row: ReactTableRow, formattedDate: string, reportHeadersNoDate: Header[], newGoals: Goal[]): void {
		if (!row.parent)
			return;
		const siblingPaths: string[] = [];
		const activeRowPath = getPathFromRowWithDate(row.parent, formattedDate);
		row.parent.subRows.forEach((sibling, i) => {
			const siblingPathValue = sibling.primaryCell[reportHeadersNoDate.length - 1].value.val;
			const objectTitle = Object.values(reportHeadersNoDate[reportHeadersNoDate.length - 1].cell.value.val ?? {})[0];
			let totalPath;
			if (siblingPathValue) {
				totalPath = { ...activeRowPath, [objectTitle]: {
					'value': siblingPathValue,
					'index': reportHeadersNoDate.length - 1
				}
				};
				siblingPaths.push(hash(totalPath));
			}
			const siblingGoalValue = goalPathsHashMap.get(hash(totalPath));
			updateOneGoal(formattedDate, values.length > i ? values[i] : values[0], totalPath, siblingGoalValue, newGoals);
		});
	}

	function updateOneDashboard(goalValue: {value: string; index: number; dashboard: boolean} | undefined, isDashboard: boolean, formattedDate: string, newGoals: Goal[]) {
		if (!goalValue) return;
		newGoals[goalValue.index].goals = newGoals[goalValue.index].goals.map(g => {
			if (g.date === formattedDate) {
				return { 
					...g,
					dashboard: isDashboard
				};
			}
			return g;
		});
	}

	function updateAllDashboard(row: ReactTableRow, reportHeadersNoDate: Header[], isDashboard: boolean, formattedDate: string, newGoals: Goal[]) {
		if (!row.parent)
			return;
		const activeRowPath = getPathFromRowWithDate(row.parent, formattedDate);
		row.parent.subRows.forEach((sibling) => {
			const siblingPathValue = sibling.primaryCell[reportHeadersNoDate.length - 1].value.val;
			const objectTitle = Object.values(reportHeadersNoDate[reportHeadersNoDate.length - 1].cell.value.val ?? {})[0];
			let totalPath;
			if (siblingPathValue) {
				totalPath = { ...activeRowPath, [objectTitle]: {
					'value': siblingPathValue,
					'index': reportHeadersNoDate.length - 1
				}
				};
			}
			updateOneDashboard(goalPathsHashMap.get(hash(totalPath)), isDashboard, formattedDate, newGoals);
		});
	}

	function areNoChildrenSet(row: ReactTableRow, formattedDate: string): boolean {
		if (!row.subRows || row.subRows.length === 0) {
			const currentPathHash = hash(getPathFromRowWithDate(row, formattedDate));
			return !goalPathsHashMap.has(currentPathHash);
		}
		return row.subRows.every(child => areNoChildrenSet(child, formattedDate));
	}

	function areAllChildrenSetDashboard(row: ReactTableRow, formattedDate: string): boolean {
		if (!row.subRows || row.subRows.length === 0) {
			const currentPathHash = hash(getPathFromRowWithDate(row, formattedDate));
			const goalValue = goalPathsHashMap.get(currentPathHash);
			if (!goalValue) return true;
			return goalValue.dashboard;
		}
		return row.subRows.every(child => areAllChildrenSetDashboard(child, formattedDate));
	}

	React.useEffect(() => {
		props.setToolBarState?.({
			bottomLeftToolbarComponent: <>
				{props.filterParams !== undefined && props.filterParams.length > 0 &&
					<ToolbarFilterButton
						activeFilters={
							props.context ? (nbFilters + filterTreeLength(filterResult.formatted) + (props.externalActiveFilter ?? 0)) : filterTreeLength(filterResult.formatted) + (props.externalActiveFilter ?? 0)
						}
						onClick={() => {
							setOpenSummary(true);
							setFilterOpen(true);
						}}
						onDeleteFilter={() => {
							if (props.context) {
								setFilters({ ...filters, form_filter: undefined, created_by_filter: undefined, filter: undefined, my_filters: { ...(filters.my_filters), tags: undefined, products: undefined, brands: undefined } });
							}
							setTagsFilter([]);
							setFilterResult({ values: { combinator: 'and', array: [] }, formatted: undefined });
						}}
					/>}
			</>,
			bottomRightToolbarComponent: <>
				<FlexDiv gap='10px'>
					{props.visualizationFitlerParams !== undefined && props.visualizationFitlerParams?.length > 0 &&
						<ToolbarFilterButton
							title={translateToString('report_parameters')}
							activeTitle={translateToString('report_parameters') + ' ({{NUMBER}})'}
							iconSize='20px'
							icon={setting}
							onClick={() => {
								setOpenSummary(true);
								setVizualisationFilterOpen(true);
							}}
							onDeleteFilter={() => {
								setVizualisationFilterResult({ values: { combinator: 'and', array: [] }, formatted: undefined });
							}}
						/>
					}
					<SwitchView src={listView_black} active={selectedView === 'table'} onClick={_ => setSelectedView('table')} />
					<SwitchView src={reports_black} active={selectedView === 'chart'} onClick={_ => {
						if (selectedCheckbox.some(b => b)) {
							setSelectedView('chart');
						} else {
							alert({
								title: 'Attention',
								content: <Translate id='select_at_least_one_company' />,
								width: '475px',
								noButtons: true,
								svg: 'warning'
							});
						}
					}} />
				</FlexDiv>
			</>
		});
	}, [selectedView, selectedCheckbox, filterResult, props.externalActiveFilter, props.visualizationFitlerParams, vizualisationFilterResult, nbFilters]);

	const chart = React.useMemo(() => report && reportToChart(r, report, selectedPanel, selectedCheckbox, colors, selectedHeader ?? 0, users, products, companies, campaigns), [selectedView, selectedPanel, report, selectedHeader]);
	const onTableLoad = React.useCallback(() => setLoadingState(LoadingStateEnum.LOADED), []);
	const reportHeaders = report ? report.headers.filter(h => h.cell.value.val && !('date' in h.cell.value.val)) : [];
	const datas = React.useMemo(() => report?.rows.filter(row_to_filter => {
		return selectedCheckbox[row_to_filter.id] || !hidden;
	}).reduce((acc: ReactTableRow[], row) => {
		const r = filterEmptyRow(toReactTableData(row, reportHeaders.length > 0 ? reportHeaders : undefined), vizualisationFilterResult.formatted);
		if (r) acc.push(r);
		return acc;
	}, []) ?? [], [report, hidden, vizualisationFilterResult]);

	const columns: Column<ReactTableRow>[] = React.useMemo(() => {
		let reprasentationIndex = 0;
		if (!report) return [];
		const primaryLength = report.headers.filter(h => h.primary).length;
		const cols = report.headers.reduce((acc: Column<ReactTableRow>[], header, columnIndex): Column<ReactTableRow>[] => {
			const headerName = valueToString(header.cell.value);
			let calcFieldName = headerName ?? '';
			const toolTipTemplate = header.tooltip[selectedPanel];
			let options;
			const isEmptyTitle = header.cell.value.val === null;
			if (isEmptyTitle) {
				options = {
					maxWidth: 60,
					minWidth: 60,
					width: 60,
					oldResize: true
				};
			} else {
				const titleWidth = headerName ? getStringWidth(headerName, 500, 100, 8) + 30 : 250;
				options = {
					maxWidth: 500,
					minWidth: titleWidth,
					width: titleWidth,
					noResizer: report.headers[columnIndex + 1]?.cell.value.val === null
				};
			}

			const tamer = reprasentationIndex; // Js bad, closures capture by ref so we need a copy
			const isHidden = vizualisationFilterResult.formatted && 'and' in vizualisationFilterResult.formatted && vizualisationFilterResult.formatted.and.some(f => 'val' in f && f.val.column === (columnIndex - primaryLength).toString() && f.val.operator === 'not_empty');
			const columnOptionsData: DropdownData[] = [
				{ value: !isHidden ? 'hide_lines' : 'show_lines', label: !isHidden ? translateToString('reports.column_options.hide') : translateToString('reports.column_options.unhide') },
			];
			if (header.storeable && props.reprasentation?.group_by === 'Company' && isAdmin) {
				columnOptionsData.push({ value: 'turn_to_calc_field', label: translateToString('reports.column_options.turn_to_calc_field') });
			}
			const col: Column<ReactTableRow> = {
				id: `column[${columnIndex}]`,
				noHeaderEllipsis: true,
				Header: isEmptyTitle ? <EvolutionDropdown headerIndex={columnIndex} /> :
					<FlexDiv width='95%'>
						<DefaultImage width='16px' filter={FilterBlue} src={VisibilityOffIcon} isHidden={!isHidden} />
						<CellToNode color={isHidden ? BlueSidely : ''} cell={header.cell} />
						{!header.primary &&
							<>
								<Dropdown
									stopPropagation
									name='ColumnOptions'
									dropdownStyle={{ optionWidth: '230px', optionLeft: '-205px', height: 'auto' }}
									datalist={columnOptionsData}
									JSXButton={() => <DefaultImage src={optionGrey} width='17px' height='17px' cursor='pointer' rotate='90deg' />}
									onChange={(value: DropdownData) => {
										switch (value.value) {
											case 'show_lines':
											case 'hide_lines': {
												const newT = vizualisationFilterResult.formatted ?? { and: [] };
												if ('and' in newT) {
													if (isHidden) {
														newT.and = newT.and.filter(f => !('val' in f && f.val.column === (columnIndex - primaryLength).toString() && f.val.operator === 'not_empty'));
													} else {
														newT.and.push({
															val: {
																column: (columnIndex - primaryLength).toString(),
																operator: 'not_empty'
															}
														});
													}
													setVizualisationFilterResult({ ...vizualisationFilterResult, formatted: { ...newT } });
												}
												break;
											}
											case 'turn_to_calc_field': alert({
												title: 'Attention',
												// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
												content: <FlexDiv flow='column' align='stretch' justify='center' gap='1.5rem' fontSize='14px' padding='10px 0 0 0'>
													<div>{translateToString('additional_create', [['headerName', headerName!]])}</div>
													<FlexDiv flow='column' align='stretch' gap='0.75rem'>
														<Translate id='calculated_fields.new_name' />
														<Input type='text' name='calcFieldName' value={calcFieldName} onChange={e => calcFieldName = e} inputStyle={{
															height: '40px',
															width: '365px',
															padding: '0 10px',
															borderRadius: '5px',
															fontSize: '14px',
														}} />
													</FlexDiv>
												</FlexDiv>,
												textAlign: 'center',
												width: '475px'
											}).then(r => {
												if (r == AlertRes.Ok) {
													// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
													props.onCreateCalcField?.(tamer, selectedPanel, calcFieldName.trim().length > 0 ? calcFieldName.trim() : headerName!);
												}
											}); break;
										}
									}}
								/>
							</>
						}
					</FlexDiv>,
				accessor: (row: ReactTableRow) => <RenderTableCell primaryLength={primaryLength} data={row} header={header} toolTipTemplate={toolTipTemplate} index={columnIndex} emptyTile={isEmptyTitle} />,
				Footer: report.footers?.[columnIndex] ? <RenderTableCell primaryLength={primaryLength} header={header} toolTipTemplate={toolTipTemplate} index={columnIndex} data={report.footers[columnIndex]} /> : <></>,
				sortType: sort(primaryLength, columnIndex, evolutionStateRef, users, products, companies, campaigns, statuses, ctx),
				toolTip: (row, type) => {
					const data = type == 'row' ? row : report.footers[columnIndex];
					if (!data) return;
					let cell: ComplexCell | Cell | undefined;
					let primaryCell: Cell | undefined;
					if ('primaryCell' in data && columnIndex < primaryLength) {
						cell = data.primaryCell[columnIndex];
						primaryCell = data.primaryCell[0];
					} if ('primaryCell' in data) {
						cell = data.cells?.[columnIndex - primaryLength] ? data.cells[columnIndex - primaryLength] : undefined;
						primaryCell = data.primaryCell.find(p => p.value.tag != EmptyCell.tag) ?? data.cells?.find(c => c.value.tag != EmptyCell.tag) ?? undefined;
					} else {
						cell = data;
						primaryCell = undefined;
					}
					if (!cell || cell.value.val === null) return undefined;
					return {
						cell,
						toolTipTemplate,
						primaryCell,
						pos: type === 'row' ? 'left' : 'up'
					};
				},
				...options
			};

			if (header.storeable) {
				reprasentationIndex++;
			}

			return [...acc, col];
		}, [
			{
				id: 'expander',
				Header: (props: unknown) => {
					//@ts-expect-error it works
					const { getToggleAllRowsExpandedProps, isAllRowsExpanded } = props;
					return (
						<span {...getToggleAllRowsExpandedProps({ title: undefined })} style={{ fontSize: '20px', cursor: 'pointer' }}>
							{isAllRowsExpanded ? '-' : '+'}
						</span>
					);
				},
				Cell: (p: { row }) => {
					const row = p.row;
					return row.canExpand ? (
						<span
							{...row.getToggleRowExpandedProps({
								title: undefined,
								style: {
									fontSize: '20px'
								}
							})}
						>
							<div style={{ position: 'relative', top: '50%', translate: '0 -50%' }}>
								{row.isExpanded ? '-' : '+'}
							</div>
						</span>
					) : null;
				},
				width: 60,
				Footer: 'Total'
			}
		]);
		if (props.goalsProps?.isGoals) {
			const reportHeadersLength = report.headers.length;
			const lastHeaderTitle = report.headers[reportHeadersLength - 1].cell.value.val;
			if (lastHeaderTitle && 'date' in lastHeaderTitle) {
				const allDates = report.headers.reduce((acc: {date: moment.Moment, index: number}[], h, i) => {
					if (h.cell.value.val && 'date' in h.cell.value.val) {
						const date = Object.values(h.cell.value.val.date.val)[0];
						if (date) {
							acc.push({ date: moment(date), index: i });
						}
					}
					return acc;
				}, []);
				const granularity = Object.keys(lastHeaderTitle.date.val)[0];
				const reportHeadersNoDate: Header[] = [];
				const reportHeadersDate: string[] = [];
				report.headers.forEach(h => {
					if (h.cell.value.val) {
						if (!('date' in h.cell.value.val))
							reportHeadersNoDate.push(h);
						else {
							let date = moment(Object.values(h.cell.value.val.date.val)[0]);
							switch (granularity) {
								case 'fourMonths':
									date = moment(`${date.year()}-${date.month()}-01`);
									break;
								default:
									date = date.startOf(granularity as moment.unitOfTime.StartOf);
									break;
							}
							reportHeadersDate.push(date.format('YYYY-MM-DD'));
						}
					}
				});
				setReportGranularity(granularity);
				if (granularity) {
					let date = (pickedDates && pickedDates[0]) ? moment(pickedDates[0]).startOf(granularity as moment.unitOfTime.StartOf) : moment().startOf('year');
					const endDate = (pickedDates && pickedDates[1]) ? moment(pickedDates[1]) : moment().endOf('year');
					const isAllColsInsertedAfter = allDates[allDates.length - 1].date.isBefore(date) || allDates[allDates.length - 1].date.isSame(date);
					let i = 0;
					while (date.isBefore(endDate) || date.isSame(endDate)) {
						const formattedDate = date.format('YYYY-MM-DD');
						const closestDateIndex = allDates.reduce((closestIndex, currentDate, currentIndex) => {
							const currentDiff = Math.abs(currentDate.date.diff(date));
							const closestDiff = Math.abs(allDates[closestIndex].date.diff(date));
							return currentDiff < closestDiff ? currentIndex : closestIndex;
						}, 0);
						const averageValueForDate = getAverageValueForDate(formattedDate, reportHeadersNoDate.length - 1);
						let insertIndex = allDates[closestDateIndex].index;
						if (allDates[closestDateIndex].date.isAfter(date))
							insertIndex++;
						if (isAllColsInsertedAfter)
							insertIndex++;
						const footerIndex = reportHeadersDate.findIndex(d => d === formattedDate);
						let isAverage = true;
						let footerCellValue;
						if (footerIndex != -1) {
							const row: ReactTableRow = { id: -1, cells: [], primaryCell: [], subRows: datas };
							isAverage = isColAverage(row, footerIndex);
							if (isAverage)
								footerCellValue = getAverageCellValueOfChildrenWithZeros(row, reportHeadersNoDate, formattedDate, footerIndex);
							else {
								const footerIndexForSum = reportHeadersDate.findIndex(d => d === formattedDate) + reportHeadersNoDate.length;
								footerCellValue = getCellValue(report.footers[footerIndexForSum].value, '0');
							}
						}
						let averageValueForDateRatio;
						if (averageValueForDate)
							averageValueForDateRatio = isAverage ? averageValueForDate.num / averageValueForDate.denom : averageValueForDate.num;
						const goalColumn = {
							id: `column[${reportHeadersLength + i * 4 + 1}]`,
							Header: <CellToNode cell={{ value: { val: { text: '🎯' }, tag: 1 }, link: null, tooltip: null }} fontSize='18px' />,
							Footer: averageValueForDate !== undefined ? (isAverage ? <CellToNode cell={{ value: { val: { average: { numerator: averageValueForDate.num, denominator: averageValueForDate.denom } }, tag: 17 }, link: null, tooltip: null }}/> 
								:
								<CellToNode cell={{ value: { val: { float: averageValueForDate.num }, tag: 4 }, link: null, tooltip: null }}/>) 
								: 
								<RenderTableCell primaryLength={primaryLength} data={{ value: { tag: 15, val: null }, values: [], link: null, tooltip: null }} header={report.headers[reportHeadersLength + i]} toolTipTemplate={null} index={reportHeadersLength + i} /> ,
							accessor: (row: ReactTableRow) => {
								const path = getPathFromRowWithDate(row, formattedDate);
								const pathLength = Object.keys(path).length - 1;
								let goalValue = goalPathsHashMap.get(hash(path));
								if (!goalValue && pathLength !== reportHeadersNoDate.length) {
									let averageGoalValue: number | undefined;
									let siblingGoalValues: number[] = [];
									getFinalChildrenGoalsRecursively(row, '', [], siblingGoalValues, reportHeadersNoDate, formattedDate);
									siblingGoalValues = siblingGoalValues.filter(v => !isNaN(v));
									if (siblingGoalValues.length > 0) {
										if (isAverage)
											averageGoalValue = parseFloat((siblingGoalValues.reduce((value, acc) => value + acc, 0) / siblingGoalValues.length).toFixed(2));
										else 
											averageGoalValue = parseFloat(siblingGoalValues.reduce((value, acc) => value + acc, 0).toFixed(2));
										goalValue = { value: averageGoalValue.toString(), index: -1, dashboard: false };
									}
								}
								return <GoalInputCell value={goalValue?.value ?? ''}
									onBlur={(value) => {
										const trimmedValue = value.trim();
										const newGoals = [...goals];
										updateOneGoal(formattedDate, trimmedValue, path, goalValue, newGoals);
										const newGoalsFiltered = newGoals.filter(g => g.goals.length > 0 && Object.keys(g.path).length === reportHeadersNoDate.length);
										postGoals(props.reportId, newGoalsFiltered);
										setGoals(newGoalsFiltered);
									}}
									onSetAllColumn={(value) => {
										const trimmedValue = value.trim();
										const newGoals = [...goals];
										if (!row.parent?.subRows || row.parent.subRows.length === 0)
											updateOneGoal(formattedDate, trimmedValue, path, goalValue, newGoals);
										else {
											setAllColumnGoals([trimmedValue], row, formattedDate, reportHeadersNoDate, newGoals);
										}
										const newGoalsFiltered = newGoals.filter(g => g.goals.length > 0 && Object.keys(g.path).length === reportHeadersNoDate.length);
										postGoals(props.reportId, newGoalsFiltered);
										setGoals(newGoalsFiltered);
									}}
									onSetAllColumnCustom={(value) => {
										const newGoals = [...goals];
										const valuesToSet: string[] = [];
										const dateCopy = moment(formattedDate);
										let lastColDate: string | undefined;
										switch (granularity) {
											case 'week':
												lastColDate = dateCopy.subtract(1, 'week').format('YYYY-MM-DD');
												break;
											case 'month':
												lastColDate = dateCopy.subtract(1, 'month').format('YYYY-MM-DD');
												break;
											case 'quarter':
												lastColDate = dateCopy.subtract(1, 'quarter').format('YYYY-MM-DD');
												break;
											case 'year':
												lastColDate = dateCopy.subtract(1, 'year').format('YYYY-MM-DD');
												break;
											default:
												lastColDate = dateCopy.subtract(1, 'month').format('YYYY-MM-DD');
												break;
										}
										if (lastColDate && row.parent) {
											const floatValue = parseFloat(value);
											row.parent.subRows.forEach((sibling) => {
												let valueToPush = value;
												const cellIndex = reportHeadersDate.findIndex(d => d === lastColDate);
												if (cellIndex !== -1) {
													const cell = sibling.cells[cellIndex].value;
													const cellValue = getCellValue(cell, props.goalsProps?.emptyGoalInterpretation ?? '0');
													if (cellValue !== null) {
														if (cellValue > floatValue)
															valueToPush = parseFloat(cellValue.toFixed(2)).toString();
													}
												} 
												valuesToSet.push(valueToPush);
											});
										}
										if (valuesToSet.length > 1)
											setAllColumnGoals(valuesToSet, row, formattedDate, reportHeadersNoDate, newGoals);
										else
											setAllColumnGoals([value], row, formattedDate, reportHeadersNoDate, newGoals);
										const newGoalsFiltered = newGoals.filter(g => g.goals.length > 0 && Object.keys(g.path).length === reportHeadersNoDate.length);
										postGoals(props.reportId, newGoalsFiltered);
										setGoals(newGoalsFiltered);
									}}
									isInputDisabled={!(meOwner || isAdmin) || pathLength !== reportHeadersNoDate.length}
								/>;
							},
						};
						const goalsEvolution = {
							id: `column[${reportHeadersLength + i * 4 + 2}]`,
							Header: <CellToNode cell={{ value: { val: { text: '%' }, tag: 1 }, link: null, tooltip: null }} />,
							Footer: averageValueForDate && footerCellValue !== undefined && footerCellValue !== null ? <CellToNode goal={true} cell={{ value: { val: { percentage: { numer: footerCellValue, denom: averageValueForDateRatio } }, tag: 0 }, link: null, tooltip: null }}/> : 
								(averageValueForDate && props.goalsProps.emptyGoalInterpretation !== 'null' ? <CellToNode goal={true} cell={{ value: { val: { percentage: { numer: 0, denom: 1 } }, tag: 0 }, link: null, tooltip: null }}/> : 
									<RenderTableCell primaryLength={primaryLength} data={{ value: { tag: 15, val: null }, values: [], link: null, tooltip: null }} header={report.headers[reportHeadersLength + i]} toolTipTemplate={null} index={reportHeadersLength + i} />) ,
							accessor: (row: ReactTableRow) => {
								const path = getPathFromRowWithDate(row, formattedDate);
								const pathLength = Object.keys(path).length - 1;
								const cellIndex = reportHeadersDate.findIndex(d => d === formattedDate);
								let goalValue = goalPathsHashMap.get(hash(path))?.value;
								if (!goalValue && pathLength !== reportHeadersNoDate.length) {
									let averageGoalValue: number | undefined;
									let siblingGoalValues: number[] = [];
									getFinalChildrenGoalsRecursively(row, '', [], siblingGoalValues, reportHeadersNoDate, formattedDate);
									siblingGoalValues = siblingGoalValues.filter(v => !isNaN(v));
									if (siblingGoalValues.length > 0) {
										if (isAverage)
											averageGoalValue = parseFloat((siblingGoalValues.reduce((value, acc) => value + acc, 0) / siblingGoalValues.length).toFixed(2));
										else
											averageGoalValue = parseFloat(siblingGoalValues.reduce((value, acc) => value + acc, 0).toFixed(2));
										goalValue = averageGoalValue.toString();
									}
								}
								if (goalValue) {
									const goalValueNumber = parseFloat(goalValue);
									if (!goalValueNumber || isNaN(goalValueNumber))
										return <RenderTableCell primaryLength={primaryLength} data={{ value: { tag: 15, val: null }, values: [], link: null, tooltip: null }} header={report.headers[reportHeadersLength + i]} toolTipTemplate={null} index={reportHeadersLength + i} />;
									if (cellIndex !== -1) {
										let cellValue: number | null;
										if (pathLength === reportHeadersNoDate.length || props.goalsProps?.emptyGoalInterpretation === 'null' || !isAverage) {
											const cell = row.cells[cellIndex].value;
											cellValue = getCellValue(cell, props.goalsProps?.emptyGoalInterpretation ?? '0');
										}
										else {
											cellValue = getAverageCellValueOfChildrenWithZeros(row, reportHeadersNoDate, formattedDate, cellIndex);
										}
										if (cellValue !== null)
											return <CellToNode goal={true} cell={{ value: { val: { percentage: { numer: cellValue, denom: goalValueNumber } }, tag: 0 }, link: null, tooltip: null }} />;
										return <RenderTableCell primaryLength={primaryLength} data={{ value: { tag: 15, val: null }, values: [], link: null, tooltip: null }} header={report.headers[reportHeadersLength + i]} toolTipTemplate={null} index={reportHeadersLength + i} />;
									}
									else {
										if (props.goalsProps?.emptyGoalInterpretation === 'null')
											return <RenderTableCell primaryLength={primaryLength} data={{ value: { tag: 15, val: null }, values: [], link: null, tooltip: null }} header={report.headers[reportHeadersLength + i]} toolTipTemplate={null} index={reportHeadersLength + i} />;
										return <CellToNode goal={true} cell={{ value: { val: { percentage: { numer: 0, denom: 1 } }, tag: 0 }, link: null, tooltip: null }} />;
									}
								}
								return <RenderTableCell primaryLength={primaryLength} data={{ value: { tag: 15, val: null }, values: [], link: null, tooltip: null }} header={report.headers[reportHeadersLength + i]} toolTipTemplate={null} index={reportHeadersLength + i} />;
							},
						};
						const goalsDashboard = {
							id: `column[${reportHeadersLength + i * 4 + 3}]`,
							Header: <FlexDiv justify='center'><DefaultImage width='18px' height='24px' src={DashboardImg}/></FlexDiv>,
							accessor: (row: ReactTableRow) => {
								const path = getPathFromRowWithDate(row, formattedDate);
								const goalValue = goalPathsHashMap.get(hash(path));
								const isCheckboxDisabled = !goalValue;
								let defaultChecked = goalValue?.dashboard ?? false;
								if (row.subRows && row.subRows.length > 0 && !areNoChildrenSet(row, formattedDate))
									defaultChecked = areAllChildrenSetDashboard(row, formattedDate);
								return <DashboardCheckbox 
									defaultChecked={defaultChecked}
									isCheckboxDisabled={isCheckboxDisabled}
									onChange={() => {
										const newGoals = [...goals];
										updateOneDashboard(goalValue, goalValue?.dashboard !== undefined ? !goalValue.dashboard : false, formattedDate, newGoals);
										setGoals(newGoals);
										postGoals(props.reportId, newGoals);
									}}
									onCheckAll={() => {
										const newGoals = [...goals];
										updateAllDashboard(row, reportHeadersNoDate, goalValue?.dashboard !== undefined ? goalValue.dashboard : false, formattedDate, newGoals);
										setGoals(newGoals);
										postGoals(props.reportId, newGoals);
									}}
								/>;
							},
							...{ maxWidth: 60, minWidth: 60, width: 60 }
						};
						switch (granularity) {
							case 'week':
								if (!allDates.find(d => d.date.isSame(date, 'week'))) {
									const newCol = {
										id: `column[${reportHeadersLength + i * 4}]`,
										Header: <CellToNode cell={{ value: { val: { date: { val: { [granularity]: formattedDate }, tag: 1 as const } }, tag: 8 }, link: null, tooltip: null }} />,
										accessor: () => <RenderTableCell primaryLength={primaryLength} data={{ value: { tag: 15, val: null }, values: [], link: null, tooltip: null }} header={report.headers[reportHeadersLength + i]} toolTipTemplate={null} index={reportHeadersLength + i} />,
										...{ maxWidth: 134, minWidth: 134, width: 134 }
									};
									cols.splice(insertIndex + i * 4, 0, newCol);
								}
								date.add(1, 'week');
								date = date.startOf('week');
								break;
							case 'month':
								if (!allDates.find(d => d.date.isSame(date, 'month'))) {
									const newCol = {
										id: `column[${reportHeadersLength + i * 4}]`,
										Header: <CellToNode cell={{ value: { val: { date: { val: { [granularity]: formattedDate }, tag: 2 as const } }, tag: 8 }, link: null, tooltip: null }} />,
										accessor: () => <RenderTableCell primaryLength={primaryLength} data={{ value: { tag: 15, val: null }, values: [], link: null, tooltip: null }} header={report.headers[reportHeadersLength + i]} toolTipTemplate={null} index={reportHeadersLength + i} />,
										...{ maxWidth: 134, minWidth: 134, width: 134 }
									};
									cols.splice(insertIndex + i * 4, 0, newCol);
								}
								date.add(1, 'month');
								date = date.startOf('month');
								break;
							case 'quarter':
								if (!allDates.find(d => d.date.isSame(date, 'quarter'))) {
									const newCol = {
										id: `column[${reportHeadersLength + i * 4}]`,
										Header: <CellToNode cell={{ value: { val: { date: { val: { [granularity]: formattedDate }, tag: 3 as const } }, tag: 8 }, link: null, tooltip: null }} />,
										accessor: () => <RenderTableCell primaryLength={primaryLength} data={{ value: { tag: 15, val: null }, values: [], link: null, tooltip: null }} header={report.headers[reportHeadersLength + i]} toolTipTemplate={null} index={reportHeadersLength + i} />,
										...{ maxWidth: 134, minWidth: 134, width: 134 }
									};
									cols.splice(insertIndex + i * 4, 0, newCol);
								}
								date.add(3, 'month');
								date = date.startOf('quarter');
								break;
							case 'fourMonths':
								if (!allDates.find(d => d.date.isSame(date, 'quarter'))) {
									const newCol = {
										id: `column[${reportHeadersLength + i * 4}]`,
										Header: <CellToNode cell={{ value: { val: { date: { val: { [granularity]: formattedDate }, tag: 4 as const } }, tag: 8 }, link: null, tooltip: null }} />,
										accessor: () => <RenderTableCell primaryLength={primaryLength} data={{ value: { tag: 15, val: null }, values: [], link: null, tooltip: null }} header={report.headers[reportHeadersLength + i]} toolTipTemplate={null} index={reportHeadersLength + i} />,
										...{ maxWidth: 134, minWidth: 134, width: 134 }
									};
									cols.splice(insertIndex + i * 4, 0, newCol);
								}
								date.add(4, 'month');
								date = moment(`${date.year()}-${date.month()}-01`);
								break;
							case 'year':
								if (!allDates.find(d => d.date.isSame(date, 'year'))) {
									const newCol = {
										id: `column[${reportHeadersLength + i * 4}]`,
										Header: <CellToNode cell={{ value: { val: { date: { val: { [granularity]: formattedDate }, tag: 5 as const } }, tag: 8 }, link: null, tooltip: null }} />,
										accessor: () => <RenderTableCell primaryLength={primaryLength} data={{ value: { tag: 15, val: null }, values: [], link: null, tooltip: null }} header={report.headers[reportHeadersLength + i]} toolTipTemplate={null} index={reportHeadersLength + i} />,
										...{ maxWidth: 134, minWidth: 134, width: 134 }
									};
									cols.splice(insertIndex + i * 4, 0, newCol);
								}
								date.add(1, 'year');
								date = date.startOf('year');
								break;
							default:
								date.add(1, 'month');
								break;
						}
						cols.splice(allDates[0].index + i * 4 + 2, 0, goalColumn);
						cols.splice(allDates[0].index + i * 4 + 3, 0, goalsEvolution);
						cols.splice(allDates[0].index + i * 4 + 4, 0, goalsDashboard);
						i++;
					}
				}
			}
		}
		return cols;
	}, [report, selectedPanel, selectedCheckbox, vizualisationFilterResult.formatted, additionalColumns, goalPathsHashMap, pickedDates]);

	if (!report) return <NoData message={translateToString('no data')} isError={true} height='calc(100% - 10px)' />;

	return <FlexDiv flow='column' align='stretch' height='100%' width='100%'>
		{r.panels.length > 1 && <PanelSelector
			panels={r.panels.map(p => ({ label: <ValueToNode value={p.value} /> }))}
			onClick={(_, i) => {
				if (selectedPanel === i) return;
				selectedView === 'table' && setLoadingState(LoadingStateEnum.LOADING);
				setTimeout(() => setSelectedPanel(i), 10);
			}}
			activeIndex={selectedPanel}
		/>}
		{selectedView === 'chart' && report.mainAxisRepresentation === AxisRepresentation.COMPANIES && <PanelSelector
			panels={report.headers.filter(h => !h.primary && h.visualisable).map(p => ({ label: <ValueToNode value={p.cell.value} /> }))}
			onClick={(_, i) => {
				if (selectedHeader === i) return;
				setTimeout(() => setSelectedHeader(i), 10);
			}}
			activeIndex={selectedHeader ?? 0}
		/>}
		<div style={{ backgroundColor: 'white', flexGrow: 1 }}>
			{selectedView === 'chart' && chart && <ChartReportingV2
				onCompanyClick={onCompanyClick}
				onFormInstanceClick={onFormInstanceClick}
				setToolBarState={() => null}
				chart={chart}
			/>}
			{selectedView === 'table' && <InfiniteTableStyle toolTipNotHidden>
				<InfiniteTable
					onLoad={onTableLoad}
					height={height}
					columns={columns}
					data={datas}
					header_thing={<Visibility defaultValue={hidden} onChange={(b) => { setHidden(b); }} />}
					setSelectedCheckbox={id => {
						if (selectedCheckbox.filter(b => b).length >= MAX_SELECTED && !selectedCheckbox[id]) return false;
						selectedCheckbox[id] = !(selectedCheckbox[id] ?? false);
						setSelectedCheckbox([...selectedCheckbox]);
						return selectedCheckbox[id];
					}}
					selectedCheckbox={selectedCheckbox}
				/>
				<InfiniteTableToolTip />
			</InfiniteTableStyle>}
		</div>
		{props.filterParams !== undefined &&
			<AdvancedFilters
				isOpen={isFilterOpen}
				setOpen={setFilterOpen}
				isOpenSummary={isOpenSummary}
				filterList={props.filterParams}
				filterValues={filterResult.values}
				onChange={setFilterResult}
				goalsProps={props.goalsProps}
			/>
		}
		{props.visualizationFitlerParams !== undefined &&
			<AdvancedFilters
				title={translateToString('report_parameters')}
				isOpen={isVizualisationFilterOpen}
				setOpen={setVizualisationFilterOpen}
				isOpenSummary={isOpenSummary}
				filterList={props.visualizationFitlerParams}
				filterValues={vizualisationFilterResult.values}
				onChange={setVizualisationFilterResult}
				extractFilters
				goalsProps={props.goalsProps}
			/>
		}
		<ComponentLoader loadingState={loadingState} allScreen noBackground />
	</FlexDiv>;
}

export function valueToNumber(value: Value): number | null {
	if (typeof value === 'string' || !value || !value.val) return null;
	if ('int' in value.val) return value.val.int;
	if ('float' in value.val) return value.val.float;
	if ('percentage' in value.val) return fractionToPercentage(value.val.percentage);
	if ('average' in value.val) return value.val.average.numerator / value.val.average.denominator;
	return null;
}

export type Fraction = {
	readonly numer: number,
	readonly denom: number,
} | [number, number] | number;

export function fractionToDecimal(f: Fraction): number {
	if (Array.isArray(f)) return f[0] / f[1];
	if (typeof f === 'number') return f;
	return f.numer / f.denom;
}

export function fractionToPercentage(f: Fraction): number {
	return fractionToDecimal(f) * 100;
}

// This function is revelant only if data is homogeneous
function getSecondaryAxisOption(cells: [readonly (Cell | Point)[], Cell, number][], headers: [string, number][]): SecondaryAxis | undefined {
	for (const cell of cells) {
		const headerCells = cell[0].filter((_, i) => headers.find(h => h[1] == i));
		for (const cell of headerCells) {
			if (typeof cell.value === 'string' || !cell.value) {
				continue;
			}
			if ('percentage' in cell.value) {
				return { min: 0, max: 100, ticksType: 'percentage', scale: null, title: '' };
			}
		}
	}
}

function reportToChart(r: Report, report: Panel, selectedPanel: number, selectedCheckbox: boolean[], colors: { rowColors: ColorAndT<number>[]; panelColors: ColorAndT<Panel>[]; }, selectedHeader: number, users: Owner[], products: Product[], companies: Record<number, string>, campaigns: Record<number, Campaign>): Chart {
	const primaryIndex = report.headers.reduce((acc, h, i) => h.primary ? i : acc, -1);
	const barChartOption = report.headers.find(h => !h.primary && h.visualisable)?.barChart[selectedPanel];
	const headers: [string, number][] = report.headers.reduce((acc, h, i) => {
		if (h.primary || !h.visualisable) return acc;
		const headerText = valueToString(h.cell.value);
		return headerText ? [...acc, [headerText, i - primaryIndex - 1]] : acc;
	}, []);
	const flattenCells = (acc: [readonly (Cell | ComplexCell)[], Cell, number][], row: Row): [readonly (Cell | ComplexCell)[], Cell, number][] => {
		acc = [...acc, ...row.rows.reduce(flattenCells, [])];
		if (!selectedCheckbox[row.id]) return acc;
		return [...acc, [row.cells, (row.primaryCell.find(p => p.value.tag != EmptyCell.tag) ?? row.cells?.find(c => c.value.tag != EmptyCell.tag))!, row.id]];
	};
	const flattenRow = (acc: Row[], row: Row): Row[] => {
		acc = [...acc, ...row.rows.reduce(flattenRow, [])];
		if (!selectedCheckbox[row.id]) return acc;
		return [...acc, row];
	};
	const rows = report.rows.reduce(flattenRow, []);
	const cells: [readonly (Cell | ComplexCell)[], Cell, number][] = report.rows.reduce(flattenCells, []);
	const secondaryAxisOptions = getSecondaryAxisOption(cells, headers);
	const stacked = report.stacked || selectedCheckbox.filter(b => b).length === 1 && cells[0] && cells[0][0] && cells[0][0][0] && 'values' in cells[0][0][0] && cells[0][0][0].values.length > 0;
	return {
		stacked,
		title: valueToString(r.title) ?? '',
		curves: report.mainAxisRepresentation === AxisRepresentation.COMPANIES
			? genCompaniesCurves(r.panels[selectedPanel], rows, selectedHeader)
			: cells.reduce((acc: Curve[], value, i): Curve[] => {
				if (selectedCheckbox.filter(b => b).length === 1 && value[0] && value[0][0] && 'values' in value[0][0] && value[0][0].values.length > 0) {
					acc.push({
						curveValues: value[0].filter((_, i) => headers.find(h => h[1] == i)).map(cell => valueToNumber(cell.value)),
						link: primaryIndex < 0 ? null : value[1].link?.val ?? null,
						color: stringToColour('Total'),
						title: 'Total',
						representation: 'CURVE'
					});
					for (let subPanelIndex = 0; subPanelIndex < value[0][0].values.length; subPanelIndex++) {
						const curveValues = headers.map(([_, headerIndex]) => {
							const complexCell = value[0][headerIndex];
							if (!complexCell || !('values' in complexCell)) return null;
							return valueToNumber(complexCell.values[subPanelIndex]);
						});
						acc.push({
							curveValues,
							link: primaryIndex < 0 ? null : value[1].link?.val ?? null,
							color: barChartOption?.[subPanelIndex].color ?? '',
							title: barChartOption?.[subPanelIndex].name ? valueToString(barChartOption[subPanelIndex].name) ?? '' : '',
							representation: 'BAR_CHART'
						});
					}
				} else {
					const title = valueToString(value[1].value, users, products, companies, campaigns);
					const v = { 
						curveValues: value[0].filter((_, i) => headers.find(h => h[1] == i)).map(cell => valueToNumber(cell.value)),
						link: primaryIndex < 0 ? null : value[1].link?.val ?? null,
						color: title ? stringToColour(title) : (colors.rowColors.find(c => c.value === value[2])?.color ?? ''),
						title: title ?? '',
						representation: r.panels[selectedPanel].representation
					};
					acc.push(v);
				}
				return [...acc];
			}, []),
		mainAxis: {
			// TODO
			title: '',
			labels: report.mainAxisRepresentation === AxisRepresentation.COMPANIES ? genCompaniesAxis(rows) : headers.map(h => h[0]),
			key: report.mainAxisKey,
			// TODO
			ticksType: 'string'
		},
		secondaryAxis: secondaryAxisOptions ?? {
			// TODO
			title: '',
			// TODO
			min: null,
			// TODO
			max: null,
			// TODO
			scale: null,
			// TODO
			ticksType: 'string'
		}
	};
}

function genCompaniesAxis(rows: Row[]): string[] {
	return rows.map(row => valueToString(row.primaryCell[0].value) ?? '');
}

function genCompaniesCurves(report: Panel, rows: Row[], selectedHeader: number): Curve[] {
	const index = report.headers.filter(h => !h.primary).map((h, i) => ({ keep: h.visualisable && !h.primary, i })).filter(h => h.keep)[selectedHeader]?.i;
	if (index === undefined) return [];
	const firstValue: Value = rows.reduce((acc: Value, r) => {
		if (acc.val !== null) return acc;
		return r.cells[index]?.value ?? EmptyCell;
	}, EmptyCell);

	if (firstValue.val && 'pieChart' in firstValue.val) {
		const res: { [key: string]: { values: (number | undefined)[], title: string } } = rows.reduce((acc, r) => {
			const val = r.cells[index];
			if (!val || !val.value.val || !('pieChart' in val.value.val)) return acc;
			const total = val.value.val.pieChart.reduce((acc, v) => acc + v.count, 0);
			val.value.val.pieChart.forEach((v, i) => {
				const color = stringToColour(v.name);
				if (!acc[color]) {
					let title = '';
					if (val.tooltip?.content.val && 'table' in val.tooltip.content.val) {
						title = valueToString(val.tooltip.content.val.table[i][0]) ?? '';
					}
					acc[color] = { title, values: [] };
				}
				acc[color].values[i] = v.count / total;
			});
			return acc;
		}, {});
		return Object.entries(res).map(([color, curveValues]) => ({
			curveValues: curveValues.values.map(v => v ?? 0),
			link: null,
			color,
			title: curveValues.title,
			representation: 'BAR_CHART'
		}));
	} else {
		const curveValues = rows.reduce((acc, r) => [...acc, valueToNumber(r.cells[index]?.value)], []);
		return [{
			curveValues,
			link: null,
			color: BlueSidely,
			title: valueToString(report.headers.filter(h => !h.primary)[index].cell.value) ?? 'values',
			representation: 'BAR_CHART'
		}];
	}
}

export const SwitchView = styled.img<{ active: boolean }>`
	${p => p.active ? '' : `
		cursor: pointer;
		opacity: 0.2;
	`}
	height: 24px;
`;

const ToolTipContainer = styled.div`
	border: none !important;
	height: 0 !important;
	width: 0 !important;
`;

export function InfiniteTableToolTip(): JSX.Element {
	const context = React.useContext(ReportInterpretorContext);
	if (!context.hover) return <></>;
	const { cell, bounds, toolTipTemplate, primaryCell, pos } = context.hover;
	const t = { ...context.hover };
	let tooltipClass = 'tooltip2';
	let tooltipTextClass = 'tooltiptext2';
	if (pos == 'up') {
		tooltipClass = 'tooltipup';
		tooltipTextClass = 'tooltiptextup';
	}

	if (cell.value.val && 'percentage' in cell.value.val) {
		return <ToolTipContainer className='table' onMouseEnter={() => context.setHover(t)} onMouseLeave={() => context.setHover(undefined)}>
			<div className={tooltipClass} style={{ position: 'absolute', left: bounds.left, top: bounds.top }}>
				<FlexDiv justify='center' flow='column' gap='10px' className={tooltipTextClass}>
					{primaryCell && <div className='font-weight-bold' style={{ marginBottom: '2px' }}>
						<CellToNode cell={primaryCell} primary/>
					</div>}
					{cell.value.val.percentage.numer}/{cell.value.val.percentage.denom}
				</FlexDiv>
			</div>
		</ToolTipContainer>;
	}

	if (cell.value.val && 'evolution' in cell.value.val) {
		return <ToolTipContainer className='table' onMouseEnter={() => context.setHover(t)} onMouseLeave={() => context.setHover(undefined)}>
			<div className={tooltipClass} style={{ position: 'absolute', left: bounds.left, top: bounds.top }}>
				<FlexDiv justify='center' flow='column' gap='10px' className={tooltipTextClass}>
					{primaryCell && <div className='font-weight-bold' style={{ marginBottom: '2px' }}>
						<CellToNode cell={primaryCell} primary/>
					</div>}
					<ValueToNode value={cell.value.val.evolution.old} />
				</FlexDiv>
			</div>
		</ToolTipContainer>;
	}

	if (cell.value.val && 'average' in cell.value.val) {
		return <ToolTipContainer className='table' onMouseEnter={() => context.setHover(t)} onMouseLeave={() => context.setHover(undefined)}>
			<div className={tooltipClass} style={{ position: 'absolute', left: bounds.left, top: bounds.top }}>
				<FlexDiv justify='center' flow='column' gap='10px' className={tooltipTextClass}>
					{primaryCell && <div className='font-weight-bold' style={{ marginBottom: '2px' }}>
						<CellToNode cell={primaryCell} primary/>
					</div>}
					{`${cell.value.val.average.numerator.toFixed(2)} / ${cell.value.val.average.denominator}`}
				</FlexDiv>
			</div>
		</ToolTipContainer>;
	}

	if (cell.value.val && 'pieChart' in cell.value.val) {
		const total = cell.value.val.pieChart.reduce((acc, v) => acc + v.count, 0);
		const array = [...cell.value.val.pieChart];
		array.sort((a, b) => b.count - a.count);
		return <ToolTipContainer className='table' onMouseEnter={() => context.setHover(t)} onMouseLeave={() => context.setHover(undefined)}>
			<div className={tooltipClass} style={{ position: 'absolute', left: bounds.left, top: bounds.top }}>
				<FlexDiv justify='center' flow='column' gap='10px' className={tooltipTextClass}>
					{primaryCell && <div className='font-weight-bold' style={{ marginBottom: '2px' }}>
						<CellToNode cell={primaryCell} primary/>
					</div>}
					<table>
						{array.map(({ count, name }, i) => <tr key={`toolTip[${i}]`}>
							<td style={{ backgroundColor: stringToColour(name), width: '10px', height: '10px' }} />
							<td>{name}</td>
							<td>{count} ({((count / total) * 100).toFixed(0)}%)</td>
						</tr>)}
						<td></td><td></td><td>{total}</td>
					</table>
				</FlexDiv>
			</div>
		</ToolTipContainer>;
	}

	if (cell.value.val && 'list' in cell.value.val) {
		const total = cell.value.val.list.reduce((acc, v) => acc + v.count, 0);
		return <ToolTipContainer className='table' onMouseEnter={() => context.setHover(t)} onMouseLeave={() => context.setHover(undefined)}>
			<div className={tooltipClass} style={{ position: 'absolute', left: bounds.left, top: bounds.top }}>
				<FlexDiv justify='center' flow='column' gap='10px' className={tooltipTextClass}>
					<table>
						{cell.value.val.list.map(({ value, count }, i) => <tr key={`toolTip[${i}]`}>
							<td>{value}</td>
							<td>{count}</td>
						</tr>)}
						<td></td><td>{total}</td>
					</table>
				</FlexDiv>
			</div>
		</ToolTipContainer>;
	}

	if (cell.tooltip) {
		let toolTipContent: React.ReactNode = <></>;

		if ('simpleValue' in cell.tooltip.content.val) {
			toolTipContent = <ValueToNode value={cell.tooltip.content.val.simpleValue} />;
		} else if ('table' in cell.tooltip.content.val) {
			toolTipContent = <table>
				{cell.tooltip.content.val.table.map((values, x) => <tr key={`toolTip[${x}]`}>
					{values.map((value, y) => <ToolTipTd key={`toolTip[${x}][${y}]`} noBorder={x === 0}>{<ValueToNode value={value} />}</ToolTipTd>)}
				</tr>)}
			</table>;
		}
		return <ToolTipContainer className='table' onMouseEnter={() => context.setHover(t)} onMouseLeave={() => context.setHover(undefined)}>
			<div className={tooltipClass} style={{ position: 'absolute', left: bounds.left, top: bounds.top }}>
				<FlexDiv justify='center' flow='column' gap='10px' className={tooltipTextClass}>
					{toolTipContent}
				</FlexDiv>
			</div>
		</ToolTipContainer>;
	}
	if (toolTipTemplate) {
		const title = referenceToNode(toolTipTemplate.title, primaryCell, cell);
		return <ToolTipContainer className='table' onMouseEnter={() => context.setHover(t)} onMouseLeave={() => context.setHover(undefined)}>
			<div className={tooltipClass} style={{ position: 'absolute', left: bounds.left, top: bounds.top }}>
				<FlexDiv justify='center' flow='column' gap='10px' className={tooltipTextClass}>
					{title && <div className='font-weight-bold' style={{ marginBottom: '2px' }}>
						{title}
					</div>}
					<FlexDiv justify='center' align='center'>
						<table>
							{toolTipTemplate.content.map((values, x) => <tr key={`toolTip[${x}]`}>
								{values.map((value, y) => {
									let nodeValue: React.ReactNode;
									if ('value' in value.val) {
										nodeValue = <ValueToNode value={value.val.value} />;
									} else {
										nodeValue = referenceToNode(value.val.reference, primaryCell, cell);
									}
									return <ToolTipTd key={`toolTip[${x}][${y}]`} noBorder={x === 0 && title === undefined}>{nodeValue}</ToolTipTd>;
								})}
							</tr>)}
						</table>
					</FlexDiv>
				</FlexDiv>
			</div>
		</ToolTipContainer>;
	}

	return <ToolTipContainer className='table' onMouseEnter={() => context.setHover(t)} onMouseLeave={() => context.setHover(undefined)}>
		<div className={tooltipClass} style={{ position: 'absolute', left: bounds.left, top: bounds.top }}>
			<FlexDiv justify='center' flow='column' gap='10px' className={tooltipTextClass}>
				{primaryCell && <div className='font-weight-bold' style={{ marginBottom: '2px' }}>
					<CellToNode cell={primaryCell} primary/>
				</div>}
				<ValueToNode value={cell.value} />
			</FlexDiv>
		</div>
	</ToolTipContainer>;
}

function EvolutionDropdown(props: { headerIndex: number }) {
	const { evolutionState, setEvolutionState } = React.useContext(ReportInterpretorContext);
	const currentState = evolutionState[props.headerIndex] ?? '%';
	return <Dropdown
		dropdownStyle={{
			height: '20px',
			optionWidth: '60px'
		}}
		datalist={[{ label: '%', value: '%' }, { label: '+', value: '+' }]}
		name={`Operator[${props.headerIndex}]`}
		selectedValue={{ label: currentState, value: currentState }}
		JSXOption={({ options, onOptionClicked }) => {
			return <>{options.map((element, i) => <OptionBlock key={i} onClick={(e) => {
				e.stopPropagation();
				onOptionClicked(element, i);
			}}>{element.label}</OptionBlock>)}</>;
		}}
		JSXButton={({ value, setIsOpen }) => {
			return <EvolutionOperatorContainer onClick={(e) => {
				e.stopPropagation();
				setIsOpen(isOpen => !isOpen);
			}}>{value?.label}</EvolutionOperatorContainer>;
		}}
		onChange={value => setEvolutionState(state => {
			state[props.headerIndex] = value.value;
			return { ...state };
		})}
	/>;
}
