import { FieldType } from 'bindings/forms/FieldType';
import { Editor } from '@monaco-editor/react';
import * as monaco from 'monaco-editor';
import * as React from 'react';
import styled from 'styled-components';
import Add from '../../components_v2/add/Add';
import Dropdown from '../../components_v2/dropdown/Dropdown';
import Input from '../../components_v2/input/Input';
import Popup from '../../components_v2/popup/Popup';
import PopupCreation from '../../components_v2/popup/PopupCreation';
import { PopupMode } from '../../components_v2/popup/model/Model';
import { DeleteDot } from '../../styles/global/css/Dot';
import { translateToString } from '../../styles/global/translate';
import { CreationPopupContainer, creationPopupDropdownStyle, creationPopupInputStyle } from '../client-companies/style/Style';
import { creationPopupInputStyleMargin } from '../promotions/PopupPromotion';
import { getField, postField, putField } from './actions';
import { ModalData } from './FormTemplateCreator';
import { BorderColor, DarkGreySidely2 } from '../../styles/global/css/Utils';
import { FlexDiv } from '../products/style';
import { getLocalStorage } from '../../helpers/localStorage';

export const ALL_FIELD_TYPES: FieldType[] = ['Text', 'Number' , 'Date' , 'Boolean' , 'Select' , 'Multiselect' , 'File' , 'Image' , 'Url' , 'Email' , 'Phone' , 'Address' , 'Location' , 'User', 'Company', 'Integer', 'CheckIn', 'Form', 'TextArea', 'Signature', 'Money'];

type MetaData = {
	Select?: string[],
	Multiselect?: string[],
	Form?: number,
	CheckIn?: number,
}

const SelectMetaDataContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 1em;
`;

export function SelectMetaData(props: { title?: string, value?: string, first: boolean, locked?: boolean, onDelete?: () => void, onChange: (value: string) => void }) {
	return <SelectMetaDataContainer>
		<Input
			disabled={!!props.locked}
			required
			label={props.first ? (props.title ?? 'MetaData') : undefined}
			value={props.value}
			type='text'
			name='selectMetaData'
			inputStyle={props.first ? creationPopupInputStyleMargin : creationPopupInputStyle}
			onChange={props.onChange}
		/>
		{props.onDelete !== undefined && !props.locked && <DeleteDot style={{ marginTop: props.first ? '21px' : undefined }} onClick={props.onDelete}/>}
	</SelectMetaDataContainer>;
}

const metaDataValidator = (metaData: MetaData, type: FieldType) => {
	switch (type) {
		case 'Select':
		case 'Multiselect':
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			return metaData[type] !== undefined && metaData[type]!.length !== 0 && metaData[type]!.every(e => e.length);
		case 'Form':
		case 'CheckIn':
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			return metaData[type] !== undefined && metaData[type]! > 0;
		default: return true;
	}
};

type MultiDimensionFieldData = {
	is_company: boolean,
	is_form: boolean,
	is_additional: boolean,
	is_product: boolean,
}

const BoolCheckbox = styled.input`
	margin: 0;
	accent-color: hsl(198, 100%, 44%);
	cursor: pointer;
	filter: brightness(106%); 
`;

export default function PopupField(props: { isOpen: boolean, setIsOpen: (b: boolean) => void, field: ModalData, onCreate: () => void }) {
	const [type, setType] = React.useState<FieldType | undefined>(props.field.type);
	const [name, setName] = React.useState<string>(props.field.name ?? '');
	const [multidimensionBools, setMultidimensionBools] = React.useState<MultiDimensionFieldData>({ is_company: props.field.is_company ?? false, is_form: props.field.is_form ?? false, is_additional: props.field.is_additional ?? false, is_product: props.field.is_product ?? false });
	const [data, setData] = React.useState<MetaData>({});
	const textAreaRef = React.useRef<HTMLTextAreaElement>(null);
	const constraintEditor = React.useRef<monaco.editor.IStandaloneCodeEditor | null>(null);
	const [constraint, setConstraint] = React.useState<string | undefined>(props.field.constraint ? JSON.stringify(props.field.constraint) : '{}');
	const isDarkMode = getLocalStorage('isDarkMode') ?? false;

		
	React.useEffect(() => setType(props.field.type), [props.field.type]);
	React.useEffect(() => setName(props.field.name ?? ''), [props.field.name]);
	const reset = () => {
		setType(undefined);
		setName('');
		setData({});
	};

	React.useEffect(() => {
		if (props.field.field_id !== undefined) {
			getField(props.field.field_id).then(field => {
				setType(field.type);
				setName(field.name);
				setMultidimensionBools({ is_company: field.is_company, is_form: field.is_form, is_additional: field.is_additional, is_product: field.is_product });
				setData(field.data ? { [field.type]: field.data } : {});
				setConstraint(field.constraint ? JSON.stringify(field.constraint) : '{}');
			});
		}
	}, [props.field.field_id]);

	return <Popup
		popupMode={PopupMode.Details}
		isOpen={props.isOpen}
		onClickOut={() => {
			reset();
			props.setIsOpen(false);
		}}
	>
		<PopupCreation
			messageButton={props.field.field_id ? 'Sauvegarder' : 'Créer'}
			title={props.field.field_id ? 'Field edition' : 'Field creation'}
			canValidate={type !== undefined && name.length !== 0 && metaDataValidator(data, type)}
			onSubmit={() => {
				if (props.field.field_id) {
					if (type) {
						putField(props.field.field_id, {
							id: props.field.field_id,
							type,
							name,
							data: data[type],
							is_company: multidimensionBools.is_company,
							is_form: multidimensionBools.is_form,
							is_additional: multidimensionBools.is_additional,
							is_product: multidimensionBools.is_product,
							constraint: constraint === '{}' || !constraint ? undefined : JSON.parse(constraint),
						}).then(() => {
							reset();
							props.onCreate();
						});
					}
				} else {
					if (!type) return;
					postField({
						type,
						name,
						data: data[type],
						is_company: multidimensionBools.is_company,
						is_form: multidimensionBools.is_form,
						is_additional: multidimensionBools.is_additional,
						is_product: multidimensionBools.is_product,
						constraint: constraint === '{}' || !constraint ? undefined : JSON.parse(constraint),
					}).then(() => {
						reset();
						props.onCreate();
					});
				}

			}}
			onClose={() => props.setIsOpen(false)}
		>
			<CreationPopupContainer gap='1em'>
				slug: {props.field.slug}
				<Dropdown
					dropdownStyle={{ ...creationPopupDropdownStyle, margin: '11px 0' }}
					datalist={ALL_FIELD_TYPES.map(value => ({ label: value, value }))}
					name='type-dropdown'
					onChange={value => setType(value.value)}
					disabled={props.field.field_id !== undefined}
					selectedValue={type ? { label: type, value: type } : undefined}
					required
					label={translateToString('type')}
				/>
				{type && <Input type='text' name='title-input' value={name} onChange={setName} inputStyle={creationPopupInputStyleMargin} required label={translateToString('title')} />}
				{(type === 'Select' || type === 'Multiselect') && <>
					<label style={{
						fontSize: '12px',
						color: DarkGreySidely2,
					}}>{translateToString('form_editor.modify_multiple_values')}
						<textarea ref={textAreaRef} defaultValue={data[type]?.map(e => `${e}`).join('\n')} 
							style={{
								width: '100%',
								border: `1px solid ${BorderColor}`,
								borderRadius: '5px',
								fontSize: '12px',
								padding: '9px 12px',
								color: DarkGreySidely2,
							}}
							onChange={e => {
								if (textAreaRef.current) {
									textAreaRef.current.style.height = '';
									textAreaRef.current.style.height = textAreaRef.current.scrollHeight + 3 + 'px';
								}
								setData(md => {
									md[type] = e.target.value.split('\n').map(e => e.trim()).filter(e => e.length > 0);
									return { ...md };
								});
							}}/>
					</label>
					{data[type]?.map((e, i) => <SelectMetaData
						value={e}
						key={`selectedMetaData[${i}]`}
						first={i === 0}
						onDelete={() => {
							setData(md => {
								md[type]?.splice(i, 1);
								return { ...md };
							});
						}}
						onChange={value => {
							setData(md => {
								if (!md[type]) md[type] = [];
								// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
								md[type]![i] = value;
								return { ...md };
							});
						}}
					/>) ?? <></>}
					<Add onClick={() => setData(md => {
						if (!md[type]) md[type] = [];
						md[type]?.push('');
						return { ...md };
					})}/>
				</>}
				{(type === 'Form' || type === 'CheckIn') && <>
					<Input type='number' name='form-id' value={props.field.field_id ? data[type] : 0} onChange={value => {
						setData(md => {
							md[type] = parseInt(value);
							return { ...md };
						});
					}} inputStyle={creationPopupInputStyleMargin} required label={translateToString('form-id')} />
				</>}
				<FlexDiv flow='column' align='stretch' gap='6px'>
					<label style={{
						fontSize: '12px',
						color: DarkGreySidely2,
						marginBottom: '0px',
					}}>
						{translateToString('form_editor.field_domain')}
					</label>
					{ Object.keys(multidimensionBools).map((key, i) =>
						<FlexDiv key={`${key}${i}`} fontSize='13px' color='' gap='10px' cursor='pointer' 
							onClick={() => setMultidimensionBools({ ...multidimensionBools, [key]: !multidimensionBools[key] })}>
							<BoolCheckbox 
								type='checkbox'
								checked={multidimensionBools[key]}/>
							{translateToString(`form_editor.domain_${key}`)}
						</FlexDiv>
					)
					}
				</FlexDiv>
				<FlexDiv flow='column' align='stretch' gap='6px' height='100%'>
					<label style={{
						fontSize: '12px',
						color: DarkGreySidely2,
						marginBottom: '0px',
					}}>
						{translateToString('form_editor.constraint')}
					</label>
					<Editor
						height='200px'
						language='json'
						value={constraint}
						theme={isDarkMode ? 'vs-dark' : 'light'}
						saveViewState={false}
						onChange={(value) => {
							try {
								setConstraint(value);
							} catch (e) {
								console.error('Invalid JSON');
							}
						}}
						options={{
							minimap: { enabled: false },
							folding: false,
							glyphMargin: false,
						}}
						onMount={React.useCallback((editor) => {
							constraintEditor.current = editor;		
							setTimeout(() => {
								editor.getAction('editor.action.formatDocument').run();
							}, 200);
							setTimeout(() => {
								editor.revealLine(1);
							}, 300);
							editor.getAction('editor.action.formatDocument').run();
						}, [])}
					/>
				</FlexDiv>
			</CreationPopupContainer>

		</PopupCreation>
	</Popup>;
}
