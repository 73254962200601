import { FilterType } from 'bindings/filters/FilterType';
import * as moment from 'moment';
import * as React from 'react';
import { getTranslate, Translate } from 'react-localize-redux';
import { LoadingStateEnum } from '../../../containers_v2/import/model';
import storeLang from '../../../helpers/storeLang';
import { Open } from '../../../styles/global/css/Open';
import { BlueSidely, FilterBlue, SidelyBlack } from '../../../styles/global/css/Utils';
import { translateToString } from '../../../styles/global/translate';
import { DropdownData, TagOperator } from '../../dropdown/model/Model';
import InputSearch from '../../input/InputSearch';
import PopupCreation from '../../popup/PopupCreation';
import { Collapse } from 'reactstrap';
import {
	FilterAction,
	FilterColumn,
	FilterId,
	FilterQueryType,
	FilterResult,
	FilterTree,
	FilterValueResult,
	FilterValueResultArray,
	FilterValueResultUnion,
} from '../model/Model';
import { Body, Category, CategoryFilterText, CategoryName, Container, FilterText, Header } from '../style/AdvancedFilterStyle';
import DateFilter from './DateFilter';
import FilterSelection from './FilterSelection';
import ListFilter from './ListFilter';
import NumberFilter from './NumberFilter';
import StringFilter from './StringFilter';
import BoolFilter from './BoolFilter';
import Summary from './Summary';
import { ObjectAction } from '../../../../../web-types/objectActions';
import { TagType } from '../../toolbarFilter/tags/Model';
import equal from 'deep-equal';
import NullableFilter from './NullableFilter';
import * as _ from 'lodash';
import { useFunctionState } from '../../../utils/customHooks';
import CompanyFilter from './CompanyFilter';
import Filter from '../../../containers/formBuilder/formData/Filter';
import { FlexDiv } from '../../../containers_v2/products/style';
import { goalsProps, ReportFiltersContext } from '../../../containers_v2/reports/generic/generic';
import { Assortment } from '../../../containers_v2/products/model';
import { Product } from '../../../atoms/product';
import { Campaign } from '../../../atoms/campaignAtom';
import Restricted from '../../../containers_v2/permissions/Restricted';
import { BoolSlider } from '../../../containers_v2/settings/subSettings/CalculatedFieldsCreation';
import target from 'images/icons/target.svg';
import Dropdown from '../../dropdown/Dropdown';
import { isOwner, useMe } from '../../../atoms/global/users';
import { isSuperAdmin } from '../../utils';

export type FilterParameterParent = {
	children: FilterParameter[] | FilterParameterParent[]
	name: string
	nbFiltersSet?: number
}

export interface FilterParameter {
	category: string
	filters: Filter[]
}

export interface Filter {
	id: FilterId
	name: string
	type: FilterType,
	jsx?: JSX.Element,
	isFilterSet?: boolean
}

type FilterAndJsx = {
	jsx: JSX.Element,
	filter: Filter
}

export function convertFilters(filters: FilterValueResultUnion): FilterTree {
	if ('val' in filters) {
		return convertFilter(filters.val);
	} else {
		if (filters.combinator == 'and') {
			return {
				and: filters.array.map(convertFilters)
			};
		} else {
			return {
				or: filters.array.map(convertFilters)
			};
		}
	}
}

export function reverseConvertFilters(
	filters: FilterTree, 
	catalogues?: Assortment[],
	products?: Product[],
	promotions?: Campaign[],
): FilterValueResultUnion {
	function recursiveConvertFilters(filters: FilterTree): FilterValueResultUnion {
		if ('val' in filters) {
			let values: object[] = [];
			if (filters.val.value) {
				const valueSplit = filters.val.value.split(',');
				if (filters.val.column === 'catalogue') {
					values = valueSplit.map((v) => {
						const catalogue = catalogues?.find((c) => c.id.toString() === v);
						return { id: v, name: catalogue?.name ?? v };
					});
				}
				else if (filters.val.column === 'product') {
					values = valueSplit.map((v) => {
						const product = products?.find((p) => p.uuid === v);
						return { id: v, name: product?.name ?? v };
					});
				}
				else if (filters.val.column === 'campaign') {
					values = valueSplit.map((v) => {
						const campaign = promotions?.find((c) => c.id.toString() === v);
						return { id: v, name: campaign?.name ?? v };
					});
				}
				else if (filters.val.column === 'period') {
					values = valueSplit.map((v) => {
						return { id: v, name: v };
					});
				}
			}
			let filterType: FilterType = 'string';
			if (filters.val.column === 'catalogue' || filters.val.column === 'product')
				filterType = filters.val.column as FilterType;
			else if (filters.val.column === 'picking')
				filterType = 'nullable';
			return { val: { title: filters.val.column as string, action: FilterQueryTypeToFilterAction(filters.val.operator), type: filterType, id: filters.val.column, value: filters.val.value, values: values } };
		} else {
			if ('and' in filters) {
				return {
					array: filters.and.map(recursiveConvertFilters),
					combinator: 'and'
				};
			} else {
				return {
					array: filters.or.map(recursiveConvertFilters),
					combinator: 'or'
				};
			}
		}
	}
	return recursiveConvertFilters(filters);
}


export function FilterQueryTypeToFilterAction(queryType: FilterQueryType | undefined): FilterAction | FilterAction[] | undefined {
	switch (queryType) {
		case 'contains':
			return FilterAction.CONTAINS;
		case 'not_contains':
			return FilterAction.DOES_NOT_CONTAINS;
		case 'starts_with':
			return FilterAction.START_WITH;
		case 'ends_with':
			return FilterAction.END_WITH;
		case 'eq':
			return FilterAction.IS_EQUAL;
		case 'ne':
			return FilterAction.IS_NOT_EQUAL;
		case 'gt':
			return FilterAction.MORE_THAN;
		case 'lt':
			return FilterAction.LESS_THAN;
		case 'gte':
			return [FilterAction.MORE_THAN_NEXT, FilterAction.EQUAL_OR_MORE_THAN];
		case 'lte':
			return [FilterAction.MORE_THAN_LAST, FilterAction.EQUAL_OR_LESS_THAN];
		case 'empty':
			return FilterAction.IS_EMPTY;
		case 'not_empty':
			return FilterAction.IS_NOT_EMPTY;
		case 'between':
			return [FilterAction.BETWEEN, FilterAction.LESS_THAN_LAST, FilterAction.LESS_THAN_NEXT];
		case 'not_between':
			return FilterAction.NOT_BETWEEN;
		case 'contained':
			return FilterAction.IS;
		case 'not_contained':
			return FilterAction.IS_NOT;
	}

}

function FilterActionToFilterQueryType(action: FilterAction): FilterQueryType {
	switch (action) {
		case FilterAction.CONTAINS:
			return 'contains';
		case FilterAction.CONTAINS_LIST:
			return 'contained';
		case FilterAction.DOES_NOT_CONTAINS:
			return 'not_contains';
		case FilterAction.DOES_NOT_CONTAINS_LIST:
			return 'not_contained';
		case FilterAction.START_WITH:
			return 'starts_with';
		case FilterAction.END_WITH:
			return 'ends_with';
		case FilterAction.IS_TRUE:
		case FilterAction.IS_FALSE:
		case FilterAction.IS_EQUAL:
			return 'eq';
		case FilterAction.IS_NOT_EQUAL:
			return 'ne';
		case FilterAction.MORE_THAN:
			return 'gt';
		case FilterAction.LESS_THAN:
			return 'lt';
		case FilterAction.EQUAL_OR_MORE_THAN:
		case FilterAction.MORE_THAN_NEXT:
			return 'gte';
		case FilterAction.EQUAL_OR_LESS_THAN:
		case FilterAction.MORE_THAN_LAST:
			return 'lte';
		case FilterAction.IS_EMPTY:
			return 'empty';
		case FilterAction.IS_NOT_EMPTY:
			return 'not_empty';
		case FilterAction.BETWEEN:
		case FilterAction.LESS_THAN_LAST:
		case FilterAction.LESS_THAN_NEXT:
			return 'between';
		case FilterAction.NOT_BETWEEN:
			return 'not_between';
		case FilterAction.IS:
			return 'eq';
		case FilterAction.IS_NOT:
			return 'ne';
	}
}

export function convertFilter(f: FilterValueResult): FilterTree {
	let value: string | undefined;
	let operator: FilterQueryType = FilterActionToFilterQueryType(f.action);

	const isSwitch = (isNot?: true) => {
		operator = isNot ? 'not_contained' : 'contained';
		switch (f.type) {
			case 'tag': {
				value = f.values !== undefined ? f.values.reduce<string>((acc: string, v: any) => `${acc}${v.tag.id}${v.operator == 'and' ? '&' : '|'}`, '') : undefined;
				value = value?.slice(0, -1);
				operator = isNot ? 'not_combinator' : 'combinator';
				break;
			}
			case 'user':
			case 'status':
			case 'event_status_id':
			case 'event_type_id':
			case 'granularity':
			case 'target_enum':
			case 'account_type':
			case 'campaign':
			case 'period':
			case 'catalogue':
				value = (f.values != null) ? f.values.map(v => v.id).join(',') : undefined;
				break;
			case 'product':
				value = (f.values != null) ? f.values.map(v => v.uuid).join(',') : undefined;
				break;
			case 'select':
			case 'multi_select':
				operator = FilterActionToFilterQueryType(f.action);
				value = (f.values != null) ? f.values.join(',') : undefined;
				break;
			case 'string':
			case 'numeric':
				operator = isNot ? 'ne' : 'eq';
				value = f.value?.toString() ?? '';
		}
	};

	switch (f.action) {
		case FilterAction.CONTAINS:
		case FilterAction.DOES_NOT_CONTAINS:
		case FilterAction.START_WITH:
		case FilterAction.END_WITH:
		case FilterAction.IS_TRUE:
		case FilterAction.IS_FALSE:
		case FilterAction.IS_EQUAL:
		case FilterAction.IS_NOT_EQUAL:
		case FilterAction.MORE_THAN:
		case FilterAction.LESS_THAN:
		case FilterAction.EQUAL_OR_MORE_THAN:
		case FilterAction.EQUAL_OR_LESS_THAN:
			value = f.value?.toString() ?? '';
			break;
		case FilterAction.IS_EMPTY:
		case FilterAction.IS_NOT_EMPTY:
			break;
		case FilterAction.CONTAINS_LIST:
		case FilterAction.IS:
			isSwitch();
			break;
		case FilterAction.DOES_NOT_CONTAINS_LIST:
		case FilterAction.IS_NOT:
			isSwitch(true);
			break;
		case FilterAction.BETWEEN:
			value = (f.values != null) && f.values.length >= 2 ? `${f.values[0]},${f.values[1]}` : undefined;
			break;
		case FilterAction.NOT_BETWEEN:
			value = (f.values != null) && f.values.length >= 2 ? `${f.values[0]},${f.values[1]}` : undefined;
			break;
		case FilterAction.MORE_THAN_LAST:
			switch (f.type) {
				// @ts-expect-error date is legacy compatibility
				case 'date': 
				case 'temporal':{
					let n: number | undefined = undefined;
					if (typeof f.value === 'number') {
						n = f.value;
					} else if (typeof f.value === 'string') {
						n = parseInt(f.value);
					}
					if (n !== undefined && !isNaN(n))
						value = moment().subtract(n, 'd').toISOString();
				}
			}
			break;
		case FilterAction.LESS_THAN_LAST:
			switch (f.type) {
				// @ts-expect-error date is legacy compatibility
				case 'date': 
				case 'temporal': {
					let n: number | undefined = undefined;
					if (typeof f.value === 'number') {
						n = f.value;
					} else if (typeof f.value === 'string') {
						n = parseInt(f.value);
					}
					if (n !== undefined && !isNaN(n))
						value = `${moment().subtract(n, 'd').toISOString()},${moment().toISOString()}`;
				}
			}
			break;
		case FilterAction.LESS_THAN_NEXT:
			switch (f.type) {
				// @ts-expect-error date is legacy compatibility
				case 'date': 
				case 'temporal': {
					let n: number | undefined = undefined;
					if (typeof f.value === 'number') {
						n = f.value;
					} else if (typeof f.value === 'string') {
						n = parseInt(f.value);
					}
					if (n !== undefined && !isNaN(n))
						value = `${moment().toISOString()},${moment().add(n, 'd').toISOString()}`;
				}
			}
			break;
		case FilterAction.MORE_THAN_NEXT:
			switch (f.type) {
				// @ts-expect-error date is legacy compatibility
				case 'date': 
				case 'temporal': {
					let n: number | undefined = undefined;
					if (typeof f.value === 'number') {
						n = f.value;
					} else if (typeof f.value === 'string') {
						n = parseInt(f.value);
					}
					if (n !== undefined && !isNaN(n))
						value = moment().add(n, 'd').toISOString();
				}
			}
			break;
	}
	return { val: { operator, value, column: f.id, filter_type: f.filterType } };
}

function compareSimilarFilters(f1: FilterId, f2: FilterId): boolean {
	if (typeof f1 === 'string' && typeof f2 === 'string') return f1 === f2;
	if (typeof f1 === 'string' || typeof f2 === 'string') return false;
	if ('next_event_date' in f1 && 'next_event_date' in f2) return true;
	if ('last_event_date' in f1 && 'last_event_date' in f2) return true;
	if ('last_event_date' in f1 && 'last_event_date' in f2) return true;
	if ('external_id' in f1 && 'external_id' in f2) return true;
	if ('frequencies' in f1 && 'frequencies' in f2) return f1.frequencies.element === f2.frequencies.element;
	if ('checkout' in f1 && 'checkout' in f2) return f1.checkout.element === f2.checkout.element;
	if ('additional_columns' in f1 && 'additional_columns' in f2) return equal(f1.additional_columns?.map(c => c.id), f2.additional_columns?.map(c => c.id));
	if ('additional_field_columns' in f1 && 'additional_field_columns' in f2) return equal(f1, f2);
	if ('last_form_date' in f1 && 'last_form_date' in f2) return true;
	if ('calculated_field_columns' in f1 && 'calculated_field_columns' in f2) return equal(f1.calculated_field_columns?.map(c => c.id), f2.calculated_field_columns?.map(c => c.id));
	return false;
}

function isFilterParameterList(filterId: FilterParameter[] | FilterParameterParent[]): filterId is FilterParameter[] {
	return filterId.length == 0 || 'category' in filterId[0];
}

function getFiltersFromFilterList(filterList?: FilterParameter[] | FilterParameterParent[]): Filter[] {
	if (!filterList || filterList.length == 0) return [];
	if (isFilterParameterList(filterList)) {
		return (filterList as FilterParameter[]).reduce((acc, f) => [...acc, ...f.filters], []);
	} else {
		return (filterList as FilterParameterParent[]).reduce((acc, f) => [...acc, ...getFiltersFromFilterList(f.children)], []);
	}
}

function BackButton(props: {onClick: React.MouseEventHandler<HTMLParagraphElement>}) {
	return <FilterText
		color={BlueSidely}
		cursor="pointer"
		fontSize="11px"
		style={{ alignSelf: 'flex-start' }}
		onClick={props.onClick}
		padding='0 0 8px 0'>
		&lt;<Translate id='back' />
	</FilterText>;
}

export function FilterList(props: {
	onClickOut?: () => void
	isSummaryOpen?: boolean
	onChange: (values: FilterResult) => void
	filterList?: FilterParameter[] | FilterParameterParent[]
	filterValues?: FilterValueResultUnion
	selectedFilter?: FilterId
	permission?: ObjectAction
	tagType?: TagType
	title?: string
	extractFilters?: boolean
	isReportMetadata?: boolean
	overflow?: string
	goalsProps?: goalsProps
}): JSX.Element {
	const [loading, _setLoading] = React.useState<LoadingStateEnum>(LoadingStateEnum.LOADED);

	const [resultFilters, setResultFilters] = React.useState<FilterValueResultUnion>(props.filterValues ?? { array: [], combinator: 'and' });
	const [filters, setFilters] = React.useState<FilterParameter[] | FilterParameterParent[]>(props.filterList ?? []);
	const [search, setSearch] = React.useState<string>('');
	const [path, setPath] = useFunctionState<string[]>([], ({ newValue }: { newValue: string[] }) => {
		const newFilters = newValue.length !== 0 ? _.get(props.filterList, newValue[newValue.length - 1]) : (props.filterList ?? []);
		setFilters(newFilters);
		setSearch('');
		return newValue;
	});

	const [filterColumns, setFilterColumns] = React.useState<FilterColumn[]>(filters.map((_, i) => ({ id: i, isOpen: true })));
	const [filterJSX, setFilterJSX] = React.useState<FilterAndJsx>();
	const [isSummaryOpen, setSummaryOpen] = React.useState<boolean>(props.isSummaryOpen ?? false);
	const [filterPath, setFilterPath] = React.useState<number[]>([]);
	const [selectedFilter, setSelectedFilter] = React.useState<FilterId | { filter: FilterValueResult, tree: FilterTree } | undefined>(props.selectedFilter);
	const [updateDepth, setUpdateDepth] = React.useState<number[]>();
	const translate = getTranslate(storeLang.getState().localize);
	const me = useMe();
	const meOwner = isOwner(me) || isSuperAdmin();

	React.useEffect(() => {
		setFilterColumns(filters.map((_, i) => ({ id: i, isOpen: true })));
	}, [filters]);

	React.useEffect(() => {
		if (props.isReportMetadata)
			setFilters(props.filterList ?? []);
	}, [props.filterList]);

	React.useEffect(() => {
		if (props.isReportMetadata && props.filterValues)
			setResultFilters(props.filterValues);
	}, [props.filterValues]);

	React.useEffect(() => {
		setSelectedFilter(props.selectedFilter);
	}, [props.selectedFilter]);

	React.useEffect(() => {
		if (selectedFilter) {
			let filterId: FilterId;
			if (typeof selectedFilter === 'object' && 'filter' in selectedFilter) {
				if (!selectedFilter.filter) return;
				filterId = selectedFilter.filter.id;
			} else {
				filterId = selectedFilter;
			}
			const filter = getFiltersFromFilterList(props.filterList).find(f => compareSimilarFilters(f.id, filterId));
			if (filter != null) {
				openFilterEditMode(filter, typeof selectedFilter === 'object' && 'tree' in selectedFilter ? selectedFilter : undefined);
				setSummaryOpen(false);
			}
		}
	}, [props.filterList, selectedFilter]);

	React.useEffect(() => {
		setSummaryOpen(props.isSummaryOpen ?? false);
	}, [props.isSummaryOpen]);

	function updateFilters(newFilters: FilterValueResultUnion): void {
		setResultFilters(newFilters);
		props.onChange({
			values: newFilters,
			formatted: formatResult(newFilters)
		});
	}

	function isFilterParameter(filter: FilterParameter | FilterParameterParent): filter is FilterParameter {
		return 'filters' in filter;
	}

	function isFilterParameterParent(filter: FilterParameter | FilterParameterParent): filter is FilterParameterParent {
		return 'children' in filter;
	}

	function countNbFiltersSet(filterArray: FilterParameterParent[] | FilterParameter[], index: number): number {
		if (index >= filterArray.length) return 0;
		const filter = filterArray[index];
		if (isFilterParameterParent(filter) && filter.children)
			return countNbFiltersSet(filter.children, 0) + countNbFiltersSet(filterArray, index + 1);
		else if (isFilterParameter(filter) && filter.filters)
			return filter.filters.filter(f => f.isFilterSet).length + countNbFiltersSet(filterArray, index + 1);
		return 0;
	}

	function buttonReturn(): void {
		if (selectedFilter) {
			setSummaryOpen(true);
		}
		setFilterJSX(undefined);
		setSelectedFilter(undefined);
		setUpdateDepth(undefined);
		setPath([...path]);
	}

	function formatResult(filters: FilterValueResultUnion): FilterTree {
		return convertFilters(filters);
	}

	function openFilterEditMode(filter: Filter, tree?: { filter: FilterValueResult, tree: FilterTree }): void {
		if (filter.jsx) {
			setFilterJSX({
				jsx: <Container overflow={props.overflow}>
					<BackButton onClick={buttonReturn}/>
					<FilterText margin="10px 0" color={SidelyBlack} fontWeight="500" fontSize="14px">{filter.name}</FilterText>
					{filter.jsx}
				</Container>,
				filter
			});
			return;
		}
		const onSubmit = (filterType: FilterType) => (value: FilterValueResult) => {
			setSummaryOpen(true);
			updateFilters(addFilterValueResultUnion(resultFilters, { ...value, filterType }, filterPath, updateDepth));
			setUpdateDepth(undefined);
			buttonReturn();
		};
		switch (filter.type) {
			case 'nullable':
				setFilterJSX({
					jsx: <NullableFilter id={filter.id} title={filter.name} onReturn={buttonReturn} onSubmit={onSubmit(filter.type)} selectedValue={tree?.tree} />,
					filter
				});
				break;
			case 'string':
				setFilterJSX({
					jsx: <StringFilter id={filter.id} title={filter.name} onReturn={buttonReturn} onSubmit={onSubmit(filter.type)} selectedValue={tree?.tree} />,
					filter
				});
				break;
			case 'company':
				setFilterJSX({
					jsx: <CompanyFilter id={filter.id} title={filter.name} onReturn={buttonReturn} onSubmit={onSubmit(filter.type)} selectedValue={tree?.tree} filterResult={tree?.filter} />,
					filter
				});
				break;
			case 'bool':
				setFilterJSX({
					jsx: <BoolFilter id={filter.id} title={filter.name} onReturn={buttonReturn} onSubmit={onSubmit(filter.type)} selectedValue={tree?.tree} />,
					filter
				});
				break;
			case 'frequency':
			case 'numeric':
			case 'percentage':
			case 'checkout':
				setFilterJSX({
					jsx: <NumberFilter id={filter.id} title={filter.name} onReturn={buttonReturn} onSubmit={onSubmit(filter.type)} percentage={filter.type === 'percentage'} selectedValue={tree?.tree} />,
					filter
				});
				break;
			case 'temporal':
				setFilterJSX({
					jsx: <DateFilter id={filter.id} title={filter.name} onReturn={buttonReturn} onSubmit={onSubmit(filter.type)} selectedValue={tree} />,
					filter
				});
				break;
			case 'tag':
			case 'user':
			case 'status':
			case 'event_status_id':
			case 'event_type_id':
			case 'granularity':
			case 'target_enum':
			case 'account_type':
			case 'catalogue':
			case 'product':
			case 'campaign':
			case 'period':
			case 'select':
			case 'multi_select':
				setFilterJSX({
					jsx: <ListFilter id={filter.id} title={filter.name} onReturn={buttonReturn} onSubmit={onSubmit(filter.type)} type={filter.type} permission={props.permission} selectedValue={tree} tagType={props.tagType} overflow={props.overflow}/>,
					filter
				});
				break;
			default:
				break;
		}
	}

	function filtersInCategory(filterParameter: FilterParameter): JSX.Element {
		const { filters } = React.useContext(ReportFiltersContext);
		const clickableGoals = props.extractFilters && props.goalsProps && (!props.goalsProps.isGoals || props.goalsProps.goals && props.goalsProps.goals.length === 0);
		const list: DropdownData[] = [{ label: translateToString('objectives.empty_objective_0'), value: '0' }, { label: translateToString('objectives.empty_objective_undefined'), value: 'null' }];

		return (
			<>
				{
					filterParameter.filters
						.filter(filter => filter.name.toLowerCase().includes(search.toLocaleLowerCase()))
						.map((filter, i) => {
							if (props.extractFilters && filter.jsx !== undefined) {
								if (filter.id === 'Formulaires pris en compte')
									filter.name = translateToString('forms_for_variation');
								if (filter.id !== 'Formulaires pris en compte' || (filters.variation !== undefined && filters.variation !== null))
									return (<div key={`filterListCollapseFilter[${i}]`}>
										<FilterText margin="10px 0" color={SidelyBlack} fontWeight="500" fontSize="14px">{filter.name}</FilterText>
										{filter.jsx}
									</div>
									);
								else
									return <></>;
							}
							return (
								<CategoryFilterText key={`filterListCollapseFilter[${i}]`}
									onClick={() => openFilterEditMode(filter)}>
									<span>{filter.name}</span>
									{filter.isFilterSet && <span style={{ color: BlueSidely }}>{' (1)'}</span>}
								</CategoryFilterText>
							);
						})
				}
				{ props.extractFilters && props.goalsProps && props.goalsProps.isEvolutionsReport && meOwner &&
					<Restricted to={{ objectAction: 'ViewObjective' }}>
						<FilterText margin='10px 0 0 0' color={SidelyBlack} fontWeight="500" fontSize="14px"><Translate id='objectives.title'/></FilterText>
						<FlexDiv gap='4px'>
							<BoolSlider isActive={props.goalsProps.isGoals} cursor={clickableGoals ? 'pointer' : 'not-allowed'} onClick={() => {
								if (props.goalsProps && clickableGoals)
									props.goalsProps.setIsGoals((prev) => !prev);
							}}/>
							<img width={'24px'} style={{ filter: props.goalsProps.isGoals ? FilterBlue : 'opacity(75%)' }} src={target}/>
						</FlexDiv>
						{ props.goalsProps.isGoals &&
							<FlexDiv flow='column' align='stretch' gap='8px' fontSize='12px'>
								<Translate id='objectives.empty_objective'/>
								<Dropdown 
									name='goals'
									readOnly
									datalist={list}
									selectedValue={list.find((l) => l.value === props.goalsProps?.emptyGoalInterpretation)}
									onChange={(value) => {props.goalsProps && props.goalsProps.setEmptyGoalInterpretation(value.value);}}
									dropdownStyle={{
										width: '21vw',
										height: '38px',
										optionWidth: '21vw'
									}}
								/>
							</FlexDiv>
						}
					</Restricted>
				}
			</>
		);
	}

	function body(): JSX.Element {
		if (!isFilterParameterList(filters)) return <></>;
		return (
			<Container overflow={props.overflow}>
				{ !props.isReportMetadata && <Header>
					{
						(path.length !== 0 || ('array' in resultFilters && resultFilters.array.length != 0)) && <BackButton
							onClick={() => {
								if (path.length !== 0) return setPath(path => path.slice(0, -1));
								setSummaryOpen(true);
								setUpdateDepth(undefined);
							}}
						/>
					}
					<InputSearch name='search_filter' type='text' placeholder={translateToString('search')} onChange={(value) => setSearch(value)}/>
				</Header>}
				<Body>
					{
						filters
							.filter(f => f.filters.filter(filter => filter.name.toLowerCase().includes(search.toLocaleLowerCase())).length > 0)
							.map((f, i) => {
								return (
									<>
										{
											!props.extractFilters &&
											<Category onClick={() => {
												const filter = filterColumns.find(f => f.id === i);
												if (filter != null) {
													filter.isOpen = !filter.isOpen;
													setFilterColumns(filterColumns.filter(f => f.id !== filter.id).concat(filter));
												}
											}}>
												<CategoryName>{f.category}</CategoryName>
												<Open isOpen={filterColumns.find(f => f.id === i)?.isOpen ?? false}/>
											</Category>
										}
										{
											f.filters.length > 0 && <>
												{ !props.extractFilters ? (
													<Collapse isOpen={filterColumns.find(f => f.id === i)?.isOpen ?? false}>
														{filtersInCategory(f)}
													</Collapse>
												) : (
													<FlexDiv flow='column' align='stretch' gap={props.isReportMetadata ? '2px' : '10px'} padding={props.isReportMetadata ? '0' : '10px 0 0 0'}>
														{filtersInCategory(f)}
													</FlexDiv>
												)
												}
											</>
										}
									</>
								);
							})
					}
				</Body>
			</Container>
		);
	}

	if (!isFilterParameterList(filters)) {
		return <PopupCreation
			title={props.title ?? translate('advanced_filters').toString()}
			content={<Container overflow={props.overflow}>
				<Header paddingBottom={'20px'}>
					{path.length !== 0 && <BackButton
						onClick={() => setPath(path => path.slice(0, -1))}/>}
					<InputSearch name='search_filter' type='text' placeholder={translateToString('search')} onChange={(value) => setSearch(value)}/>
				</Header>
				<Body>
					{filters?.filter(f => f.name.toLowerCase().includes(search.toLocaleLowerCase())).map((f, i) => {
						if (f.name !== translateToString('global.filter.company_filters')) f.nbFiltersSet = countNbFiltersSet([f], 0);
						const filterNbFormatted = f.nbFiltersSet ? ` (${f.nbFiltersSet})` : '';
						const isFilterDisabled = f.name === 'Filtres de produits' && props.goalsProps && props.goalsProps.isGoals;
						return (<CategoryFilterText key={i} 
							isFilterDisabled={isFilterDisabled}
							warningMessage={isFilterDisabled ? translateToString('objectives.filter_disabled') : undefined}
							onClick={!isFilterDisabled ? () => {
								setPath(path => {
									const newPath = path.length !== 0 ? `${path[path.length - 1]}[${i}].children` : `[${i}].children`;
									return [...path, newPath];
								});
							} : undefined}>
							<span>{f.name}</span>
							<span style={{ color: BlueSidely }}>{filterNbFormatted}</span>
						</CategoryFilterText>);})}
				</Body>
			</Container>}
			canValidate={false}
			onSubmit={() => null}
			loading={loading}
			onClose={() => (props.onClickOut != null) && props.onClickOut()}
			hideValidation
		/>;
	}

	if (isSummaryOpen && 'array' in resultFilters && resultFilters.array.length && !filters.every(f => f.filters.every(f => f.jsx !== undefined))) {
		return (
			<PopupCreation
				noHeader={props.isReportMetadata}
				title={props.title ?? translate('advanced_filters').toString()}
				content={
					<Container overflow={props.overflow}>
						{path.length !== 0 && <BackButton
							onClick={() => setPath(path => path.slice(0, -1))}/>}
						<Summary overflow={props.overflow} noPadding={props.isReportMetadata} onAdd={(path) => {
							setFilterJSX(undefined);
							setSummaryOpen(false);
							setFilterPath(path);
						}}
						setSelectedFilter={(tree, depth, filter, isCategoryClicked) => {
							if (isCategoryClicked) {
								setSummaryOpen(false);
							} else {
								setSelectedFilter({ filter, tree });
							}
							setUpdateDepth(depth);
						}}
						filters={resultFilters}
						onDelete={(value) => updateFilters(removeFilter(resultFilters, value))}
						onInvert={(v, i) => updateFilters(invertFilter(resultFilters, v, i))}
						updateFilters={updateFilters}
						/>
					</Container>
				}
				canValidate={false}
				onSubmit={() => null}
				loading={loading}
				onClose={() => (props.onClickOut != null) && props.onClickOut()}
				hideValidation
				overflow={props.overflow}
			/>
		);
	}

	if (filterJSX != null) {
		return (
			<FilterSelection
				noHeader={props.isReportMetadata}
				title={props.title}
				buttonReturn={buttonReturn}
				content={filterJSX.jsx}
				loading={loading}
				onClose={props.onClickOut}
				filter={filterJSX.filter}
				overflow={props.overflow}
			/>
		);
	}

	if (props.isReportMetadata) return body();
	
	return (
		<PopupCreation
			title={props.title ?? translate('advanced_filters').toString()}
			content={body()}
			canValidate={false}
			onSubmit={() => null}
			loading={loading}
			onClose={() => (props.onClickOut != null) && props.onClickOut()}
			hideValidation
			overflow={props.overflow}
		/>
	);
}

export function invert(combinator: TagOperator): TagOperator {
	return combinator == 'and' ? 'or' : 'and';
}

function addFilterValueResultUnion(old_filter: FilterValueResultUnion, new_filter: FilterValueResult, path: number[], updateDepth?: number[]): FilterValueResultUnion {
	const filter = cloneFilter(old_filter);
	let cursor: FilterValueResultUnion = filter;
	let prev;
	for (const i of updateDepth ?? path) {
		prev = [cursor, i];
		cursor = cursor['array'][i];
	}
	if ('array' in cursor) {
		cursor.array.push({ val: new_filter });
	} else {
		if (prev) {
			const old_filter = prev[0].array.splice(prev[1], 1)[0];
			let filter;
			if (updateDepth?.length) {
				filter = { ...old_filter, val: new_filter };
			} else {
				filter = {
					combinator: invert(prev[0].combinator),
					array: [old_filter, { val: new_filter }],
				};

			}
			prev[0].array.splice(prev[1], 0, filter);
		}
	}

	return filter;
}

function removeFilter(rawFilter: FilterValueResultUnion, id: number[]): FilterValueResultUnion {
	const filter: FilterValueResultUnion = JSON.parse(JSON.stringify(rawFilter));
	const toRemove = id.pop();
	let cursor = filter;
	let prev;
	for (const i of id) {
		prev = [cursor, i];
		cursor = cursor['array'][i];
	}
	cursor['array'].splice(toRemove, 1);

	if (prev && cursor['array'].length == 0) {
		prev[0].array.splice(prev[1], 1);
	}

	return filter;
}

function invertFilter(filter: FilterValueResultUnion, path: number[], id: number): FilterValueResultUnion {
	const newFilters = cloneFilter(filter);
	let cursor: FilterValueResultUnion = newFilters;
	for (const i of path) {
		cursor = cursor['array'][i];
	}
	if (cursor['array'].length == 2) {
		cursor['combinator'] = invert(cursor['combinator']);
	} else {
		const filters = cursor['array'].splice(id, 2);
		cursor['array'].splice(id, 0, {
			combinator: invert(cursor['combinator']),
			array: filters,
		});
	}

	return newFilters;
}

function cloneFilter(filter: FilterValueResultUnion): FilterValueResultUnion {
	if ('array' in filter) {
		const new_filter: FilterValueResultUnion = {
			array: [],
			combinator: filter.combinator
		};
	
		addToFilter(filter, new_filter);

		return new_filter;
	}
	return { val: filter.val };
}

function addToFilter(filter: FilterValueResultArray, new_filter: FilterValueResultArray) {
	for (const f of filter.array) {
		if ('val' in f) {
			new_filter.array.push(f);
		} else {
			if (f.array.length == 1) {
				new_filter.array.push(f.array[0]);
			} else if (f.combinator == new_filter.combinator) {
				addToFilter(f, new_filter);
			} else {
				new_filter.array.push(cloneFilter(f));
			}
		}
	}
}

export default FilterList;
